// Copyright text placeholder, Warner Bros. Discovery, Inc.

// Design has a specific naming pattern for tokens (with dashes), which are used heavily in this file.
/* eslint-disable @typescript-eslint/naming-convention */

// ***DO NOT EDIT THIS FILE DIRECTLY***
// Generated with @wbd/generate-design-resources

/* eslint-disable @rushstack/typedef-var */

/**
 * BorderTokens derived from design data
 * @public
 */
export const BorderToken = {
  corner: {
    action: {
      lg: 8
    },
    full: 999,
    indicator: {
      badge: 8
    },
    input: {
      field: 4,
      option: {
        checkbox: 8
      },
      toggle: {
        corner: 999,
        corner_thumb: 999
      }
    },
    md: 8,
    none: 0,
    sm: 4,
    xs: 2
  },
  stroke: {
    bold: 5,
    chip: {
      selected: 3,
      unselected: 0
    },
    input: {
      basic: 2,
      emphasis: 3,
      option: {
        selected: 0,
        unselected: 2
      },
      toggle: {
        emphasis: 3,
        selected: 0,
        unselected: 2
      }
    },
    medium: 4,
    none: 0,
    notify: {
      container_border: 2
    },
    regular: 3,
    thin: 2
  }
} as const;
