// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IHttpError } from '../http-internal';
import HttpModuleAxios from '../http-module-axios';

/**
 * @public
 *
 * Checks if an error is HttpError
 *
 * @param error - error
 * @returns whether if the error is a HttpError error or not
 * @deprecated please migrate to BoltHttpClient.isHttpError
 */
export const isHttpError = (error: unknown): error is IHttpError => HttpModuleAxios.isHttpError(error);
