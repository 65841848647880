// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { AuthToken, IStorage } from '@wbd/bolt-http';
import { EventWithParams } from '@wbd/light-events';
import { getMigratedToken, getToken, type IMigrationCredentials } from '../authentication';
import { AuthTokenProvider, DEFAULT_AUTH_TOKEN_STORAGE_KEY } from './AuthTokenProvider';

/**
 * AuthTokenProvider with retain auth capabilities using the legacy token.
 *
 * @public
 */
export class AuthMigrationTokenProvider extends AuthTokenProvider {
  /**
   * legacy token for retain auth feature.
   */
  private _migrationCredentialsProvider: () => Promise<IMigrationCredentials>;

  /**
   * internal subscribers for migrated user
   */
  protected _userMigratedSubscribers: EventWithParams<[]> = new EventWithParams<[]>();

  public constructor(
    migrationCredentialsProvider: () => Promise<IMigrationCredentials>,
    tokenStorage?: IStorage,
    storageKey: string = DEFAULT_AUTH_TOKEN_STORAGE_KEY
  ) {
    super(tokenStorage, storageKey);
    this._migrationCredentialsProvider = migrationCredentialsProvider;
  }

  /**
   * Tries to fetch a migrated token using the legacy token.
   * Otherwise it returns an anonymous token.
   * The migrated token requests needs an anonymous token,
   * the getToken request needs to be fired in first place.
   *
   * @returns AuthToken
   */
  protected async _getToken(): Promise<AuthToken> {
    const token = await getToken(true);
    const migrationKey = `${this._storageKey}-migrated`;
    const migrationAttempted = this._tokenStorage?.readSync(migrationKey) as string | undefined;

    if (migrationAttempted) {
      return token;
    }

    try {
      this._tokenStorage?.writeSync(migrationKey, JSON.stringify(Date.now()));
      const credentials = await this._migrationCredentialsProvider();
      const migratedToken = await getMigratedToken(credentials);

      if (migratedToken) {
        this._userMigratedSubscribers.fire().catch(() => {});

        return migratedToken;
      }
    } catch (error) {
      this._errorSubscribers.fire(error).catch(() => {
        // fail silently
      });
    }

    return token;
  }

  /**
   * Expose an onUserMigrated subscriber to provide app to know state of user migrating from legacy application
   * @returns
   */
  public onUserMigrated(listener: () => void): void {
    this._userMigratedSubscribers.addListener(listener);
  }
}
