// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IInitialContext } from '../application';
import resources from '@wbd/fuse-ctv-resources-common';

export function bootstrapSplash(context: IInitialContext): void {
  const videoContainer = document.getElementById('splash-video-container');

  if (!videoContainer || !resources.splashVideo) return;

  const video = document.createElement('video');
  video.id = 'splash-video';
  video.autoplay = false;
  video.width = window.innerWidth || document.documentElement.clientWidth;
  video.height = window.innerHeight || document.documentElement.clientHeight;
  video.ariaHidden = 'true';
  video.src = resources.splashVideo.source;

  videoContainer.appendChild(video);

  video.onloadedmetadata = () => {
    video.onerror = null;
    video.setAttribute('data-loaded', 'true');
    video.onloadedmetadata = null;
  };
  video.onerror = (e) => {
    video.onloadedmetadata = null;
    video.onerror = null;
    video.parentElement?.removeChild(video);
  };
}
