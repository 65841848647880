// Copyright text placeholder, Warner Bros. Discovery, Inc.

/* eslint-disable no-console */

import type { ILogAdapter } from '../types';

/**
 * @public
 *
 * Creates console adapter
 *
 * @returns LogAdapter
 */
export function createConsoleAdapter(): ILogAdapter {
  return {
    debug: (...data) => console.debug(...data),
    info: (...data) => console.info(...data),
    warn: (...data) => console.warn(...data),
    error: (...data) => console.error(...data)
  };
}
