// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { loadAllFonts } from '@wbd/beam-ctv-shared-ui/lib/assets';
import type { TFontScript } from '@wbd/fuse-ctv-resources-common';
import {
  iUiLaunchError,
  type IUiLaunchErrorErr,
  UiLaunchErrorClassification,
  UiLaunchErrorScope
} from '../instrumentation';

export async function loadUIFonts(script: TFontScript): Promise<void> {
  try {
    await loadAllFonts(script);
  } catch (error) {
    await import('../instrumentation').then(({ generateErr }) => {
      iUiLaunchError.capture({
        err: generateErr<IUiLaunchErrorErr>(error, {
          classification: UiLaunchErrorClassification.DEPENDENCY_FAILURE,
          scope: UiLaunchErrorScope.RECOVERABLE
        })
      });
    });
  }
}
