// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IInterceptor, IRequestConfig } from '../http-internal';
import type { IDeviceConsentsConfig, ISessionConfig } from '../session-config';

/**
 * Returns the constructed device consent header value passed as `x-wbd-device-consent` header.
 */
const getDeviceConsent = (config: IDeviceConsentsConfig): string => {
  return `gpc=${config.globalPrivacyControl ? '1' : '0'}`;
};

/**
 * Constructs an 'x-disco-param' info header in the form realm=dplayse,features=feature1;feature2.
 * https://docs.disco-api.com/apidocs/#disco-params
 *
 * The following properties are used from the config that is passed in
 *
 * asyncRails - Indicates support for asynchronous rails.
 * brandId - The brand that the application has, for example dplus for discovery+.
 * homeTerritoryHint - Home Territory Hint, a country code hinting at the country that the user is a resident of.
 * realm - The current realm.
 * siteLookupKey - (DEPRECATED) The site that should be used (optional, only used if multiple sites are activated and geo location sites are not desired).
 * uatEnabled - Signal that Sites which are in uat mode should also be considered when selecting a Site.
 *
 * @public
 */
export const getXDiscoParamHeader = (config: ISessionConfig): string => {
  const { asyncRails, brandId, homeTerritoryHint, realm, siteLookupKey, uatEnabled } = config;
  const discoParams = [`realm=${realm}`, `bid=${brandId}`];
  const lookupKey = homeTerritoryHint ? `${brandId}_${homeTerritoryHint}` : siteLookupKey;

  if (siteLookupKey) {
    discoParams.push(`siteLookupKey=${lookupKey}`);
  }

  if (uatEnabled) {
    discoParams.push('uat=true');
  }

  if (asyncRails) {
    discoParams.push(`features=ar`);
  }

  if (homeTerritoryHint) {
    discoParams.push(`hth=${homeTerritoryHint}`);
  }
  return discoParams.join(',');
};

/**
 * Constructs an 'x-device-info' info header in the form of APP/VERSION (MAKER/MODEL; OS/VERSION; DEVICE_ID/CLIENT_ID)
 * https://docs.disco-api.com/apidocs/#device-info
 * @public
 */
export const getXDeviceInfoHeader = (config: ISessionConfig): string => {
  const { clientId, applicationId, applicationVersion, deviceId, deviceInfo } = config;

  const { manufacturer, model, operatingSystem, operatingSystemVersion } = deviceInfo;

  const deviceIdParam = clientId
    ? `${encodeURIComponent(deviceId)}/${encodeURIComponent(clientId)}`
    : encodeURIComponent(deviceId);
  return `${applicationId}/${applicationVersion} (${manufacturer}/${model}; ${operatingSystem}/${operatingSystemVersion}; ${deviceIdParam})`;
};

/**
 * Constructs an 'x-disco-client' info header in the form PLATFORM:OSVERSION:APPNAME:APPVERSION
 * https://docs.disco-api.com/apidocs/#disco-client-header
 * @public
 */
export const getXDiscoClientHeader = function (config: ISessionConfig): string {
  const osVersion = config.deviceInfo.operatingSystemVersion.replace(':', '') || 'UNKNOWN';

  return `${config.platform}:${osVersion}:${config.applicationId}:${config.applicationVersion}`;
};

/**
 * Header that need to be sent to Sonic with every request
 * @public
 */
export const discoHeadersInterceptorFactory =
  (config: ISessionConfig): IInterceptor<IRequestConfig> =>
  async (requestConfig: IRequestConfig): Promise<IRequestConfig> => {
    const authToken = !requestConfig.isAnonymous
      ? await config.authTokenProvider?.getTokenAsync()
      : undefined;
    return {
      ...requestConfig,
      headers: {
        'Content-Type': 'application/json',
        'x-disco-client': getXDiscoClientHeader(config),
        'x-disco-params': getXDiscoParamHeader(config),
        ...requestConfig.headers,
        ...(config.deviceId && { 'x-device-info': getXDeviceInfoHeader(config) }),
        ...(authToken && { Authorization: `Bearer ${authToken}` }),
        ...(config.deviceConsents && { 'x-wbd-device-consent': getDeviceConsent(config.deviceConsents) }),
        ...(config.devicePreferredLanguages?.length && {
          'x-wbd-preferred-language': config.devicePreferredLanguages.join(',')
        })
      }
    };
  };
