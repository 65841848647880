// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 *
 * This library hosts the `IFuseCTVResources` interface which will be implemented by
 * concrete resource libraries for FUSE.
 *
 * Resources are a way to group assets and configuration that are specific to a tenant and
 * will be applied into the platform apps in order to make them look-and-feel like another
 * brand.
 *
 * @packageDocumentation
 */

export * from './packlets/generated';
export * from './packlets/app-name';
export * from './packlets/resources';
export * from './packlets/fonts';

// eslint-disable-next-line @rushstack/packlets/mechanics
import { Resources } from './packlets/resources/Resources';
export default Resources;
