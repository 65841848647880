// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * The UN M49 Region Codes (see https://wbdstreaming.atlassian.net/wiki/spaces/GCX/pages/52199934/Language+Codes+and+Format+Codes#UN-M49-Region-Codes).
 * These codes are used for "composed regions", such as "Latin America and the Caribbean".
 *
 * These codes are numeric, which means that they cannot be used as names for enum values. Instead, we use the name
 
 * of the composed region from the UN M49 standard.
 *
 * Official values were sourced from the IANA website:
 *    <br>Website: https://www.iana.org/protocols
 *    <br>Data endpoint URL: https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
 *    <br>Sourced on: 2021-03-25
 *
 * This file was generated by `localization-enum-generator`. Please edit the template there to make any desired
 * changes. Changes made directly to this file will be overwritten without warning if this file is re-generated.
 *
 * @public
 */
export const enum UnRegionCode {
  /** World */
  WORLD = '001',

  /** Africa */
  AFRICA = '002',

  /** North America */
  NORTH_AMERICA = '003',

  /** South America */
  SOUTH_AMERICA = '005',

  /** Oceania */
  OCEANIA = '009',

  /** Western Africa */
  WESTERN_AFRICA = '011',

  /** Central America */
  CENTRAL_AMERICA = '013',

  /** Eastern Africa */
  EASTERN_AFRICA = '014',

  /** Northern Africa */
  NORTHERN_AFRICA = '015',

  /** Middle Africa */
  MIDDLE_AFRICA = '017',

  /** Southern Africa */
  SOUTHERN_AFRICA = '018',

  /** Americas */
  AMERICAS = '019',

  /** Northern America */
  NORTHERN_AMERICA = '021',

  /** Caribbean */
  CARIBBEAN = '029',

  /** Eastern Asia */
  EASTERN_ASIA = '030',

  /** Southern Asia */
  SOUTHERN_ASIA = '034',

  /** South-Eastern Asia */
  SOUTH_EASTERN_ASIA = '035',

  /** Southern Europe */
  SOUTHERN_EUROPE = '039',

  /** Australia and New Zealand */
  AUSTRALIA_AND_NEW_ZEALAND = '053',

  /** Melanesia */
  MELANESIA = '054',

  /** Micronesia */
  MICRONESIA = '057',

  /** Polynesia */
  POLYNESIA = '061',

  /** Asia */
  ASIA = '142',

  /** Central Asia */
  CENTRAL_ASIA = '143',

  /** Western Asia */
  WESTERN_ASIA = '145',

  /** Europe */
  EUROPE = '150',

  /** Eastern Europe */
  EASTERN_EUROPE = '151',

  /** Northern Europe */
  NORTHERN_EUROPE = '154',

  /** Western Europe */
  WESTERN_EUROPE = '155',

  /** Sub-Saharan Africa */
  SUB_SAHARAN_AFRICA = '202',

  /** Latin America and the Caribbean */
  LATIN_AMERICA_AND_THE_CARIBBEAN = '419'
}

// prettier-ignore
/**
 * set of values from the UnRegionCode const enums
 * @public
 */
export const UnRegionCodeSet: ReadonlySet<UnRegionCode> = new Set([
'001', '002', '003', '005', '009', '011', '013', '014', '015', '017', '018', '019', '021', '029', '030', '034', '035', '039', '053', '054', '057', '061', '142', '143', '145', '150', '151', '154', '155', '202', '419'
]) as Set<UnRegionCode>;
