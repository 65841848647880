// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Stringification options
 * @public
 */
export interface IQueryStringifyOptions {
  /** (default: true) URL-encode keys and values */
  encode?: boolean;
}

/**
 * Stringify an object for use as a URL query parameter value
 * @public
 */
export function queryStringify(value: Record<string, unknown>, options?: IQueryStringifyOptions): string {
  if (typeof value === 'object' && value !== null) {
    const seen = new Set<unknown>();
    const optionsWithDefaults = options ?? {};
    if (optionsWithDefaults.encode === undefined) {
      optionsWithDefaults.encode = true;
    }
    return queryStringifyObject(value, seen, optionsWithDefaults);
  }
  return '';
}

function queryStringifyObject(
  obj: Record<string, unknown>,
  seen: Set<unknown>,
  options: IQueryStringifyOptions,
  prefix?: string
): string {
  // prevent infinite loops
  seen.add(obj);

  const hasOwnProperty = Object.prototype.hasOwnProperty;
  const { encode } = options;
  const pairs: string[] = [];

  for (const prop in obj) {
    if (!hasOwnProperty.call(obj, prop)) {
      continue;
    }

    const value = obj[prop];
    const encodedProp = encode ? encodeURIComponent(prop) : prop;
    const key = prefix ? `${prefix}[${encodedProp}]` : encodedProp;
    let pair: string | undefined;

    if (value === undefined || typeof value === 'function') {
      continue;
    } else if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        // found a loop
        continue;
      }
      if (Array.isArray(value)) {
        // arrays to comma-separated values
        // note: this is normally an option in `qs` which also allows objects nested inside arrays to be serialised
        const encodedValues: string[] = [];
        value.forEach((value) => {
          if (value !== undefined && typeof value !== 'function') {
            const encodedValue = encode ? encodeURIComponent(String(value)) : String(value);
            encodedValues.push(encodedValue);
          }
        });
        pair = `${key}=${encodedValues.join(',')}`;
      } else {
        // recurse-serialise, building keys as `obj[prop1][prop2][prop3]...`
        pair = queryStringifyObject(value as Record<string, unknown>, seen, options, key);
      }
    } else {
      const encodedValue = encode ? encodeURIComponent(String(value)) : String(value);
      pair = `${key}=${encodedValue}`;
    }

    if (pair) {
      pairs.push(pair);
    }
  }

  return pairs.join('&');
}
