// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { PaywallType } from '../data-models';
import { HttpClient, type RequestConfigOverride } from '../http-client';
import type { DataTypes, ITypedJsonApiDocumentFromModel } from '../json-api';
import type { IPaywallTypeParameters } from './IPaywallTypeParameters';
type IPaywallTypeResponse = ITypedJsonApiDocumentFromModel<DataTypes.PaywallType, PaywallType>;

/**
 * Returns a PaywallType
 * @param config - The request config used to pass in properties like an abort signal.
 * @internal
 */
export const getPaywallTypeRequest = async (
  paywallTypeParameters: IPaywallTypeParameters = {},
  config?: RequestConfigOverride
): Promise<IPaywallTypeResponse> => {
  const { planProvider, storeFrontCountryCode } = paywallTypeParameters;
  const filter = { planProvider, storeFrontCountryCode };
  const params = { filter };

  const response = await HttpClient.instance.get<IPaywallTypeResponse>(`/paywall/type`, {
    params,
    signal: config?.signal
  });
  return response.data;
};
