// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IsoRegionCode } from '../generated';
import { getIsoRegionCodes } from '../getters';

/**
 * Type guard function for IsoRegionCode values.
 *
 * @param tag - a possible IsoRegionCode
 *
 * @public
 */
export function isIsoRegionCode(tag: string): tag is IsoRegionCode {
  return getIsoRegionCodes().has(tag as IsoRegionCode);
}
