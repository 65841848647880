// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type {
  BootstrapConfigChangeEventParams,
  BootstrapConfigChangeListener,
  IBootstrapConfig,
  IBootstrapConfigJson
} from './IBootstrapConfig';

import { BoltEnvironment } from '../session-config';
import type { IStorage } from '../session-state';
import type { BootstrapProvider } from './BootstrapProvider';
import { fingerprintData } from './fingerprint';
import type { IResponse } from '../http-internal';
import type { Unsubscribe } from '@wbd/light-events';
import { EventWithParams } from '@wbd/light-events';
import { noop } from '@wbd/beam-js-extensions';

export const CONFIG_STORAGE_KEY: string = 'bootstrap-config';

/**
 * This class manages the caching and manipulation of Headwaiter responses for the client
 *
 * Coordinates with `RefreshManager` to support managed bootstrap configuration.
 *
 * Managed bootstrap configuration handles automatic processing of bootstrap refresh
 * signals attached to API responses.  Under managed bootstrap several things occur:
 *
 * - when a bootstrap refresh signal is received, all outgoing API requests are paused
 *   until new configuration is obtained.
 *
 * - the registered bootstrap provider function will be called and its results will be
 *   used to update the cached configuration.
 *
 * To enable managed bootstrap, call `setBootstrapProvider()`.
 *
 * @public
 */
export class BootstrapConfig {
  /**
   * private local state of the bootstrap config
   *
   * Do not set this value without updating this._fingerprint.
   */
  private _config: IBootstrapConfig;

  /**
   * An function that will be called to obtain new bootstrap configuration when
   * a bootstrap refresh signal is received.
   *
   * When this value is set, this enables *managed bootstrap configuration*.
   *
   * @see BootstrapConfig
   *
   */
  private _bootstrapProvider?: BootstrapProvider;

  /**
   * private default state of the bootstrap config
   */
  private _default: IBootstrapConfig;

  /**
   * private storage for the configuration fingerprint.
   *
   * Used to detect changes to this._config on incoming requests.
   */
  private _fingerprint: string = '';

  /**
   * A channel that notifies subscribers when the bootstrap configuration has changed.
   */
  private readonly _onConfigChangeEvent: EventWithParams<BootstrapConfigChangeEventParams> =
    new EventWithParams<BootstrapConfigChangeEventParams>();

  /**
   * reference to the storage provider
   */
  private _persistentStorage?: IStorage;

  /**
   * Persistent storage key prefix
   */
  private _storageKeyPrefix: string;

  /**
   * Initialize `BootstrapConfig` with our hard-coded default values to be merged with a headwaiter bootstrap response.
   * @param defaultConfig - The default config to fallback on for things not specified by HeadWaiter
   * @param storage - An instance of a storage provider
   * @param environment - The application environment, for example: INT, STG, PRD
   * @public
   */
  public constructor(defaultConfig: IBootstrapConfig, storage?: IStorage, environment?: BoltEnvironment) {
    this._default = defaultConfig;
    this._config = this._default;
    this._fingerprint = fingerprintData(this._config);
    this._storageKeyPrefix =
      !environment || environment === BoltEnvironment.PRD ? '' : `${environment.toLowerCase()}-`;
    this._persistentStorage = storage;
  }

  /**
   * Allow downstream consumers to update `BootstrapConfig` from the latest call to /bootstrap
   * @param response - The response of a config request from HeadWaiter
   */
  public update(response?: IBootstrapConfigJson): IBootstrapConfig {
    const previousFingerprint = this._fingerprint;

    // apply a shallow merge of default and server response
    this._config = {
      ...this._default,
      ...(response || {})
    };
    this._fingerprint = fingerprintData(this._config);

    if (response) {
      this._persistentStorage?.writeSync(
        `${this._storageKeyPrefix}${CONFIG_STORAGE_KEY}`,
        JSON.stringify(this._config)
      );
    }

    if (previousFingerprint !== this._fingerprint) {
      // We do not await this event because doing so can cause the bootstrap refresh lock
      // to deadlock if a handler makes a network request.
      // The refresh timeout breaks the deadlock, but that still locks things for an unacceptable
      // amount of time.

      // eslint-disable-next-line no-void
      void this._onConfigChangeEvent.fire(this._config);
    }

    return this._config;
  }

  /**
   * Adds a listener to be called when a bootstrap configuration has changed.
   *
   * @param listener - The listener to be added to the onConfigChange event
   * @public
   */
  public onConfigChange(listener: BootstrapConfigChangeListener): Unsubscribe {
    return this._onConfigChangeEvent.addListener(listener);
  }

  /**
   * Attempts to restore a previously saved bootstrap config
   *
   * @returns the restored config if it exists
   */
  public restore(): IBootstrapConfig | undefined {
    const storedConfig = this._persistentStorage?.readSync(`${this._storageKeyPrefix}${CONFIG_STORAGE_KEY}`);
    if (storedConfig) {
      try {
        const config = JSON.parse(storedConfig);
        this._config = config;
        this._fingerprint = fingerprintData(this._config);
        return config;
      } catch {
        return undefined;
      }
    }
  }

  /**
   * returns the current bootstrap state
   * @returns
   */
  public get(): IBootstrapConfig {
    return this._config;
  }

  /**
   * returns a hash of the current bootstrap state
   * @returns
   */
  public getFingerprint(): string {
    return this._fingerprint;
  }

  /**
   * Accepts a config fingerprint.  Returns true if the fingerprint matches the
   * fingerprint of the current config.
   *
   * @param fingerprint - a bootstrap config fingerprint
   * @returns boolean - true if the fingerprints match
   */
  public isMatchingConfigFingerprint(fingerprint: string | undefined): boolean {
    return this._fingerprint === fingerprint;
  }

  /**
   * Triggers the bootstrap config provider.
   *
   * @param response - response of the API call that caused this bootstrap refresh
   * @returns a promise that resolves when the bootstrap config has been updated.
   */
  public async refreshBootstrap(response: IResponse): Promise<void> {
    if (!this._bootstrapProvider) {
      return;
    }

    try {
      const newConfig = await this._bootstrapProvider?.(response);
      await this.update(newConfig);
    } catch {
      noop();
    }
  }

  /**
   * Find route keys in template url and replace with routing values
   * @param baseUrlTemplate - baseUrl template
   * @returns
   */
  private _replaceRouteKeys(baseUrlTemplate: string): string {
    return baseUrlTemplate.replace(/\{.*?\}/gm, (substring) => {
      const routeKey = substring.replace(/{|}/g, '');
      if (routeKey in this._config.routing) {
        return this._config.routing[routeKey];
      }
      return substring;
    });
  }

  /**
   * Resolve a relative bolt path to a full request URI
   * @param path - relative path
   * @returns
   */
  public resolveRequestUrl(path: string): string {
    // ensure relative paths always start with a slash
    if (path.charAt(0) !== '/') path = `/${path}`;

    // try to find a 'startsWith' match against the endpoint table
    for (const endpoint of this._config.endpoints) {
      if (path.startsWith(endpoint.path)) {
        // construct the full bolt request url
        const baseUrlTemplate = this._config.apiGroups[endpoint.apiGroup].baseUrl;
        const baseUrl = this._replaceRouteKeys(baseUrlTemplate);
        return `${baseUrl}${path}`;
      }
    }
    // return the original relative path
    // if no match is found
    return path;
  }

  public setBootstrapProvider(bootstrapProvider: BootstrapProvider | undefined): void {
    this._bootstrapProvider = bootstrapProvider;
  }

  /**
   * Predicate function that returns true if a bootstrapProvider has been set for the
   * instance.
   *
   * @returns true if a bootstrap provider is set
   */
  public hasBootstrapProvider(): boolean {
    return !!this._bootstrapProvider;
  }
}
