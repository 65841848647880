// Copyright text placeholder, Warner Bros. Discovery, Inc.

// Design has a specific naming pattern for tokens (with dashes), which are used heavily in this file.
/* eslint-disable @typescript-eslint/naming-convention */

// ***DO NOT EDIT THIS FILE DIRECTLY***
// Generated with @wbd/generate-design-resources

/* eslint-disable @rushstack/typedef-var */

import type { TransitionTimingFunction } from '@3rdparty/lightningjs-core';

/**
 * Motion tokens derived from design data.
 * @public
 */
export const MotionToken = {
  duration: {
    xxs: 0.05,
    xs: 0.1,
    sm: 0.2,
    md: 0.3,
    lg: 0.4,
    xl: 0.5,
    xxl: 0.6
  },
  easing: {
    smooth_decelerate: 'cubic-bezier(0.00, 0.00, 0.34, 1.00)' as TransitionTimingFunction,
    balanced_in_out: 'cubic-bezier(0.66, 0.00, 0.34, 1.00)' as TransitionTimingFunction,
    emphasized_accelerate: 'cubic-bezier(0.30, 0.00, 0.80, 0.15)' as TransitionTimingFunction,
    linear: 'cubic-bezier(0.00, 0.00, 1.00, 1.00)' as TransitionTimingFunction
  }
} as const;
