// Copyright text placeholder, Warner Bros. Discovery, Inc.

/* eslint-disable @rushstack/packlets/mechanics */
import { SessionManager } from '@wbd/instrumentation-session-manager';
import type { IGlobalContext } from '../generated/context';
import type { Configuration } from './Configuration';
import type { IVersionedEvent } from './IVersionedEvent';
import type { IVersionedEventWithContext } from './IVersionedEventWithContext';
import { SessionUpdate } from './SessionUpdate';

type IEmitterPromiseCallback = () => Promise<IGlobalContext>;
type IEmitterPromise = (callback: IEmitterPromiseCallback) => IGlobalContext;

/**
 * Emitter class for telegraph events and errors, handles adding shared properties keys onto events
 * @public
 */
export class Emitter {
  private static _ALL_EVENTS_KEY: string = 'all';
  /** list of matchers to check against to attach context to */
  private static _contextMatchers: string[] = [];
  /** cache of matcher lookup results */
  private static _contextMatchersCache: Map<string, boolean> = new Map<string, boolean>();
  /** stored promised to hold event emission until ready */
  private static _contextPromise: Promise<IEmitterPromiseCallback> = new Promise((resolve) => {
    Emitter._contextPromiseResolver = resolve as IEmitterPromise;
  });
  /** context promise resolver  */
  private static _contextPromiseResolver: IEmitterPromise;
  /** collection of stored sets for callback functions */
  private static _emitCallbacks: Map<string, Set<(event: IVersionedEvent) => void>> = new Map();
  /** storage set for context callback functions  */
  private static _emitContextCallbacks: Set<(event: IVersionedEventWithContext) => void> = new Set();
  /** method to return a context if provided */

  public static initialize(config: Configuration): void {
    this._contextMatchersCache.clear();

    const matchers = config.forwarderMatchers
      // filter out invalid matches
      ?.filter((matcher) => matcher.includes('isdk:'))
      // remove the isdk: prefix
      .map((matcher) => matcher.replace('isdk:', ''))
      // convert to set to dedup items
      .reduce((acc, matcher) => acc.add(matcher), new Set<string>());

    this._contextMatchers = Array.from(matchers ?? []);
  }

  /**
   * add a listener that only fires when it matches a specific kind
   * @param kind - event schema kind
   * @param callback - callback function
   * @returns
   */
  public static addEventKindListener(kind: string, callback: (event: IVersionedEvent) => void): void {
    this._emitCallbacks.set(kind, this._getSetByKey(kind).add(callback));
  }

  /**
   * adds an event listener to fire with additional context
   * will only fire if the matchers are met
   * @param callback - callback function to fire
   */
  public static addEventWithContextListener(callback: (event: IVersionedEventWithContext) => void): void {
    this._emitContextCallbacks.add(callback);
  }

  /**
   * adds an event listener to fire whenever any event is emitted
   * @param callback - callback function to fire
   */
  public static addEventListener(callback: (event: IVersionedEvent) => void): void {
    this._emitCallbacks.set(this._ALL_EVENTS_KEY, this._getSetByKey(this._ALL_EVENTS_KEY).add(callback));
  }

  /**
   * add a listener that only fires when it matches a specific event type
   * @param eventType - event schema type
   * @param callback - callback function
   */
  public static addEventTypeListener<TEventPayload>(
    eventType: string,
    callback: (event: IVersionedEvent<TEventPayload>) => void
  ): void {
    this._emitCallbacks.set(
      eventType,
      this._getSetByKey(eventType).add(callback as (event: IVersionedEvent) => void)
    );
  }

  /**
   * Decorate the Event with shared properties keys needed for this event and send to the Queue
   * @param event - the versioned event data to be added
   * @returns promise
   */
  public static async emitEventAsync(event: IVersionedEvent): Promise<void> {
    // if the event is expected to extend the session, tell the session manager to extend the session
    if (event.sessionUpdate === SessionUpdate.EXTEND) {
      SessionManager.extendSession();
    }

    // add the provided context
    const context = await this._contextPromise.then((contextProviderCallback) => contextProviderCallback());

    this._emitToEventKindListeners(event);
    this._emitToEventTypeListeners(event);
    this._emitToEventListener(event);
    this._emitToEventContextListener(event, context);
  }

  /**
   * Looks up the callbacks by key and emits the event to them
   * @param key - the key to lookup callbacks by
   * @param event - the event to emit to the callbacks
   */
  private static _emitEventByKey(key: string, event: IVersionedEvent): void {
    const keySet = Emitter._emitCallbacks.get(key);

    if (keySet) {
      keySet.forEach((functionCallback) => functionCallback(event));
    }
  }

  /**
   * emits an event to the listeners that is associated with the event.eventType
   * @param event - the versioned event data to be emitted to the kind listener
   */
  private static _emitToEventTypeListeners(event: IVersionedEvent): void {
    this._emitEventByKey(event.eventType, event);
  }

  /**
   * emits an event to the listeners that is associated with the event.kind
   * @param event - the versioned event data to be emitted to the kind listener
   */
  private static _emitToEventKindListeners(event: IVersionedEvent): void {
    const kind = event.eventType.split(/\./)[1];
    this._emitEventByKey(kind, event);
  }

  /**
   * emits an event to the enriched event listener
   * @param event - event to emit
   * @param context - global context to attach to the event
   * @returns
   */
  private static _emitToEventContextListener(event: IVersionedEvent, context?: IGlobalContext): void {
    // validate that the event should be queued
    if (!context || !this._emitContextCallbacks.size || !this._shouldContextualize(event)) {
      return;
    }

    // if its on the dispatch list and a dispatcher method is set, call the dispatcher
    this._emitContextCallbacks.forEach((functionCallback) => functionCallback({ ...context, event }));
  }

  /**
   * emits an event to the default all listener
   * @param event - the event to emit
   * @returns
   */
  private static _emitToEventListener(event: IVersionedEvent): void {
    this._emitEventByKey(this._ALL_EVENTS_KEY, event);
  }

  /**
   * gets the set of callbacks by key
   * @param key - string key to get the set of callbacks by
   * @returns
   */
  private static _getSetByKey(key: string): Set<(event: IVersionedEvent) => void> {
    return this._emitCallbacks.get(key) ?? new Set();
  }

  /**
   * sets the context callback getter
   * @param callback - callback function to be called to get the context
   */
  public static setContextProvider(callback: () => Promise<IGlobalContext>): void {
    this._contextPromiseResolver(callback);
  }

  /**
   * checks if the event should include additional context
   * @param event - the event to check if it should be queued
   * @returns boolean
   */
  private static _shouldContextualize(event: IVersionedEvent): boolean {
    const { eventType } = event;
    const cachedValue = this._contextMatchersCache.get(eventType);

    if (cachedValue !== undefined) {
      return cachedValue;
    }

    const hasMatch = this._contextMatchers.some((matcher) => this._matchesEventType(matcher, eventType));
    const isRejected = this._contextMatchers.some((matcher) => this._rejectsEventType(matcher, eventType));

    const shouldQueue = hasMatch && !isRejected;

    this._contextMatchersCache.set(eventType, shouldQueue);

    return shouldQueue;
  }

  /**
   * checks if the eventType matches the matcher
   * @param matcher - string partial to check eventType against
   * @param eventType - string to check against matcher
   * @returns boolean if the eventType matches the matcher
   */
  private static _matchesEventType(matcher: string, eventType: IVersionedEvent['eventType']): boolean {
    let modifiedMatcher = matcher.replace('!', '');

    // wild card matchers, matches everything
    if (matcher === '*') {
      return true;
    }

    // if wildcard matchers in the string, remove it
    if (matcher.endsWith('*')) {
      modifiedMatcher = modifiedMatcher.slice(0, -1);
    }

    return eventType.startsWith(modifiedMatcher);
  }

  /**
   * checks if the eventType is rejected by any matcher
   * @param matcher - string partial to check eventType against
   * @param eventType - string to check against matcher
   * @returns boolean if the eventType matches the matcher
   */
  private static _rejectsEventType(matcher: string, eventType: IVersionedEvent['eventType']): boolean {
    const hasMatch = this._matchesEventType(matcher, eventType);
    return matcher.startsWith('!') && hasMatch;
  }
}
