// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { DecisionType, type ILabsDecisionData } from '../types';

/**
 * Resolve final decision from local and server labs decision.
 * @public
 *
 * ////////// Decision Merging Strategy //////////
 * Response + Local = Decision
 * undefined + (on: false, config: (a: “b”)) = (on: false, config: (a: “b”))
 * (on: true, config: null) + (on: false, config: (a: “b”)) = (on:true, config: (a: “b”)
 * (on: true, config: ()) + ( on: false, config: (a: “b”)) = (on:true, config: ())
 * (on: true, config: (b: “c”)) + ( on: false, config: (a: “b”)) = (on:true, config: (b: “c”))
 *
 * @see https://wbdstreaming.atlassian.net/wiki/spaces/ARC/pages/1051361304/GCX-LADR+093+Experimentation+with+Labs#:~:text=undefined%20%2B%20%7B%20on%3A-,false,-%2C%20config%3A%20%7Ba%3A%20%E2%80%9Cb
 * @param labsDecision - Server response decision
 * @param localDecision - Provided local decision
 * @returns final decision
 */
export function getDecision<T>({
  labsDecision,
  localDecision
}: {
  labsDecision: ILabsDecisionData<T> | undefined;
  localDecision: ILabsDecisionData<T> | undefined;
}): ILabsDecisionData<T> {
  if (localDecision?.type === DecisionType.STATIC) return localDecision;

  // Note: the order of the spread operator is important here.
  // The local decision must be spread first to ensure that the server decision takes priority.
  const mergedDecision = {
    ...localDecision,
    ...labsDecision,
    config: labsDecision?.config ?? localDecision?.config,
    on: labsDecision?.on ?? localDecision?.on ?? false
  };

  return mergedDecision as ILabsDecisionData<T>;
}
