// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { SiteTokenManager } from '../site-token';
import type { IAuthMigrationDetails } from '@wbd/beam-ctv-retain-auth';

const siteTokenManager: SiteTokenManager = new SiteTokenManager();

/**
 * Default account implementation
 * @public
 */
export class AbstractAccount {
  // prohibit class initialization as we only want to use te static functions
  protected constructor() {}
  /**
   * Tracks a new user sign in
   *
   * @param entitlements - the list of products the user is entitled to
   */
  public static trackSignIn(entitlements?: string[]): void {}

  /**
   * Tracks a silent sign-in on app launch
   *
   * @param entitlements - the list of products the user is entitled to
   */
  public static trackSilentSignIn(entitlements?: string[]): void {}

  /**
   * Tracks an implicit or explicit sign out
   */
  public static trackSignOut(): void {}

  /**
   * Fetches stored site token
   */
  public static getHotelSiteToken(): string | undefined {
    return siteTokenManager.getToken();
  }

  /**
   * Fetches the auth migration credentials
   */
  public static async getAuthMigrationDetailsAsync?(): Promise<IAuthMigrationDetails>;
}

/**
 * account implementation interface
 * @public
 */
export type IAccount = Omit<typeof AbstractAccount, 'prototype'>;
