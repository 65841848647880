// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { RequestConfigOverride } from '../../http-client';
import { HttpClient } from '../../http-client';
import type { IUserEntitlementResponse } from './interfaces/IUserEntitlementResponse';

/**
 * Gets a high level summary of the customer entitlements.
 * The use cases for this endpoint includes for clients to decide if a Paywall should be displayed, and if it in such cases should contain win back information for lapsed users.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getUserEntitlementsRequest = async (
  config?: RequestConfigOverride
): Promise<IUserEntitlementResponse> => {
  const response = await HttpClient.instance.get<IUserEntitlementResponse>(
    '/entitlements/userEntitlementsSummary/me',
    config
  );
  return response.data;
};
