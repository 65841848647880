// Copyright text placeholder, Warner Bros. Discovery, Inc.

interface IMilestone {
  title: string;
  timeDiffSeconds: number;
  accumulatedSeconds: number;
  time: number;
}

let milestones: IMilestone[] = [];

function getAppStartTime(startTime: number | undefined): number {
  const appStartTime = window.launchTime ? window.launchTime : Date.now();
  const absoluteStart = startTime ?? appStartTime;
  return absoluteStart;
}

/**
 * Store / Track the amount of time taken from A to B
 */
export function trackMilestone(title: string, startTime?: number): void {
  const timeNow = Date.now();
  const prevTime = milestones[milestones.length - 1]?.time ?? getAppStartTime(startTime);
  const timeDiff = timeNow - prevTime;
  const timeDiffSeconds = timeDiff / 1000;
  const accTimeDiff = timeNow - getAppStartTime(startTime);
  const accTimeDiffSeconds = accTimeDiff / 1000;

  const track: IMilestone = {
    title: title,
    timeDiffSeconds: timeDiffSeconds,
    accumulatedSeconds: accTimeDiffSeconds,
    time: timeNow
  };
  milestones.push(track);
}

export function clearMilestones(): void {
  milestones = [];
}

export function getMilestones(): IMilestone[] {
  return milestones;
}
