// Copyright text placeholder, Warner Bros. Discovery, Inc.

// Design has a specific naming pattern for tokens (with dashes), which are used heavily in this file.
/* eslint-disable @typescript-eslint/naming-convention */

// ***DO NOT EDIT THIS FILE DIRECTLY***
// Generated with @wbd/generate-design-resources

/* eslint-disable @rushstack/typedef-var */

/**
 * Elevation tokens derived from design data.
 * @public
 */
export const ElevationTokens = {
  shadow_none: {
    offsetX: 0,
    offsetY: 0,
    radius: 0,
    spread: 0,
    color: 0x00000000
  },
  shadow_01: {
    offsetX: 0,
    offsetY: 2,
    radius: 8,
    spread: 0,
    color: 0x59000000
  },
  shadow_02: {
    offsetX: 0,
    offsetY: 4,
    radius: 10,
    spread: 0,
    color: 0x4d000000
  },
  shadow_offset_left: {
    offsetX: -20,
    offsetY: 0,
    radius: 12,
    spread: 0,
    color: 0x99000000
  }
} as const;
