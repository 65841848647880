// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { JsonApiResource } from 'json-api-models';
import type { Config } from '../data-models';
import type { RequestConfigOverride } from '../http-client';
import { HttpClient } from '../http-client';
import type { DataTypes, ITypedJsonApiDocumentFromModel } from '../json-api';
import { CMSRequestType } from './CMSRequestType';
import type { IBoltConfig } from './IBoltConfig';
import { getCMSParams } from './getCMSParams';

/**
 * @internal
 */
export type IConfigResponse = ITypedJsonApiDocumentFromModel<DataTypes.Config, Config>;

/**
 * Get config by alias.
 * @param alias - The alias of the config.
 * @param config - The request config used to pass in properties like an abort signal.
 * @internal
 */
export const getConfigRequest = async (
  alias: string,
  config?: RequestConfigOverride
): Promise<IConfigResponse> => {
  const response = await HttpClient.instance.get<IConfigResponse>(`/cms/${CMSRequestType.CONFIGS}/${alias}`, {
    params: getCMSParams(undefined, false),
    signal: config?.signal
  });
  return response.data;
};

/**
 * Get bolt platform config
 * @param alias - The alias of the route
 * @param config - The request config is used to pass in properties like an abort signal.
 * @public
 */
export const getConfig = async (alias: string, config?: RequestConfigOverride): Promise<IBoltConfig> => {
  const boltConfig = await getConfigRequest(alias, config);

  const { data, meta } = boltConfig || {};
  const site = meta?.site as JsonApiResource;
  const configData = data?.attributes?.config as { config?: Omit<IBoltConfig, 'site'> };

  return (
    configData &&
    site && {
      ...configData,
      site: { id: site.id, ...site.attributes }
    }
  );
};
