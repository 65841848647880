// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IConsentsPayload } from './IConsentsPayload';
import type { IExperiencePayload } from './IExperiencePayload';
import type { ILocalizationPayload } from './ILocalizationPayload';

/**
 * Unique non encrypted session payload keys
 * @see {@link https://wbdstreaming.atlassian.net/wiki/spaces/CON/pages/429129872/Session+Context+Payloads}
 * @public
 */
export enum PlainSessionPayloadKey {
  /**
   * session consents payload key
   */
  Consents = 'consents',

  /**
   * session experience payload key
   */
  Experience = 'experience',

  /**
   * session localization payload key
   */
  Localization = 'localization'
}

/**
 * Unique encrypted session payload keys
 * @public
 */
export enum EncryptedSessionPayloadKey {
  /**
   * session overrides payload key
   */
  Overrides = 'overrides'
}

/**
 * Session payload key
 * @public
 */
export type SessionPayloadKey = PlainSessionPayloadKey | EncryptedSessionPayloadKey;

/**
 * Session payload value for the given session payload key
 * @public
 */

export type SessionPayloadValueForKey<T extends SessionPayloadKey> =
  | {
      [PlainSessionPayloadKey.Consents]: IConsentsPayload;
      [PlainSessionPayloadKey.Experience]: IExperiencePayload;
      [PlainSessionPayloadKey.Localization]: ILocalizationPayload;
      [EncryptedSessionPayloadKey.Overrides]: string;
    }[T]
  | undefined;

/**
 * Session payload event value for given the payload key
 * @public
 */
export type SessionPayloadEventValueForKey<T extends SessionPayloadKey> = [SessionPayloadValueForKey<T>];
