// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IHttpError } from '@wbd/bolt-http';
import { isHttpError } from '@wbd/bolt-http';
import type { ISonicError } from '../../http-client';

/**
 * Extracts resource errors from the response and detects whether it contains
 * an invalid token error
 *
 * @public
 */
export function hasInvalidTokenError(maybeHttpError: unknown): boolean {
  if (!isHttpError(maybeHttpError)) {
    return false;
  }

  const errors = (maybeHttpError as IHttpError<{ errors: ISonicError[] }>).response?.data?.errors;
  return Array.isArray(errors) && errors.some((error) => error.code === 'invalid.token');
}
