// Copyright text placeholder, Warner Bros. Discovery, Inc.

// Design has a specific naming pattern for tokens (with dashes), which are used heavily in this file.
/* eslint-disable @typescript-eslint/naming-convention */

// ***DO NOT EDIT THIS FILE DIRECTLY***
// Generated with @wbd/generate-design-resources

/* eslint-disable @rushstack/typedef-var */

/**
 * Gradient tokens derived from design data
 * @public
 */
export const GradientTokens = {
  top_to_bottom: {
    light_30: {
      linear: {
        angle: 180,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 0.7)' },
          { position: 0.15, color: 'rgba(0, 0, 0, 0.5)' },
          { position: 0.3, color: 'rgba(0, 0, 0, 0.1)' },
          { position: 0.4, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    },
    heavy_70: {
      linear: {
        angle: 180,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 1.0)' },
          { position: 0.3, color: 'rgba(0, 0, 0, 0.8)' },
          { position: 1, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    },
    heavy_100_surface_accent: {
      linear: {
        angle: 180,
        colors: [
          { position: 0, color: 'rgba(15, 21, 51, 1.0)' },
          { position: 1, color: 'rgba(15, 21, 51, 0)' }
        ]
      }
    }
  },
  bottom_to_top: {
    medium_55: {
      linear: {
        angle: 0,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 0.7)' },
          { position: 0.3, color: 'rgba(0, 0, 0, 0.2)' },
          { position: 0.55, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    },
    medium_65: {
      linear: {
        angle: 0,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 0.85)' },
          { position: 0.38, color: 'rgba(0, 0, 0, 0.66)' },
          { position: 0.53, color: 'rgba(0, 0, 0, 0.34)' },
          { position: 0.65, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    },
    medium_100: {
      linear: {
        angle: 0,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 0.7)' },
          { position: 0.7, color: 'rgba(0, 0, 0, 0.2)' },
          { position: 1, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    },
    heavy_35: {
      linear: {
        angle: 0,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 1.0)' },
          { position: 0.18, color: 'rgba(0, 0, 0, 0.2)' },
          { position: 0.35, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    },
    heavy_50: {
      linear: {
        angle: 0,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 1.0)' },
          { position: 0.5, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    },
    heavy_55: {
      linear: {
        angle: 0,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 1.0)' },
          { position: 0.3, color: 'rgba(0, 0, 0, 0.2)' },
          { position: 0.55, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    },
    heavy_100_surface_accent: {
      linear: {
        angle: 0,
        colors: [
          { position: 0, color: 'rgba(15, 21, 51, 1.0)' },
          { position: 1, color: 'rgba(15, 21, 51, 0)' }
        ]
      }
    }
  },
  left_to_right: {
    lightest_100: {
      linear: {
        angle: 90,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 0.1)' },
          { position: 1, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    },
    light_65: {
      linear: {
        angle: 90,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 0.6)' },
          { position: 0.45, color: 'rgba(0, 0, 0, 0.37)' },
          { position: 0.65, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    },
    medium_65: {
      linear: {
        angle: 90,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 0.65)' },
          { position: 0.25, color: 'rgba(0, 0, 0, 0.45)' },
          { position: 0.45, color: 'rgba(0, 0, 0, 0.2)' },
          { position: 0.65, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    },
    heavy_50: {
      linear: {
        angle: 90,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 1.0)' },
          { position: 0.15, color: 'rgba(0, 0, 0, 0.8)' },
          { position: 0.5, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    },
    heavy_70: {
      linear: {
        angle: 90,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 1.0)' },
          { position: 0.35, color: 'rgba(0, 0, 0, 0.85)' },
          { position: 0.7, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    },
    heaviest_100: {
      linear: {
        angle: 90,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 1.0)' },
          { position: 0.5, color: 'rgba(0, 0, 0, 0.8)' },
          { position: 1, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    },
    heaviest_100_full: {
      linear: {
        angle: 90,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 1.0)' },
          { position: 0.5, color: 'rgba(0, 0, 0, 0.8)' },
          { position: 1, color: 'rgba(0, 0, 0, 0.6)' }
        ]
      }
    }
  },
  right_to_left: {
    lightest_100: {
      linear: {
        angle: 270,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 0.1)' },
          { position: 1, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    }
  },
  to_bottom_left: {
    light_50: {
      linear: {
        angle: 225,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 0.5)' },
          { position: 0.12, color: 'rgba(0, 0, 0, 0.3)' },
          { position: 0.24, color: 'rgba(0, 0, 0, 0.2)' },
          { position: 0.5, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    }
  },
  to_top_right: {
    light_50: {
      linear: {
        angle: 45,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 0.50)' },
          { position: 0.12, color: 'rgba(0, 0, 0, 0.3)' },
          { position: 0.24, color: 'rgba(0, 0, 0, 0.2)' },
          { position: 0.5, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    },
    medium_75: {
      linear: {
        angle: 30,
        colors: [
          { position: 0, color: 'rgba(0, 0, 0, 0.65)' },
          { position: 0.5, color: 'rgba(0, 0, 0, 0.25)' },
          { position: 0.75, color: 'rgba(0, 0, 0, 0.0)' }
        ]
      }
    }
  }
} as const;
