// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IconAsset } from '@wbd/fuse-ctv-resources-common';

import iconAddSVG from './add-regular.svg';
import iconAddSolidSVG from './add-solid.svg';
import iconBackSVG from './back-regular-28.svg';
import iconCheckSVG from './check-regular-28.svg';
import iconCheckSolidSVG from './check-solid-28.svg';
import iconChevronDownSVG from './chevron-down-regular.svg';
import iconChevronLeftSVG from './chevron-left-regular.svg';
import iconChevronRightSVG from './chevron-right-regular.svg';
import iconChevronUpSVG from './chevron-up-regular.svg';
import iconDeleteSVG from './delete-regular.svg';
import iconEditSVG from './edit-regular.svg';
import iconErrorSVG from './error-regular-24.svg';
import iconEyeHideSVG from './eye-hide-regular.svg';
import iconEyeShowSVG from './eye-show-regular.svg';
import iconForward2SVG from './forward-2-regular.svg';
import iconHomeSVG from './home-regular.svg';
import iconInfoSVG from './info-regular.svg';
import iconKeyBackspaceSVG from './key-backspace-regular.svg';
import iconKeyHideSVG from './key-hide-regular.svg';
import iconKeyLanguageSVG from './key-language-regular.svg';
import iconKeyNextSVG from './key-next-regular.svg';
import iconKeySVG from './key-regular-28.svg';
import iconKeyShiftSVG from './key-shift-regular.svg';
import iconKeyShiftSolidSVG from './key-shift-solid.svg';
import iconSpaceSVG from './key-space-regular.svg';
import iconSpaceTripleSVG from './key-space-triple-regular.svg';
import iconLanguageSVG from './language-regular.svg';
import iconLockClosedSVG from './lock-closed-regular-28.svg';
import iconMenuSVG from './menu-regular.svg';
import iconMoreEpisodesSVG from './more-episodes-regular.svg';
import iconPauseSVG from './pause-regular.svg';
import iconPlaySVG from './play-regular.svg';
import iconPressAndHoldSVG from './press-and-hold-regular.svg';
import iconProfileSVG from './profile-regular.svg';
import iconRemoveSVG from './remove-regular.svg';
import iconRestartSVG from './restart-regular.svg';
import iconSearchSVG from './search-regular.svg';
import iconSeriesSVG from './series-regular.svg';
import iconSettingsSVG from './settings-regular.svg';
import iconTrailerSVG from './trailer-regular.svg';

/**
 * @public
 */
export const iconAdd: IconAsset = iconAddSVG;

/**
 * @public
 */
export const iconAddSolid: IconAsset = iconAddSolidSVG;

/**
 * @public
 */
export const iconBack: IconAsset = iconBackSVG;

/**
 * @public
 */
export const iconCheck: IconAsset = iconCheckSVG;

/**
 * @public
 */
export const iconCheckSolid: IconAsset = iconCheckSolidSVG;

/**
 * @public
 */
export const iconChevronDown: IconAsset = iconChevronDownSVG;

/**
 * @public
 */
export const iconChevronLeft: IconAsset = iconChevronLeftSVG;

/**
 * @public
 */
export const iconChevronRight: IconAsset = iconChevronRightSVG;

/**
 * @public
 */
export const iconChevronUp: IconAsset = iconChevronUpSVG;

/**
 * @public
 */
export const iconDelete: IconAsset = iconDeleteSVG;

/**
 * @public
 */
export const iconEdit: IconAsset = iconEditSVG;

/**
 * @public
 */
export const iconError: IconAsset = iconErrorSVG;

/**
 * @public
 */
export const iconEyeHide: IconAsset = iconEyeHideSVG;

/**
 * @public
 */
export const iconEyeShow: IconAsset = iconEyeShowSVG;

/**
 * @public
 */
export const iconForward2: IconAsset = iconForward2SVG;

/**
 * @public
 */
export const iconHome: IconAsset = iconHomeSVG;

/**
 * @public
 */
export const iconInfo: IconAsset = iconInfoSVG;

/**
 * @public
 */
export const iconKeyBackspace: IconAsset = iconKeyBackspaceSVG;

/**
 * @public
 */
export const iconKeyHide: IconAsset = iconKeyHideSVG;

/**
 * @public
 */
export const iconKeyLanguage: IconAsset = iconKeyLanguageSVG;

/**
 * @public
 */
export const iconKeyNext: IconAsset = iconKeyNextSVG;

/**
 * @public
 */
export const iconKey: IconAsset = iconKeySVG;

/**
 * @public
 */
export const iconKeyShift: IconAsset = iconKeyShiftSVG;

/**
 * @public
 */
export const iconKeyShiftSolid: IconAsset = iconKeyShiftSolidSVG;

/**
 * @public
 */
export const iconSpace: IconAsset = iconSpaceSVG;

/**
 * @public
 */
export const iconSpaceTriple: IconAsset = iconSpaceTripleSVG;

/**
 * @public
 */
export const iconLanguage: IconAsset = iconLanguageSVG;

/**
 * @public
 */
export const iconLockClosed: IconAsset = iconLockClosedSVG;

/**
 * @public
 */
export const iconMenu: IconAsset = iconMenuSVG;

/**
 * @public
 */
export const iconMoreEpisodes: IconAsset = iconMoreEpisodesSVG;

/**
 * @public
 */
export const iconPause: IconAsset = iconPauseSVG;

/**
 * @public
 */
export const iconPlay: IconAsset = iconPlaySVG;

/**
 * @public
 */
export const iconPressAndHold: IconAsset = iconPressAndHoldSVG;

/**
 * @public
 */
export const iconProfile: IconAsset = iconProfileSVG;

/**
 * @public
 */
export const iconRemove: IconAsset = iconRemoveSVG;

/**
 * @public
 */
export const iconRestart: IconAsset = iconRestartSVG;

/**
 * @public
 */
export const iconSearch: IconAsset = iconSearchSVG;

/**
 * @public
 */
export const iconSeries: IconAsset = iconSeriesSVG;

/**
 * @public
 */
export const iconSettings: IconAsset = iconSettingsSVG;

/**
 * @public
 */
export const iconTrailer: IconAsset = iconTrailerSVG;
