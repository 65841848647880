// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { createConsoleAdapter, loggerFactory, LogLevel, type ILogger } from '@wbd/beam-ctv-logger';
import type { IPlatformAdapter } from '@wbd/beam-ctv-platform-adapters';
import {
  addErrorsListener,
  addEventsListener,
  ApplicationLaunchStateChangeState,
  InstrumentationSDK,
  PerformanceMark,
  PerformanceMarkName,
  iApplicationLaunchStateChange,
  type IApplicationLaunchStateChangePayload,
  type IVersionedEvent
} from '../instrumentation';
import { SessionManager } from '@wbd/instrumentation-session-manager';

export function bootstrapInstrumentation(platformAdapter: IPlatformAdapter): void {
  const logLevel = DEBUG ? LogLevel.DEBUG : LogLevel.ERROR;
  const urlLogLevel = platformAdapter.device.getURLParams().get('loglevel');

  loggerFactory.setLevel(urlLogLevel && !isNaN(Number(urlLogLevel)) ? Number(urlLogLevel) : logLevel);

  loggerFactory.attachAdapter(createConsoleAdapter());

  const instrumentationLogger = loggerFactory.createLogger('Instrumentation');
  const errorLogger = loggerFactory.createLogger('Error');

  configureLaunchStateChange(instrumentationLogger);

  addErrorsListener((event: IVersionedEvent) => {
    const err = (event.payload as { err?: { stackTrace?: string; message: string; scope: string } }).err;
    if (err?.scope === 'USER_FACING') {
      instrumentationLogger.error(event.eventType, event.payload);
      errorLogger.error(err.stackTrace ?? err.message);
    } else {
      instrumentationLogger.warn(event.eventType, event.payload);
      if (err?.message) errorLogger.warn(err.stackTrace ?? err.message);
    }
  });

  addEventsListener((event: IVersionedEvent) => {
    instrumentationLogger.debug(event.eventType, event.payload);
  });

  SessionManager.setStorage(platformAdapter.storage);

  InstrumentationSDK.initialize({});

  PerformanceMark.createMark(
    {
      name: PerformanceMarkName.BOOT_START,
      page: { uri: '/' }
    },
    window.launchTime
  );
}

function configureLaunchStateChange(logger: ILogger): void {
  // @ts-ignore
  const isWebkit = typeof document.webkitHidden !== 'undefined';
  const visibilitychangeEventType = isWebkit ? 'webkitvisibilitychange' : 'visibilitychange';
  const emitLaunchStateChange = (state: ApplicationLaunchStateChangeState): void => {
    iApplicationLaunchStateChange.emit({
      state
    });
  };

  const webkitvisibilitychangeListener = (): void => {
    // @ts-ignore
    if (document[isWebkit ? 'webkitHidden' : 'hidden']) {
      emitLaunchStateChange(ApplicationLaunchStateChangeState.SUSPENDED);
    } else {
      emitLaunchStateChange(ApplicationLaunchStateChangeState.RESUMED);
    }
  };

  iApplicationLaunchStateChange.addEventListener(
    (event: IVersionedEvent<IApplicationLaunchStateChangePayload>) => {
      if (event.payload.state === ApplicationLaunchStateChangeState.COMPLETED) {
        document.removeEventListener(visibilitychangeEventType, webkitvisibilitychangeListener);
      }
    }
  );

  document.addEventListener(visibilitychangeEventType, webkitvisibilitychangeListener, true);

  emitLaunchStateChange(ApplicationLaunchStateChangeState.STARTED);
}
