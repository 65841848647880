// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IPerformanceMarkV1Payload, iPerformanceMarkV1 } from '../../generated';

/**
 * The mark monitor class used create marks
 * @public
 */
export class MarkManager {
  private _storedMarks: Map<string, IPerformanceMarkV1Payload & { deviceOccuredAt: number }> = new Map();
  private _v1: typeof iPerformanceMarkV1 = iPerformanceMarkV1;

  /**
   * creates, stores and emits a mark
   * @param payload - create mark payload
   * @param deviceOccuredAt - timestamp of when the mark was created
   */
  public createMark(payload: IPerformanceMarkV1Payload, deviceOccuredAt?: number): void {
    if (payload.name) {
      const now = deviceOccuredAt ?? Date.now();
      this._storedMarks.set(payload.name, { ...payload, deviceOccuredAt: now });
      this._v1.emit(payload, now);
    }
  }

  /**
   * gets a mark from storage
   * @param name - mark name
   * @returns performance mark payload with deviceOccuredAt timestamp
   */
  public getMark(name: string): (IPerformanceMarkV1Payload & { deviceOccuredAt: number }) | undefined {
    return this._storedMarks.get(name);
  }
}
