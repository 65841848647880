// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { RequestConfigOverride } from '../../http-client';
import { HttpClient } from '../../http-client';
import type { IGetProfilesResponse } from './interfaces/IGetProfilesResponse';

/**
 * Returns a list of all profiles for the user.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getProfilesRequest = async (config?: RequestConfigOverride): Promise<IGetProfilesResponse> => {
  const response = await HttpClient.instance.get<IGetProfilesResponse>('/users/me/profiles', config);
  return response.data;
};
