// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IPlaybackEngineCreator } from '@wbd/player-sdk-core';
import type { IEngineCreatorConfig } from './IEngineCreatorConfig';
import type { IScrubberOptions } from './IScrubberOptions';
import type { IDisplaySwapper } from '@wbd/player-sdk-gluon-engine';
import {
  parseGluonPlaybackEngineLabsConfig,
  mergeGluonPlaybackEngineLabsConfig
} from '@wbd/player-sdk-gluon-engine';
import { defaultScrubberOptions, defaultVideoGCInterval, defaultPlayerEngineConfig } from './utils';
import type { DevicePerformance } from '../device';
import { type IFeatureFlagResponse } from '@wbd/bolt-dataservice';

/**
 * video implementation interface
 * @public
 */
export class AbstractVideoCapabilities {
  // prohibit class initialization as we only want to use te static functions
  protected constructor() {}

  /**
   * Returns the appropriate video engine for the targeted platform
   * @param config - video engine initialization configuration
   * @returns GluonPlaybackEngineCreator
   */
  public static async getPlayerEngineCreatorAsync(
    config?: IEngineCreatorConfig,
    displaySwapper?: IDisplaySwapper
  ): Promise<IPlaybackEngineCreator> {
    const { GluonPlaybackEngineCreator } = await import(
      /*webpackChunkName:"player-sdk-engine-gluon"*/ '@wbd/player-sdk-gluon-engine'
    );
    return new GluonPlaybackEngineCreator(config, displaySwapper);
  }

  /**
   * Returns the engine creator override config for the target platform
   * @returns IEngineCreatorConfig
   */
  protected static _getPlayerEngineConfigOverrideAsync(): Promise<IEngineCreatorConfig> {
    return Promise.resolve({});
  }

  /**
   * Returns the merged engine creator config for the target platform
   * Merges `defaultPlayerEngineConfig` and
   * override result from `_getPlayerEngineConfigOverrideAsync`
   * @returns IEngineCreatorConfig
   */
  public static async getPlayerEngineConfigAsync(
    featureFlagResponse?: IFeatureFlagResponse
  ): Promise<IEngineCreatorConfig> {
    const configOverride = await this._getPlayerEngineConfigOverrideAsync();
    return mergeGluonPlaybackEngineLabsConfig(
      { ...defaultPlayerEngineConfig, ...configOverride },
      parseGluonPlaybackEngineLabsConfig(featureFlagResponse)
    );
  }

  public static getDisplaySwapper(): IDisplaySwapper | undefined {
    return undefined;
  }

  /**
   * returns override default engine config with inline playback specific options
   * @returns Promise<IEngineCreatorConfig>
   */
  public static async getInlinePlayerEngineConfigAsync(
    featureFlagResponse?: IFeatureFlagResponse
  ): Promise<IEngineCreatorConfig> {
    const defaultEngineConfig = await this.getPlayerEngineConfigAsync(featureFlagResponse);
    const bufferOptions = defaultEngineConfig.bufferOptions ?? {};
    return {
      ...defaultEngineConfig,
      bufferOptions: {
        ...bufferOptions,
        noAudio: true
      }
    };
  }

  /**
   * Determine if device supports DOM rendering for subtitles
   * @returns boolean
   */
  public static supportsDomCueRendering(): boolean {
    return false;
  }

  /**
   * Returns default Player Scrubber options.
   * Each of the device platforms then can override those options
   * and pass specific tweaks so that we have unique scrubber experience
   *
   * @param deviceModel - Can be used to switch between configuration on a device model basis (e.g. X1)
   * @param devicePerformance - Can be used to switch between configuration on a device performance basis (e.g. WebOS)
   * @returns
   */
  public static getScrubberOptions(
    deviceModel?: string,
    devicePerformance?: DevicePerformance
  ): IScrubberOptions {
    return defaultScrubberOptions;
  }

  /**
   * Determine if device supports trick play scrubbing
   * e.g when user is scrubbing the video player there is
   * thumbnail preview of the video frame
   * @returns boolean
   */
  public static async supportsTrickPlayScrubbing(): Promise<boolean> {
    return Promise.resolve(true);
  }

  /**
   * Returns the default video GC interval
   * that is used on the Video Player page
   * to manually trigger GC for memory cleanup
   * @returns number
   */
  public static getVideoGCInterval(): number {
    return defaultVideoGCInterval;
  }
}

/**
 * video implementation interface
 * @public
 */
export type IVideoCapabilities = Omit<typeof AbstractVideoCapabilities, 'prototype'>;
