// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { debounce } from '@wbd/beam-js-extensions';
import {
  featureFlagsRequest,
  type IFeatureFlagOptions,
  type IFeatureFlagResponse
} from '@wbd/bolt-dataservice';
import { BoltEnvironment, type BoltHttpClient } from '@wbd/bolt-http';
import { LabsSDK, LabsSDKEvents, type ILabsSDKOptions } from '@wbd/labs-sdk';
import {
  IdentityFeatureFlagsErrorClassification,
  IdentityFeatureFlagsErrorScope,
  LabsDecisionAccessedSource,
  generateErr,
  iIdentityFeatureFlagsError,
  iLabsDecisionAccessed
} from '../../instrumentation';
import {
  LABS_FLAGS_DEFAULTS,
  LABS_FLAGS_VALIDATORS,
  type ICDevFeatureFlagConfig,
  type ILabsService,
  type IPlatformDeviceInfo
} from '../../service';

const CDEV_LABS_PROJECT_IDS: Record<BoltEnvironment, string> = {
  [BoltEnvironment.DEV]: 'b0533db5-c169-472c-8fd0-bd9885049a8f',
  [BoltEnvironment.INT]: '9238f465-6cfc-44e9-bd87-ace0b24737d2',
  [BoltEnvironment.STG]: 'dc1343cd-4b7d-4c21-889c-5eacf5b73c3d',
  [BoltEnvironment.PRD]: 'af9e9ccd-689d-4b72-b03c-e54455369b2c'
};

/**
 * Available Segment ids that we can send to labs
 * https://wbdstreaming.atlassian.net/wiki/spaces/GCX/pages/279085057/CDEV-LADR+004+Labs+Flags
 */
export enum LabsSegmentIds {
  /**
   * An optional unique string for segmenting a specific device model and/or chipset
   */
  DEVICE_CHIPSET = 'deviceChipset',
  /**
   * @deprecated Instead use osModelYear
   */
  DEVICE_GROUP = 'deviceGroup',
  /**
   * Expects the performance string ('high', 'average', 'low') that comes from DevicePerformance.ts
   */
  DEVICE_PERFORMANCE = 'devicePerformance',
  /**
   * This segment expects platform-year. E.g. 'samsungtv-2016'
   */
  OS_MODEL_YEAR = 'osModelYear'
}

export async function createLabsService(
  boltHttp: BoltHttpClient,
  deviceInfo: IPlatformDeviceInfo,
  environment: BoltEnvironment
): Promise<ILabsService> {
  let labsFlags: Partial<IFeatureFlagResponse>;

  const { deviceGroup, performance, modelYear, chipset, boltPlatformName } = deviceInfo;
  const LabsSegments: Record<LabsSegmentIds, string> = {
    [LabsSegmentIds.DEVICE_GROUP]: deviceGroup ?? '',
    [LabsSegmentIds.DEVICE_PERFORMANCE]: performance ?? '',
    [LabsSegmentIds.OS_MODEL_YEAR]: `${boltPlatformName}-${modelYear}`,
    [LabsSegmentIds.DEVICE_CHIPSET]: chipset
  };

  const labsSDKOptions: ILabsSDKOptions = {
    projectId: CDEV_LABS_PROJECT_IDS[environment],
    boltHttp,
    requestOptions: {
      context: LabsSegments
    },
    localDecisions: {
      ...LABS_FLAGS_DEFAULTS
    }
  };

  const labs = await LabsSDK.create<ICDevFeatureFlagConfig>(labsSDKOptions);

  const decisionAccessedListener = debounce(
    (flagId: string, variantId: string, source: string, seedId?: string) => {
      iLabsDecisionAccessed.emit({ flagId, variantId, source: source as LabsDecisionAccessedSource, seedId });
    },
    2000
  );

  labs.subscribe(LabsSDKEvents.DecisionAccessed, (event) => {
    const { flagId, variantId, source, seedId } = event.payload;
    if (source.includes(LabsDecisionAccessedSource.EXPERIMENT)) {
      decisionAccessedListener(flagId, variantId, source, seedId);
    }
  });

  const getFlagConfig: ILabsService['getFlagConfig'] = (flag) => {
    const validator = LABS_FLAGS_VALIDATORS[flag];
    return labs.getLabsDecision(flag).getConfig(validator);
  };

  const getFeatureFlagResponse: ILabsService['getFeatureFlagResponse'] = ():
    | IFeatureFlagResponse
    | undefined => {
    function isValidFeatureFlagResponse(obj: Partial<IFeatureFlagResponse>): obj is IFeatureFlagResponse {
      const requiredProperties: string[] = Object.keys(obj);
      return requiredProperties.length > 0;
    }

    const partialFeatureFlagResponse = labsFlags;

    const featureFlagResponse =
      partialFeatureFlagResponse && isValidFeatureFlagResponse(partialFeatureFlagResponse)
        ? partialFeatureFlagResponse
        : undefined;

    return featureFlagResponse;
  };

  const isFlagEnabled: ILabsService['isFlagEnabled'] = (flag) => {
    return labs.getLabsDecision(flag).isEnabled();
  };

  const updateLabs = async (): Promise<void> => {
    try {
      labsFlags = await featureFlagsRequest({
        projectId: CDEV_LABS_PROJECT_IDS[environment],
        context: LabsSegments
      } as IFeatureFlagOptions);
    } catch (error) {
      iIdentityFeatureFlagsError.capture({
        err: generateErr(error, {
          classification: IdentityFeatureFlagsErrorClassification.INIT_FAILED,
          scope: IdentityFeatureFlagsErrorScope.RECOVERABLE
        })
      });
    }
  };

  return {
    getFlagConfig,
    getFeatureFlagResponse,
    isFlagEnabled,
    updateLabs
  };
}
