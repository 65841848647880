// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { DateFormatterOptions } from 'globalize';

/**
 * Defines the options that Globalize accepts for formatting dates.
 *
 * @public
 */
export interface IGlobalizeDateFormatInfo {
  /**
   * The Globalize date formatter options.
   *
   * For additional documentation, including an explanation of the "skeleton" property and examples of localization
   * into different regions, see https://github.com/globalizejs/globalize/blob/master/doc/api/date/date-formatter.md.
   *
   * For the full list of possible "skeleton" elements, in order, see
   *  http://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table.
   */
  options?: DateFormatterOptions;
}

/**
 * The standard date formats supported by this library. This is a union across the formats required by all projects
 * that use this library. If your project requires a format that is not yet defined, feel free to add it.
 *
 * @public
 */
export enum DateFormat {
  /** A particular time, in hours, e.g., "5 PM". */
  HOUR = 'HOUR',

  /** A particular time, in hours and minutes, e.g., "5:55 PM". */
  HOUR_MINUTE = 'HOUR_MINUTE',

  /** The named day of the week, e.g., "Sunday". */
  DAY_OF_WEEK = 'DAY_OF_WEEK',

  /**
   * The date in numeric format, with the full year (month/day/year in the US).
   * @example "11/1/2010" in the US.
   * @example "01/11/2010" in France.
   */
  DAY_MONTH_YEAR_SHORT_WITH_FULL_YEAR = 'DAY_MONTH_YEAR_SHORT_WITH_FULL_YEAR',

  /**
   * The date as an abbreviated month name, the day of the month, and a particular time, in hours and minutes, e.g., "5:55 PM".
   * @example "Dec 7, 5:55 PM" in the US.
   * @example "7 déc. 17:55" in France.
   *
   */
  DAY_MONTH_WITH_TIME = 'DAY_MONTH_WITH_TIME',

  /**
   * The date as an abbreviated month name, the day of the month, and a four-digit year.
   * @example "Dec 7, 2021" in the US.
   * @example "7 déc. 2021" in France.
   */
  DAY_MONTH_YEAR_MEDIUM = 'DAY_MONTH_YEAR_MEDIUM',

  /**
   * The date as a full month name, the day of the month, and a four-digit year.
   * @example "December 7, 2021" in the US.
   * @example "7 décembre. 2021" in France.
   */
  DAY_MONTH_YEAR_LONG = 'DAY_MONTH_YEAR_LONG',

  /**
   * The date as an abbreviated month name and the day of the month.
   * @example "Dec 7" in the US.
   * @example "7 déc." in France.
   */
  DAY_MONTH_MEDIUM = 'DAY_MONTH_MEDIUM',

  /**
   * The date as a full month name and the day of the month.
   * @example "December 7" in the US.
   * @example "7 décembre." in France.
   */
  DAY_MONTH_LONG = 'DAY_MONTH_LONG',

  /**
   * The date as an abbreviated month name, the day of the month, with full year and a particular time, in hours and minutes.
   * @example "Dec 7, 2021, 5:55 PM" in the US.
   * @example "7 déc. 2021. 17:55" in France.
   *
   */
  DAY_MONTH_YEAR_WITH_TIME = 'DAY_MONTH_YEAR_WITH_TIME',

  /**
   * The year for the given date.
   * @example "2023" in the US.
   * @example Something like "a.d. 2023" could be used by another culture, should something like that be the accepted way to render their years.
   */
  YEAR = 'YEAR'
}

/**
 * A list of `DateFormats` whose formatted results will contain words.
 *
 * To avoid mixed language in the UI, `HybridLocaleFormatter.formatAsDate` will use this list to determine which
 * formats contain words (e.g. month names, days of the week, "AM", "PM", etc.), and therefore should use the UI
 * language for formatting rather than the region.
 *
 * @internal
 */
export const _WORD_BASED_DATE_FORMATS: readonly DateFormat[] = [
  DateFormat.HOUR,
  DateFormat.HOUR_MINUTE,
  DateFormat.DAY_MONTH_MEDIUM,
  DateFormat.DAY_MONTH_LONG,
  DateFormat.DAY_MONTH_WITH_TIME,
  DateFormat.DAY_MONTH_YEAR_MEDIUM,
  DateFormat.DAY_MONTH_YEAR_LONG,
  DateFormat.DAY_OF_WEEK,
  DateFormat.DAY_MONTH_YEAR_WITH_TIME
] as const;

/**
 * A mapping from `DateFormats` enum values to the corresponding Globalize date format options objects.
 *
 * @public
 */
export const DATE_FORMATS_MAP: Map<DateFormat, IGlobalizeDateFormatInfo> = new Map<
  DateFormat,
  IGlobalizeDateFormatInfo
>([
  [DateFormat.HOUR, { options: { skeleton: 'j' } }],
  [DateFormat.HOUR_MINUTE, { options: { time: 'short' } }],
  [DateFormat.DAY_OF_WEEK, { options: { skeleton: 'EEEE' } }],
  [DateFormat.DAY_MONTH_YEAR_SHORT_WITH_FULL_YEAR, { options: { skeleton: 'yMd' } }],
  [DateFormat.DAY_MONTH_WITH_TIME, { options: { skeleton: 'MMMdjm' } }],
  [DateFormat.DAY_MONTH_YEAR_MEDIUM, { options: { date: 'medium' } }],
  [DateFormat.DAY_MONTH_YEAR_LONG, { options: { date: 'long' } }],
  [DateFormat.DAY_MONTH_MEDIUM, { options: { skeleton: 'MMMd' } }],
  [DateFormat.DAY_MONTH_LONG, { options: { skeleton: 'MMMMd' } }],
  [DateFormat.DAY_MONTH_YEAR_WITH_TIME, { options: { skeleton: 'yMMMdjm' } }],
  [DateFormat.YEAR, { options: { skeleton: 'yyyy' } }]
]);
