// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { type ISonicError, getToken } from '@wbd/bolt-dataservice';
import { isHttpError, type IHttpError } from '@wbd/bolt-http';

export async function retryOnInvalidToken<T>(
  fn: () => Promise<T>,
  retries: number = 1,
  error?: unknown
): Promise<T> {
  let isInvalidToken: boolean = false;

  if (isHttpError(error)) {
    const errors = (error as IHttpError<{ errors: ISonicError[] }>).response?.data?.errors;
    isInvalidToken = Array.isArray(errors) && errors.some((error) => error.code === 'invalid.token');

    if (retries < 0 || !isInvalidToken) {
      return Promise.reject(error);
    }
  } else if (error) {
    return Promise.reject(error);
  }

  try {
    if (isInvalidToken) {
      await getToken(true);
    }
    const result = await fn();
    return result;
  } catch (error) {
    return retryOnInvalidToken(fn, retries - 1, error);
  }
}
