// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { User } from '../../data-models';
import { TransformerLight } from '../../data-transformer';
import type { RequestConfigOverride } from '../../http-client';
import { DataTypes } from '../../json-api';
import { getUserRequest } from './getUserRequest';
import type { ITypeMap } from '../ITypeMap';

/**
 * Returns the user.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getUser = async (config?: RequestConfigOverride): Promise<User> => {
  const sonicUser = await getUserRequest(config);
  const store = new TransformerLight<DataTypes.User, ITypeMap>(sonicUser);
  const user = store.findAll(DataTypes.User)[0];
  user.meta = sonicUser.meta;

  return user;
};
