// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IStorage } from '@wbd/beam-dom-extensions';
import type { BoltHttpClient, IRequestRetryConfig } from '@wbd/bolt-http';

/**
 *
 * Configuration defined by the client to be used by the Instrumentation SDK to wire up data transmission and heartbeat events
 * @public
 */
export interface IClientConfiguration {
  /** Overrides whether ISDK will only emit events */
  emitOnly?: boolean;

  /** persistant storage for events */
  eventStorage?: IStorage;

  /** Overrides the number of milliseconds to wait until we send all telegraph logs. */
  flushInterval?: number;

  /** Overrides the event type matchers */
  forwarders?: { matchers: string[]; status: string }[];

  /** bolt http client responsible for making requests */
  httpClient?: BoltHttpClient;

  /** bolt http client owned retry policy. Recommended against setting this, as Transmission library has bespoke retry logic */
  httpClientRetryPolicy?: IRequestRetryConfig;

  /** When true attach the x-wbd-origin-group header to Telegraph requests with the value 'TEST'. */
  isTestMode?: boolean;

  /** Ovveride the maximum number of events to be sent in each batch request. */
  maxEventBatchSize?: number;

  /** The URI to be used when posting event data to telegraph */
  telegraphUri?: string;

  /** hex encoded 8 bit field controlling tracing flags */
  traceFlags?: string;
}

/**
 * Internal Configuration used by the Instrumentation SDK to wire up data transmission and heartbeat events
 *
 * Defined in https://wbdstreaming.atlassian.net/wiki/spaces/GCX/pages/107382232/LADR+010+Telemetry+Config+Schema
 * @public
 */
export class Configuration implements IClientConfiguration {
  /** default configuration values */
  public static readonly defaults: Required<Omit<Configuration, 'httpClient' | 'eventStorage'>> = {
    emitOnly: false,
    flushInterval: 2000,
    flushThrottleFactor: 2,
    forwarders: [
      {
        matchers: ['isdk:*'],
        status: 'isdk_only'
      }
    ],
    forwarderMatchers: ['isdk:*'],
    heartbeatInterval: 30000,
    httpClientRetryPolicy: {
      retries: 0
    },
    isTestMode: false,
    minDisableInterval: 30,
    maxEventBatchSize: 10,
    maxRetries: 5,
    telegraphUri: '/events',
    traceFlags: '00'
  };

  /** persistant storage for events */
  public eventStorage: IStorage | undefined;

  /** whether the ISDK should only emit events, does not setup monitors or managers */
  public emitOnly: boolean = Configuration.defaults.emitOnly;

  /** The number of milliseconds to wait until we send all telegraph logs. */
  public flushInterval: number = Configuration.defaults.flushInterval;

  /** The factor that will be multiplied to the flushInterval to throttle flushing in the event of a transmission error */
  public flushThrottleFactor: number = Configuration.defaults.flushThrottleFactor;

  /** feature flag structure for event type matchers */
  public forwarders: IClientConfiguration['forwarders'] = Configuration.defaults.forwarders;

  /** Collection of event type matchers that start with isdk */
  public forwarderMatchers?: string[] = Configuration.defaults.forwarderMatchers;

  /** The number of milliseconds between each heartbeat event. */
  public heartbeatInterval: number = Configuration.defaults.heartbeatInterval;

  /** The minimum number of minutes to disable the transmission library after failures exceed maxRetries. */
  public minDisableInterval: number = Configuration.defaults.minDisableInterval;

  /** The URI to be used when posting event data to telegraph */
  public httpClient?: BoltHttpClient;

  /** The URI to be used when posting event data to telegraph */
  public telegraphUri: string = Configuration.defaults.telegraphUri;

  /** bolt http client owned retry policy. Recommended against setting this, as Transmission library has bespoke retry logic */
  public httpClientRetryPolicy: IRequestRetryConfig = Configuration.defaults.httpClientRetryPolicy;

  /** The maximum number of events to be sent in each batch request. */
  public maxEventBatchSize: number = Configuration.defaults.maxEventBatchSize;

  /** The maximum number of retries to attempt after a transmission failure. */
  public maxRetries: number = Configuration.defaults.maxRetries;

  /** When true attach the x-wbd-origin-group header to Telegraph requests with the value 'TEST'. */
  public isTestMode: boolean = Configuration.defaults.isTestMode;

  /** A hex encoded 8 bit field controlling tracing flags */
  public traceFlags: string = Configuration.defaults.traceFlags;

  /**
   * Creates a new Configuration object
   * @param props - Configuration properties to be used by the Instrumentation SDK
   */
  public constructor(props: Partial<Configuration> = {}) {
    Object.assign(this, Configuration.defaults, props);

    // correct any forwarders that have been passed and an empty array
    if (props.forwarders && props.forwarders.length === 0) {
      this.forwarders = Configuration.defaults.forwarders;
    }

    const disabledForwarders = this.forwarders!.filter((forwarder) => forwarder.status === 'off')
      .map((forwarder) => `!${forwarder.matchers}`)
      .flat();

    const enabledForwarders = this.forwarders!.filter(
      (forwarder) => forwarder.status === 'isdk_only' || forwarder.status === 'dual_emit'
    )
      .map((forwarder) => forwarder.matchers)
      .flat();

    this.forwarderMatchers = enabledForwarders.concat(disabledForwarders);
  }
}
