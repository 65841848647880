// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { LanguageCode } from '@wbd/localization-core';
import { AppName } from '../app-name';
import {
  IBorderTokens,
  IColorTokens,
  IElevationTokens,
  IGradientTokens,
  IMotionTokens,
  ITextTokens
} from '../generated';
import type { GlobalizeStatic } from '@wbd/localization';
import type { TFontCategory } from '../fonts';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const __brand: unique symbol;

/**
 * Branded string type for SVG assets
 * @public
 */
export type IconAsset = string & { readonly [__brand]: 'SVG' };

/**
 * @public
 * Background variant
 */
export enum BackgroundVariant {
  /**
   * The default background will be present on the main browse screens (home, series, movies, genre pages, etc)
   */
  Default = 'default',
  /**
   * The basic background will be used for non-browse screens like Who is Watching, Settings, etc)
   */
  Basic = 'basic',
  /**
   * The darker background will be used for more info overviews
   */
  Darker = 'darker',
  /**
   * The transparent background will be used for the Toasts
   */
  Transparent = 'transparent'
}

/**
 * @public
 * Image-based background
 */
export interface IImageBackground {
  /**
   * The source of the background image
   */
  source: string;
  /**
   * Test Id to apply to the background
   */
  testId: string;
  /**
   * The type of background (color, image)
   */
  type: 'image';
}

/**
 * @public
 * Color-based background
 */
export interface IColorBackground {
  /**
   * The color of the background
   */
  color: number;
  /**
   * Test Id to apply to the background
   */
  testId: string;
  /**
   * The type of background (color, image)
   */
  type: 'color';
}

/**
 * @public
 * Background from image or color
 */
export type IBackground = IImageBackground | IColorBackground;

/**
 * The resource definition
 *
 * @public
 */
export interface IFuseCTVResources {
  /**
   * The application name
   */
  appName: AppName;

  /**
   * Holds information about the splash screen video
   */
  splashVideo?: {
    source: string;
  };

  /**
   * Holds information about the background to be used for each BackgroundVariant
   */
  backgrounds: Record<BackgroundVariant, IBackground>;

  /**
   * Interface that holds namespaced design tokens
   */
  design: {
    border: IBorderTokens;
    color: IColorTokens;
    elevation: IElevationTokens;
    gradient: IGradientTokens;
    motion: IMotionTokens;
    text: Record<TFontCategory, ITextTokens>;
  };

  /**
   * Hold information about the icons
   */
  icons: {
    add: IconAsset;
    addSolid: IconAsset;
    back: IconAsset;
    check: IconAsset;
    checkSolid: IconAsset;
    chevronDown: IconAsset;
    chevronLeft: IconAsset;
    chevronRight: IconAsset;
    chevronUp: IconAsset;
    delete: IconAsset;
    edit: IconAsset;
    error: IconAsset;
    eyeHide: IconAsset;
    eyeShow: IconAsset;
    forward2: IconAsset;
    home: IconAsset;
    info: IconAsset;
    key: IconAsset;
    keyBackspace: IconAsset;
    keyHide: IconAsset;
    keyLanguage: IconAsset;
    keyNext: IconAsset;
    keyShift: IconAsset;
    keyShiftSolid: IconAsset;
    keySpace: IconAsset;
    keySpaceTriple: IconAsset;
    language: IconAsset;
    lockClosed: IconAsset;
    menu: IconAsset;
    moreEpisodes: IconAsset;
    pause: IconAsset;
    play: IconAsset;
    pressHold: IconAsset;
    profile: IconAsset;
    remove: IconAsset;
    restart: IconAsset;
    search: IconAsset;
    series: IconAsset;
    settings: IconAsset;
    trailer: IconAsset;
  };

  /**
   * Function to dynamically import compiled-messages.js based on a given languageCode
   */
  loadLanguagePack: (languageCode: string) => Promise<GlobalizeStatic>;

  /**
   * Retrieves a list of supported language codes based on the specified app name.
   * @public
   */
  getClientEnabledLanguageCodes: () => LanguageCode[];
}
