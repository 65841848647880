// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { LanguageCode } from '@wbd/localization-core';

/**
 * LanguageCodes for the available language packs.
 *
 * @public
 */
export const SUPPORTED_LANGUAGES: readonly LanguageCode[] = [
  'en-US' as LanguageCode,
  'pseudo-LOC' as LanguageCode,
  'pseudo-LONG' as LanguageCode,
  'bg-BG' as LanguageCode,
  'cs-CZ' as LanguageCode,
  'da-DK' as LanguageCode,
  'es-419' as LanguageCode,
  'es-ES' as LanguageCode,
  'fi-FI' as LanguageCode,
  'fil-PH' as LanguageCode,
  'fr-FR' as LanguageCode,
  'hr-HR' as LanguageCode,
  'hu-HU' as LanguageCode,
  'id-ID' as LanguageCode,
  'mk-MK' as LanguageCode,
  'ms-MY' as LanguageCode,
  'nb-NO' as LanguageCode,
  'nl-NL' as LanguageCode,
  'pl-PL' as LanguageCode,
  'pt-BR' as LanguageCode,
  'pt-PT' as LanguageCode,
  'ro-RO' as LanguageCode,
  'sk-SK' as LanguageCode,
  'sl-SI' as LanguageCode,
  'sr-Latn-RS' as LanguageCode,
  'sv-SE' as LanguageCode,
  'th-TH' as LanguageCode,
  'zh-Hans-SG' as LanguageCode,
  'zh-Hant-HK' as LanguageCode,
  'zh-Hant-TW' as LanguageCode
] as const;
