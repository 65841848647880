// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { AppName, type IFuseCTVResources } from '@wbd/fuse-ctv-resources-common';
import { splashVideo } from './packlets/assets';

// Static imports of supported languages
import { SUPPORTED_LANGUAGES } from './codegen/localization/supportedLanguages';
import { loadLanguagePack } from './codegen/localization/loadLanguagePack';
import { data as maxResourceData } from '@wbd/fuse-ctv-resources-max';

/**
 * Resources for Max
 *
 * @public
 */
export const data: IFuseCTVResources = {
  appName: AppName.HBOMAX,
  splashVideo: {
    source: splashVideo
  },
  backgrounds: maxResourceData.backgrounds,
  icons: maxResourceData.icons,
  design: maxResourceData.design,
  loadLanguagePack,
  getClientEnabledLanguageCodes: () => [...SUPPORTED_LANGUAGES]
};
