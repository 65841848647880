// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * @public
 */
export const CMS_DECORATORS: readonly string[] = ['viewingHistory', 'isFavorite', 'contentAction', 'badges'];

/**
 * @public
 */
export const CONTENT_DECORATORS: readonly string[] = ['viewingHistory', 'isFavorite', 'contentAction'];
