// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { AuthToken, IRequestHeaders, IResponse } from '@wbd/bolt-http';

import type { RequestConfigOverride } from '../http-client';
import { HttpClient } from '../http-client';
import type { ITokenAuthenticationData } from './interfaces/ITokenAuthenticationData';

/**
 * Returns the token.
 * @param isAnonymous - Whether to apply Authorization token in request.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getToken = async (isAnonymous = false, config?: RequestConfigOverride): Promise<AuthToken> => {
  const http = HttpClient.instance;
  const { realm } = http.sessionConfig;

  const url = `/token?realm=${realm}`;
  const headers: IRequestHeaders = {};

  const response = await http.get<IResponse<ITokenAuthenticationData>>(url, {
    headers,
    isAnonymous,
    signal: config?.signal,
    bypassBootstrapRefreshLock: true
  });
  const resource = response.data?.data;
  const token = resource?.attributes?.token;
  await http.sessionConfig.authTokenProvider?.clearTokenAsync();
  await http.sessionConfig.authTokenProvider?.setTokenAsync(token);

  return token;
};
