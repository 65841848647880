// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IBootstrapResponseJson, IMigrationCredentials, ISonicError } from '@wbd/bolt-dataservice';
import {
  AuthMigrationTokenProvider,
  ConsentExperienceAlias,
  configureAnonymousConsentInterceptors,
  getBootstrap,
  getToken,
  hasInvalidTokenError,
  registerHttpClient
} from '@wbd/bolt-dataservice';
import { BoltEnvironment, BoltHttpClient } from '@wbd/bolt-http';
import { getBoltSessionConfig } from './constants';

import type { AuthToken, IDeviceInfo, IHttpError, IResponse, RefreshSignal } from '@wbd/bolt-http';
import type { Unsubscribe } from '@wbd/light-events';
import type { IInitialContext } from '../application';
import {
  IdentitySignInErrorClassification,
  IdentitySignInErrorAuthMethod,
  IdentitySignInErrorScope,
  InstrumentationSDK,
  PlatformRuntimeErrorScope,
  generateErr,
  iIdentitySignInError,
  iPlatformRuntimeError,
  type IIdentitySignInErrorErr,
  type IPlatformRuntimeErrorErr
} from '../instrumentation';
import Resources from '@wbd/fuse-ctv-resources-common';

type INetworkingErrors = IHttpError<{ errors: ISonicError[] }>;

interface IResource {
  data?: {
    attributes?: {
      token?: AuthToken;
    };
  };
}

/**
 * storage key for the auth token
 */
export const BEAM_AUTH_TOKEN_KEY: string = 'beamAuthToken';

/**
 * function that bootstraps the bolt-http and bolt-dataservice libraries
 * @param config - initiation configuration
 * @returns
 */
export const bootstrapBolt = async (
  context: IInitialContext,
  handleRefresh: (context: IInitialContext) => (signals: RefreshSignal[]) => void
): Promise<{ httpClient: BoltHttpClient; onRefreshSubscription: Unsubscribe }> => {
  const { device, storage, account } = context.platformAdapter;
  const { deviceId, manufacturer, model, operatingSystem, operatingSystemVersion, boltPlatformName } =
    context.deviceBootInfo;

  const deviceInfo: IDeviceInfo = { manufacturer, model, operatingSystem, operatingSystemVersion };

  // create persistent storage adapter
  const tokenKeyPrefix = context.boltEnvironment !== BoltEnvironment.PRD ? `${context.boltEnvironment}-` : '';
  const storageKey = `${tokenKeyPrefix}${BEAM_AUTH_TOKEN_KEY}`;

  // TODO: to be removed with GCX-82837
  const HBOGoProvider = async (): Promise<IMigrationCredentials> => {
    const token = await device.getLegacyTokenForRetainAuth();
    return {
      legacyClient: 'HBOGo',
      legacyData: token,
      legacyDataType: 'token'
    };
  };

  const credentialsProvider = account.getAuthMigrationDetailsAsync ?? HBOGoProvider;
  const authTokenProvider = new AuthMigrationTokenProvider(credentialsProvider, storage, storageKey);

  // listen to any errors from token provider
  authTokenProvider.onError((error) => {
    const errors = (error as INetworkingErrors).response?.data?.errors;

    // https://wbdstreaming.atlassian.net/wiki/spaces/GCX/pages/211719640/ESD+017+Identity+Sign-in+Error#Device-fails-to-auto-login-with-legacy-credentials
    const isLegacySwapError =
      Array.isArray(errors) &&
      errors.some((error) => error.status === '401' && error.code === 'unauthorized');

    if (isLegacySwapError) {
      iIdentitySignInError.capture({
        err: generateErr<IIdentitySignInErrorErr>(error, {
          classification: IdentitySignInErrorClassification.LOGIN_FAILED,
          scope: IdentitySignInErrorScope.RECOVERABLE
        }),
        signin: {
          authMethod: IdentitySignInErrorAuthMethod.RETAIN_AUTH
        }
      });
    }
  });

  authTokenProvider.onUserMigrated(() => {
    context.targetConsentExperience = ConsentExperienceAlias.MIGRATION_RETAIN;
  });

  const deviceLocale = await device.getDeviceLocaleAsync();

  // create http instance & register it
  const httpClient = BoltHttpClient.create(
    {
      ...getBoltSessionConfig(context.clientId, Resources.appName, boltPlatformName, context.appVersion),
      deviceId,
      deviceInfo,
      asyncRails: true,
      authTokenProvider,
      environment: context.boltEnvironment,
      devicePreferredLanguages: [deviceLocale]
    },
    {},
    storage
  );

  const bootstrapProvider = async (response: IResponse): Promise<IBootstrapResponseJson> => {
    if (authTokenProvider) {
      // if the refresh signal comes back on the response of an endpoint that returns a updated token
      // in the payload, we need to ensure to store it first before we call bootstrap (ex. login / logout)
      const resource = (response as IResponse<IResource>).data?.data;
      const token = resource?.attributes?.token;
      if (token) {
        await authTokenProvider.setTokenAsync(token);
      }
    }

    try {
      return await getBootstrap();
    } catch (error) {
      if (hasInvalidTokenError(error)) {
        await getToken(true);
      }

      throw error;
    }
  };

  httpClient.bootstrapConfig?.setBootstrapProvider(bootstrapProvider);

  const onRefreshSubscription = httpClient.refreshManager.onRefresh(handleRefresh(context));

  // configure consent interceptors
  configureAnonymousConsentInterceptors(httpClient.interceptors, context.boltEnvironment, storage);

  // register bolt-http with bolt-dataservice
  registerHttpClient(httpClient);

  // wire up bolt-http before token request
  InstrumentationSDK.updateConfiguration({ httpClient });

  // Force getting an anonymous or migrated token
  await authTokenProvider.getTokenAsync();

  // Wire up exception handler for session-state and refresh monitor.
  const sessionExceptionHandler = (error: unknown): void => {
    iPlatformRuntimeError.capture({
      err: generateErr<IPlatformRuntimeErrorErr>(error, {
        scope: PlatformRuntimeErrorScope.RECOVERABLE
      })
    });
  };
  httpClient.sessionState.onError(sessionExceptionHandler);
  httpClient.refreshManager.onError(sessionExceptionHandler);

  // return bolt sonic configuration
  return { httpClient, onRefreshSubscription };
};
