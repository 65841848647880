// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IStorage } from '@wbd/beam-dom-extensions';
import { BrowserStorage, StorageScope } from '@wbd/beam-dom-extensions';
/**
 * SiteTokenManager implementation
 * @public
 */
export class SiteTokenManager {
  private static _siteTokenKeyName: string = 'HOTEL_SITE_TOKEN';
  private _sessionStorage: IStorage;

  public constructor() {
    this._sessionStorage = new BrowserStorage(StorageScope.SESSION);
  }

  /**
   * Attempts to return the stored site token.
   * If site token is undefined, tries to store value returned by _getSiteTokenFromQueryParam() as siteToken
   */
  public getToken(): string | undefined {
    const storageToken = this._sessionStorage.readSync(SiteTokenManager._siteTokenKeyName);
    if (storageToken) {
      return storageToken;
    } else {
      const abstractSiteToken = this.getSiteTokenFromQueryParam();
      if (abstractSiteToken) {
        this._sessionStorage.writeSync(SiteTokenManager._siteTokenKeyName, abstractSiteToken);
        return abstractSiteToken;
      }
    }
    return undefined;
  }

  /**
   * Adds the site-token key-value in the session storage.
   */
  public setToken(token: string): void {
    this._sessionStorage.writeSync(SiteTokenManager._siteTokenKeyName, token);
  }

  /**
   * Removes the site-token key from the session storage.
   */
  public clearSync(): void {
    this._sessionStorage.removeSync(SiteTokenManager._siteTokenKeyName);
  }

  /**
   * Returns the value of the `token` query param, if that query param exists when this is called
   *
   * @returns `token` query param, or `undefined`
   */
  public getSiteTokenFromQueryParam(): string | undefined {
    // This assumes the ?token query parameter is set when this is called
    const siteToken = new URLSearchParams(window.location.search).get('token');
    return siteToken ?? undefined;
  }
}
