// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IHttpModule } from '../http-internal';
import { createHttpModule } from './createAxiosHttpModule';
import { isHttpError } from './isAxiosError';
import { isHttpTimeoutError } from './isAxiosTimeoutError';

const HttpModuleAxios: IHttpModule = {
  createHttpModule,
  isHttpError,
  isHttpTimeoutError
};
export default HttpModuleAxios;
