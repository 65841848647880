// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { debounce } from './debounce';

/**
 * Factory that creates an aggregate debounce wrapper function that delays the given function until
 * after the stated delay time (in milliseconds) has passed since the last time this debounced function was called
 *
 * This function aggregates and merges the result of each callback before passing them to the final invoked callback function.
 * 
 * A custom merge function can be provided, by default uses a mergeLastUnique algorithm. Shallow merging both arrays and object, 
 * the last update will prevail for values that can't be merged ex. primitives.

 * Usage
 * -----
 *
 * `function callback(value:string):void {`
 * `  console.log(value);`
 * `}`
 * `const myDebounce = debounce(callback, 500);`
 *
 * to initiate the debounce it needs to be executed
 * `const myDebounceCancel = myDebounce(yourArgsForTheCallback);`
 *
 * if you call `myDebounceCancel();` the timer will be cancelled and the callback will NOT be executed
 * other wise the `callback` is executed after 500ms and `console` will log out yourArgsForTheCallback
 *
 * @param callback - function to be executed
 * @param delay - in milliseconds
 * @param mergeFn - optional custom merge function, by default uses 'mergeLastArguments'
 * @returns - debounced function - which, in turn, returns a function that will cancel the debounce timer
 * @public
 */
export function aggregateDebounce<T extends (...args: never[]) => unknown, V = Parameters<T>[number]>(
  callback: T,
  delay: number,
  mergeFn: (a: V, b: V) => V
): (...args: Parameters<T>) => () => void {
  let lastArgs: V[] = [];

  // Wrap debounce callback function to reset lastArgs when debounce callback triggers.
  const debounceFn = debounce((...args: V[]) => {
    lastArgs = [];
    callback(...(args as Parameters<T>));
  }, delay);

  // Returns a wrapped factory to merge the trigger arguments.
  return (...args: Parameters<T>) => {
    lastArgs = args.map((arg, index) => mergeFn(lastArgs[index], arg));
    const cancelFn = debounceFn(...(lastArgs as Parameters<T>));

    // Wrap cancel function to reset lastArgs.
    return () => {
      lastArgs = [];
      return cancelFn();
    };
  };
}
