// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ILogger } from '@wbd/beam-ctv-logger';
import type { IPlatformAdapter, IProfileInfo } from '@wbd/beam-ctv-platform-adapters';
import type { ClientId, Platform } from '@wbd/bolt-http';
import type { IInitialContext } from '../application';
import {
  UiLaunchErrorClassification,
  UiLaunchErrorLaunchState,
  UiLaunchErrorScope,
  generateErr,
  iUiLaunchError,
  type IUiLaunchErrorErr
} from '../instrumentation';
import { bootstrapGlobalErrors } from './bootstrapGlobalErrors';
import { bootstrapInstrumentation } from './bootstrapInstrumentation';
import { loadUI } from './loadUI';
import { loadCanvasErrorFallback } from './loadUIFallback';
import { setup } from './setup';
import type { IFuseCTVResources } from '@wbd/fuse-ctv-resources-common';
import { loadResources } from '@wbd/fuse-ctv-resources-common';
import { resolvePlatformValues } from './resolvePlatformValues';
import type { ILocalizationService } from '@wbd/beam-ctv-localization';

/**
 * Application boot config options
 */
export interface IBootstrapAppOptions {
  appVersion: string;
  clientId: ClientId;
  commercePartnerId?: string | ((platform: Platform) => string);
  logger?: ILogger;
  omdComponentName?: string;
  onUserActivity?: (translation: ILocalizationService, profileInfo: IProfileInfo | undefined) => void;
  platform: Platform | (() => Promise<Platform>);
  platformAdapter: IPlatformAdapter;
  platformDisplayName?: string;
  resources?: IFuseCTVResources;
}

/**
 * Helper function to validate if the setup context completed successfully
 * @param context - context as returned from bootstrap 'setup'
 * @returns
 */
export function isValidContext(context: Partial<IInitialContext>): context is IInitialContext {
  return !context.startupError && context.globalError?.totalCount() === 0;
}

/**
 * Bootstrap the BEAM application async
 * @param config - bootstrap configuration
 */
export async function bootstrapAppAsync(options: IBootstrapAppOptions): Promise<void> {
  const { resources, platformAdapter } = options;

  if (resources) {
    loadResources(resources);
  }

  // capture global uncaught exceptions
  const globalError = bootstrapGlobalErrors();

  // initializes instrumentation sdk
  bootstrapInstrumentation(platformAdapter);

  const { platform, commercePartnerId } = await resolvePlatformValues(options);
  const platformDisplayName = options.platformDisplayName ?? platform;

  // run init sequence in parallel with loading the main application code
  const [context, { start, startWithError }] = await Promise.all([
    setup({
      ...options,
      platform,
      commercePartnerId,
      platformDisplayName,
      globalError,
      /**
       * This value comes from the omd.json file in each project
       * and is replaced using webpack's DefinePlugin.
       *
       * When omd.json is not present, we use the provided component name instead for dynamic cases
       * such as fuse-ctv apps
       */
      omdComponentName:
        typeof __OMD_COMPONENT_NAME__ === 'string'
          ? __OMD_COMPONENT_NAME__
          : options.omdComponentName ?? 'unknown'
    }),
    loadUI()
  ]);

  // ensure fonts finish loading
  if (context.fontConfiguration) {
    await context.fontConfiguration.pendingFonts;
    context.fontConfiguration.pendingFonts = undefined;
  }

  try {
    // we can start the application
    if (isValidContext(context)) {
      return await start(context);
    } else {
      // boot sequence failed but we can still try presenting a localized message
      return await startWithError(context);
    }
  } catch (error) {
    iUiLaunchError.capture({
      err: generateErr<IUiLaunchErrorErr>(error, {
        scope: UiLaunchErrorScope.USER_FACING,
        classification: UiLaunchErrorClassification.UNKNOWN
      }),
      launchState: UiLaunchErrorLaunchState.INITIALIZING
    });

    if (!context.startupError) {
      context.startupError = error;
    }
    // failed to start the app
    // pass 'context' with globalizer so we have localized error messaging
    loadCanvasErrorFallback(context);
  }
}
