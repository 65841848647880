// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IFuseCTVResources } from './IFuseCTVResources';

/**
 * This allows a nicer import in consumer projects
 * e.g. `import resources from '@wbd/fuse-ctv-resources-common'`
 *
 * @public
 */
export const Resources: IFuseCTVResources = {} as IFuseCTVResources;
