// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * String tokens that represent a request by the service that the client refresh
 * a particular segment its local state.
 *
 * @public
 */
export enum RefreshSignal {
  /**
   * User localization has changed (ex. in case of profile switch)
   *
   * The client should take the below action(s):
   * 1. refresh the page the client is currently on; update both content calls and localized UX
   */
  UX = 'ux',
  /**
   * Bootstrap payload has changed (ex. in case of roaming)
   *
   * The client should refresh bootstrap routing payloads by making a new request to
   * the bootstrap endpoint.  This will happen automatically for clients that participate
   * in managed bootstrap configuration. To enable managed bootstrap configuration,
   * clients should register a `BootstrapProvider` with the `BoltHttpClient`.
   *
   * `httpClient.bootstrapConfig.setBootstrapProvider( someFunctionHere );`
   *
   */
  BOOTSTRAP = 'bootstrap',
  /**
   * User consents has changed (ex. in case of refresh consents)
   *
   * The client should get consent experience according to the use case check LADR-15
   *
   * @deprecated migrate to the `reconsent` state property as signalled on the consents payload in `IConsentsPayload`
   */
  CONSENTS = 'consents'
}

/**
 * The refresh payload is send over the x-wbd-refresh header
 * by headwaiter to signal client refresh actions
 */
export type RefreshPayload = `${RefreshSignal}` | `${RefreshSignal},${RefreshSignal}`;

/**
 * helper to determine if string is of type RefreshSignal
 * @param type - maybe of type RefreshSignal
 * @returns
 */
export function isRefreshSignal(type: string): type is RefreshSignal {
  return Object.values(RefreshSignal).includes(type as RefreshSignal);
}

/**
 * Helper function to parse a x-wbd-refresh header value.
 * @param headerContents - x-wbd-refresh header value
 * @returns array of RefreshSignal
 */
export function parseRefreshHeader(headerContents: RefreshPayload | string): RefreshSignal[] {
  const signalList = (headerContents ?? '').split(/,\s*/).filter(isRefreshSignal);
  return signalList;
}
