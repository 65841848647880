// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IDimensions, type LogLevel } from '@wbd/player-sdk-core';
import { IEngineBufferConfig } from './IEngineBufferConfig';
import { IEngineGapSkipConfig } from './IEngineGapSkipConfig';
import { IEngineNetworkRequestRetryConfig } from './IEngineNetworkRequestRetryConfig';
import { IAbrOptions } from '@hbo/gluon/engine.common/abr/IAbrOptions';
import { IMediaKeyOptions } from '@hbo/gluon/pal.common/media/IMediaKeyOptions';
import {
  MultiPeriodWorkaroundLevel,
  StallSkippingWorkaroundLevel
} from '@hbo/gluon/pal.common/platform/IPlatformWorkaround';

/**
 * Type exports needed for prefixedMediaKeySupport config option
 */
export { VideoCodecGroup } from '@hbo/gluon/pal.common/media/VideoCodecGroup';
export { AudioCodecGroup } from '@hbo/gluon/pal.common/media/AudioCodecGroup';
export { EncryptionScheme } from '@hbo/gluon/pal.common/encryption/EncryptionScheme';
export { KeySystem } from '@hbo/gluon/pal.common/media/KeySystem';
export { PersistentState } from '@hbo/gluon/pal.common/encryption/PersistentState';
export { PlatformCapabilities } from '@hbo/gluon/pal.browser/platform/PlatformCapabilities';

/**
 * Enum describing PlayReady security levels
 * @public
 */
export enum DRMPlayReadyLevel {
  UNKNOWN = 'unknown',
  SL150 = 'sl150',
  SL2000 = 'sl2000',
  SL3000 = 'sl3000'
}
/**
 * Enum describing Widevine security levels
 * @public
 */
export enum DRMWidevineLevel {
  L1 = 'l1',
  L2 = 'l2',
  L3 = 'l3'
}

/**
 * @public
 */
export interface IGluonMediaKeyOptions extends IMediaKeyOptions {}

/**
 * @public
 */
export { MultiPeriodWorkaroundLevel } from '@hbo/gluon/pal.common/platform/IPlatformWorkaround';

/**
 * @public
 */
export interface IAudioCodecsOverride {
  aac: boolean;
  ac3: boolean;
  eac3: boolean;
  atmos: boolean;
}

/**
 * @public
 */
export interface IVideoCodecsOverride {
  avc: boolean;
  dvh: boolean;
  hevcSdr: boolean;
  hevcHdr: boolean;
}

/**
 * @public
 */
export interface IResolutionOverride {
  /**
   * Set the maximum allowed resolution. If not provided, the video will be capped by the video element size and
   * fallbacks to the smallest stream if video element size smaller than available streams
   */
  maxResolution?: IDimensions;
  /**
   * Set the minimum allowed resolution and fallbacks to the closest stream if no stream with specified min resolution
   * is not available
   */
  minResolution?: IDimensions;
  /**
   * Throw an exception if we filtered everything out by maxResolution/minResolution configurations. False by default
   */
  failWhenOutOfBounds?: boolean;
}

/**
 * @public
 */
export enum ProjectedHdcpLevel {
  HDCP_LEVEL_UNKNOWN,
  HDCP_NONE,
  HDCP_V1,
  HDCP_V2,
  HDCP_V2_1,
  HDCP_V2_2,
  HDCP_V2_3,
  HDCP_NO_DIGITAL_OUTPUT
}

/**
 * Predefined set of supported CDMs by platform. CapabilitiesProvider will used this predefined set
 * instead of requesting MKSA (window.navigator.requestMediaKeySystemAccess) for probing purposes.
 * @public
 */
export interface ICDMOverride {
  widevine?: {
    level: DRMWidevineLevel;
    version: string;
  };
  playready?: {
    level: DRMPlayReadyLevel;
    version: string;
  };
  fairplay?: {
    version: string;
  };
}

/**
 * It represents multiple video ratios
 * @public
 */
export enum VideoRatio {
  FOUR_THREE = '4:3',
  SIXTEEN_NINE = '16:9',
  TWENTY_ONE_NINE = '21:9'
}

/**
 * Interface for setting css for video width, height and margin
 * @public
 */
export interface IVideoScreenSize {
  /**
   * Video Height
   */
  videoHeight: number;
  /**
   * Video Width
   */
  videoWidth: number;
  /**
   * Margin
   */
  margin: string;
}

/**
 * Interface for calculated videoRatio aspectRatio and
 * found matching video
 * @public
 */
export interface IVideoRatioDetails {
  videoRatio: number;
  aspectRatio: VideoRatio;
}

/**
 * Interface for Gluon Playback Engine workarounds
 * @public
 */
export interface IWorkaroundOptions {
  /**
   * Reduces the frequency of timeupdates
   */
  timeUpdateThrottling?: boolean;
}

/**
 * Configuration options for the GluonPlaybackEngine.
 *
 * @public
 */
export interface IGluonConfig {
  /**
   * Supported audio codecs for the player
   */
  supportedAudioCodecs?: IAudioCodecsOverride;
  /**
   * Supported video codecs for the player
   */
  supportedVideoCodecs?: IVideoCodecsOverride;
  /**
   * Supported CDMs for the player
   */
  supportedCDMs?: ICDMOverride;
  /**
   * Defines video resolution config
   */
  resolutionOverrides?: IResolutionOverride;
  /**
   * HDCP Level
   */
  hdcpConfig?: ProjectedHdcpLevel;
  /**
   * Defines config for buffer options for playback
   */
  bufferOptions?: IEngineBufferConfig;
  /**
   * Defines config for gap skipping
   */
  gapSkip?: IEngineGapSkipConfig;
  /**
   * Defines download retry timeouts for segments and manifest
   */
  networkRequestRetry?: IEngineNetworkRequestRetryConfig;
  /**
   * License Request timeout for the player license requests
   */
  licenseRequestTimeout?: number;
  /**
   * This factor will be taken into consideration for initialBandwidth
   * For example if previous bandwidth was 5MB and initialBandwidthFactor is 0.5
   * the next initial bandwidth will be 2.5MB. This param should help
   * improve VST. Maximum value is 1 minimum is 0 meaning ignore initialBandwidth
   */
  initialBandwidthFactor?: number;
  /**
   * Indicates single key is required for playback
   */
  requireSingleKey?: boolean;
  /**
   * Indicates if we should use prefixed EME
   */
  usePrefixedEme?: boolean;
  /**
   * Enables clients to pass in supported media key options. This should be send in combination
   * with usePrefixedEme above. Requires and array with options for:
   * - audio
   * - video
   * - keySystem
   * - encryptionScheme
   * - persistentState
   */
  prefixedMediaKeySupport?: ReadonlyArray<IGluonMediaKeyOptions>;
  /**
   * Prevents seeking to 0 for specific devices that are unable to do so.
   */
  dontSeekToZero?: boolean;
  /**
   * Configures the stall skipping level.
   */
  stallSkipping?: StallSkippingWorkaroundLevel;
  /**
   * Configures track change seeking.
   */
  seekOnTrackChange?: boolean;
  /**
   * Multi period support level to configure what is reused between periods.
   */
  multiPeriod?: MultiPeriodWorkaroundLevel;
  /**
   * This workaround adds timeline offset option
   * that can adjust DOM timeline values into/out of PAL
   * where player translate times into and out of,
   * that will shift the media decode times back to 0
   * to allow for the largest possible range of playback times on Sagemcom devices
   */
  needsTimelineOffset?: boolean;
  /**
   * Configuration for decode time rewrite limit.
   */
  decodeTimeRewriteLimit?: number;
  /**
   * Workaround to inject pssh data into init segments.
   */
  injectPsshIntoInitSegments?: boolean;
  /**
   * Workaround to string dolby vision box from the variants
   * as ziggo has issues playing and navigating between promo and main content
   */
  stripDolbyVisionBox?: boolean;
  /**
   * Configuration to force media keys to be attached even for unencrypted content.
   */
  mediaKeysAttachedForUnencrypted?: boolean;
  /**
   * Abr options that client can pass to tweak playback
   * experience and have better playback quality
   */
  abrOptions?: IAbrOptions;
  /**
   * RewriteSingleKeyPlayReadyInitData option that client can
   * send so single-key play ready init data is rewritten aka LA_URL
   * and other items are also set. This workaround was initially introduced
   * for fixing KPN playback
   */
  rewriteSingleKeyPlayReadyInitData?: boolean;
  /**
   * Override for hasVerifiedMediaPath
   * used for Widevine DRM
   */
  hasVerifiedMediaPathOverride?: boolean;
  /**
   * This flag is used to enable video ratio adjustments inside GluonPlaybackEngine.
   * Reason for this is that some devices are stretching the screen because of device issue
   * and we need to calculate and set proper video element resolution.
   * Should be used on platforms that have problems with video ratio
   */
  needsVideoRatioAdjustments?: boolean;
  /**
   * Logging level. Defaults to none.
   */
  logLevel?: LogLevel;
  /**
   * Workaround options that client can provide
   * so that player can apply workarounds for specific devices
   */
  workaroundOptions?: IWorkaroundOptions;
}
