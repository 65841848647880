// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * All application names
 *
 * @public
 */
export enum AppName {
  MAX = 'max',
  HBOMAX = 'hbomax',
  BEAM = 'beam',
  DPLUS = 'dplus',
  STRESS = 'stress'
}
