// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type axios from 'axios';
import type { AxiosRequestConfig } from 'axios';
import type { IAxiosRetryConfig } from 'axios-retry';
import type { IRequestMethodConfig, IRequestRetryConfig, IResponse } from '../http-internal';

/**
 * Transforms IRequestConfig to an AxiosRequestConfig
 * @param requestConfig - original requestConfig
 * @param retryConfig - original retryConfig
 * @returns
 */
export function transformRequestMethod<T = unknown, R = IResponse<T, unknown>>(
  axiosMethodFn: typeof axios.get | typeof axios.delete
): (url: string, requestConfig?: IRequestMethodConfig, retryConfig?: IRequestRetryConfig) => Promise<R> {
  return (
    url: string,
    requestConfig: AxiosRequestConfig = {},
    retryConfig?: IRequestRetryConfig
  ): Promise<R> => {
    if (retryConfig) requestConfig['axios-retry'] = retryConfig as IAxiosRetryConfig;
    return axiosMethodFn(url, requestConfig);
  };
}

/**
 * Transforms IRequestConfig to an AxiosRequestConfig for methods that requires data payload
 * @param requestConfig - original requestConfig
 * @param retryConfig - original retryConfig
 * @returns
 */
export function transformRequestMethodWithData<T = unknown, R = IResponse<T, unknown>, D = unknown>(
  axiosMethodFn: typeof axios.post | typeof axios.patch | typeof axios.put
): (
  url: string,
  data: D,
  requestConfig?: IRequestMethodConfig,
  retryConfig?: IRequestRetryConfig
) => Promise<R> {
  return (
    url: string,
    data: D,
    requestConfig: AxiosRequestConfig = {},
    retryConfig?: IRequestRetryConfig
  ): Promise<R> => {
    if (retryConfig) requestConfig['axios-retry'] = retryConfig as IAxiosRetryConfig;
    // If data payload body is empty Axios doesn't send 'Content-Type': 'application/json'
    // setting data = {} when body is empty forces Axios to send 'Content-Type': 'application/json'
    // this is needed because some endpoints return a 500 when browsers append their own Content-Type
    // such as ps4 which appends application/x-www-form-urlencoded
    data = data || ({} as D);
    return axiosMethodFn(url, data, requestConfig);
  };
}
