// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IAuthTokenProvider } from '../token';
import { BoltEnvironment } from './BoltEnvironment';
import type { ClientId } from './ClientId';
import type { IDeviceConsentsConfig } from './IDeviceConsentsConfig';
import type { IDeviceInfo } from './IDeviceInfo';
import type { ISessionConfig } from './ISessionConfig';
import type { Platform } from './Platform';

/**
 * Config params are used for building the networking headers
 * @public
 */
export class SessionConfig implements ISessionConfig {
  /** {@inheritDoc ISessionConfig.environment} */
  public readonly environment: BoltEnvironment = BoltEnvironment.PRD;
  /** {@inheritDoc ISessionConfig.globalDomain} */
  public readonly globalDomain: string = 'api.discomax.com';
  /** {@inheritDoc ISessionConfig.brandId} */
  public readonly brandId: string;
  /** {@inheritDoc ISessionConfig.realm} */
  public readonly realm: string;
  /** {@inheritDoc ISessionConfig.clientId} */
  public readonly clientId: ClientId;
  /** {@inheritDoc ISessionConfig.applicationId} */
  public readonly applicationId: string;
  /** {@inheritDoc ISessionConfig.applicationVersion} */
  public readonly applicationVersion: string;
  /** {@inheritDoc ISessionConfig.deviceConsents} */
  public deviceConsents?: IDeviceConsentsConfig;
  /** {@inheritDoc ISessionConfig.deviceId} */
  public readonly deviceId: string;
  /** {@inheritDoc ISessionConfig.homeTerritoryHint} */
  public readonly homeTerritoryHint?: string;
  /** {@inheritDoc ISessionConfig.siteLookupKey} */
  public readonly siteLookupKey?: string;
  /** {@inheritDoc ISessionConfig.deviceInfo} */
  public readonly deviceInfo: IDeviceInfo;
  /** {@inheritDoc ISessionConfig.authTokenProvider} */
  public readonly authTokenProvider?: IAuthTokenProvider;
  /** {@inheritDoc ISessionConfig.platform} */
  public readonly platform: Platform;
  /** {@inheritDoc ISessionConfig.uatEnabled} */
  public readonly uatEnabled: boolean = false;
  /** {@inheritDoc ISessionConfig.asyncRails} */
  public readonly asyncRails: boolean = true;
  /** {@inheritDoc ISessionConfig.devicePreferredLanguages} */
  public devicePreferredLanguages: string[] = [];

  public constructor(data: ISessionConfig) {
    if (
      data.environment !== undefined &&
      Object.values(BoltEnvironment).includes(data.environment as BoltEnvironment)
    ) {
      this.environment = data.environment;
    }
    this.brandId = data.brandId;
    this.realm = data.realm;
    this.clientId = data.clientId;
    this.applicationId = data.applicationId;
    this.applicationVersion = data.applicationVersion;
    this.deviceConsents = data.deviceConsents;
    this.deviceId = data.deviceId;
    this.homeTerritoryHint = data.homeTerritoryHint;
    this.siteLookupKey = data.siteLookupKey;
    this.deviceInfo = data.deviceInfo;
    this.authTokenProvider = data.authTokenProvider;
    this.platform = data.platform;

    if (data.uatEnabled !== undefined) this.uatEnabled = data.uatEnabled;
    if (data.asyncRails !== undefined) this.asyncRails = data.asyncRails;
    if (data.globalDomain !== undefined) this.globalDomain = data.globalDomain;
    if (data.devicePreferredLanguages !== undefined)
      this.devicePreferredLanguages = data.devicePreferredLanguages;
  }
}
