// Copyright text placeholder, Warner Bros. Discovery, Inc.

/* eslint-disable @rushstack/packlets/mechanics */
import { SessionManager } from '@wbd/instrumentation-session-manager';
import {
  GlobalContext,
  IGlobalContext,
  IGlobalContextA11y,
  IGlobalContextApplicationStateDevice,
  IGlobalContextNetwork,
  IGlobalContextPage,
  IGlobalContextProps,
  IGlobalContextScreen
} from '../generated/context';

/**
 * The class that will store and manage global context for events
 * @public
 */
export class GlobalContextManager {
  private static _NOT_INITIALIZED_ERROR: string =
    'GlobalContextManager is not initialized or updated with a context';
  private static _globalContextPromise: Promise<void> = new Promise((resolve) => {
    GlobalContextManager._globalContextPromiseResolver = resolve;
  });
  private static _globalContextPromiseResolver: () => void;
  private static _globalContext: GlobalContext;

  /**
   * Initializes the GlobalContextManager
   * @param props - the props needed to initialize the GlobalContextManager
   */
  public static initialize(context?: IGlobalContextProps): void {
    if (context) {
      this._globalContext = new GlobalContext(context);
      this._globalContextPromiseResolver();
    }
  }

  /**
   * Updates the global context
   * @param context - the global context object to set to
   * @throws if the manager has not been initialized
   */
  public static updateGlobalContext(context: IGlobalContextProps): void {
    this.initialize(context);
  }

  /**
   * Returns the global context
   * @returns the current stored global context
   * @throws if the manager has not been initialized
   */
  public static getGlobalContextAsync(): Promise<IGlobalContext> {
    return this._globalContextPromise.then(() => {
      return {
        ...this._globalContext,
        applicationState: {
          ...this._globalContext.applicationState,
          sessionId: SessionManager.sessionId
        }
      };
    });
  }

  /**
   * Console logs an error message
   */
  private static _consoleError(): void {
    console.error(GlobalContextManager._NOT_INITIALIZED_ERROR);
  }

  /**
   * Update the Application state part of the global context
   * @param applicationState - The new application state data to use
   * @throws if the manager has not been initialized
   */
  public static updateApplicationState(
    applicationState: Partial<IGlobalContextProps['applicationState']>
  ): void {
    if (!GlobalContextManager._globalContext) {
      return GlobalContextManager._consoleError();
    }

    Object.assign(this._globalContext.applicationState, applicationState);
  }

  /**
   * Update the Page object part of the global context
   * @param pageApplicationState - The new page data to use
   * @throws if the manager has not been initialized
   */
  public static updatePageApplicationState(pageApplicationState: IGlobalContextPage): void {
    if (!GlobalContextManager._globalContext) {
      return GlobalContextManager._consoleError();
    }

    return this.updateApplicationState({
      ...this._globalContext.applicationState,
      page: pageApplicationState
    });
  }

  /**
   * Update the Screen object part of the global context
   * @param screenApplicationState - The new screen data to use
   * @throws if the manager has not been initialized
   */
  public static updateScreenApplicationState(screenApplicationState: IGlobalContextScreen): void {
    if (!GlobalContextManager._globalContext) {
      return GlobalContextManager._consoleError();
    }

    return this.updateApplicationState({
      ...this._globalContext.applicationState,
      screen: screenApplicationState
    });
  }

  /**
   * Update the a11y object part of the global context
   * @param a11yApplicationState - The new a11y data to use
   * @throws if the manager has not been initialized
   */
  public static updateA11yApplicationState(a11yApplicationState: IGlobalContextA11y): void {
    if (!GlobalContextManager._globalContext) {
      return GlobalContextManager._consoleError();
    }

    return this.updateApplicationState({
      ...this._globalContext.applicationState,
      a11y: a11yApplicationState
    });
  }

  /**
   * Update the Device object part of the global context
   * @param deviceApplicationState - The new device data to use
   * @throws if the manager has not been initialized
   */
  public static updateDeviceApplicationState(
    deviceApplicationState: IGlobalContextApplicationStateDevice
  ): void {
    if (!GlobalContextManager._globalContext) {
      return GlobalContextManager._consoleError();
    }

    return this.updateApplicationState({
      ...this._globalContext.applicationState,
      device: deviceApplicationState
    });
  }

  /**
   * Update the Network object part of the global context
   * @param networkApplicationState - The new page data to use
   * @throws if the manager has not been initialized
   */
  public static updateNetworkApplicationState(networkApplicationState: IGlobalContextNetwork): void {
    if (!GlobalContextManager._globalContext) {
      return GlobalContextManager._consoleError();
    }

    return this.updateApplicationState({
      ...this._globalContext.applicationState,
      network: networkApplicationState
    });
  }

  /**
   * Update the Application part of the global context
   * @param application - the new application data to use
   * @throws if the manager has not been initialized
   */
  public static updateApplication(application: IGlobalContext['application']): void {
    if (!GlobalContextManager._globalContext) {
      return GlobalContextManager._consoleError();
    }

    this._globalContext = {
      ...this._globalContext,
      application: application
    };
  }

  /**
   * Update the device part of the global context
   * @param device - the new device data to use
   * @throws if the manager has not been initialized
   */
  public static updateDevice(device: IGlobalContext['device']): void {
    if (!GlobalContextManager._globalContext) {
      return GlobalContextManager._consoleError();
    }

    this._globalContext = {
      ...this._globalContext,
      device: device
    };
  }
}
