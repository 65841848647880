// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { Listener, Unsubscribe } from '@wbd/light-events';
import { EventWithParams } from '@wbd/light-events';
import type { IInterceptor, IInterceptors, IRequestConfig, IResponse } from '../http-internal';
import { type ISessionState } from './ISessionState';
import {
  EncryptedSessionPayloadKey,
  PlainSessionPayloadKey,
  type SessionPayloadKey,
  type SessionPayloadEventValueForKey,
  type SessionPayloadValueForKey
} from './SessionPayload';

export const SESSION_STATE_KEY: string = 'session-state';

/**
 * This class manages retrieval, caching and manipulation of Session-state headers for Headwaiter responses in the client
 * @public
 */
export class SessionState implements ISessionState {
  /**
   * A string that holds the latest value of the session state header
   */
  private _sessionStateHeader?: string;

  /**
   * An object that holds decoded session state payload values
   */
  private _payloadValues: Record<string, string> | undefined;

  /**
   * A record of unique channels by session payload type that notifies subscribers when changed payload signals are received.
   */
  private readonly _onSessionPayloadChangeEvent: Record<
    SessionPayloadKey,
    EventWithParams<SessionPayloadEventValueForKey<SessionPayloadKey>>
  > = {
    [PlainSessionPayloadKey.Consents]: new EventWithParams<
      SessionPayloadEventValueForKey<PlainSessionPayloadKey.Consents>
    >(),
    [PlainSessionPayloadKey.Experience]: new EventWithParams<
      SessionPayloadEventValueForKey<PlainSessionPayloadKey.Experience>
    >(),
    [PlainSessionPayloadKey.Localization]: new EventWithParams<
      SessionPayloadEventValueForKey<PlainSessionPayloadKey.Localization>
    >(),
    [EncryptedSessionPayloadKey.Overrides]: new EventWithParams<
      SessionPayloadEventValueForKey<EncryptedSessionPayloadKey.Overrides>
    >()
  };

  /**
   * A channel that notifies subscribers when there is an exception in session-state onchange callbacks
   */
  private readonly _errorSubscribers: EventWithParams<[unknown]> = new EventWithParams<[unknown]>();

  /**
   * Triggers one ore multiple onSessionPayloadChange events
   * @param payloadKeys - the session-state payload keys should trigger an onChange event
   * @returns promise that awaits the onChange listeners
   */
  private async _triggerOnSessionPayloadChangeEvents(payloadKeys: string[]): Promise<void> {
    const isEncryptedKey = (key: string): boolean =>
      Object.values(EncryptedSessionPayloadKey).includes(key as EncryptedSessionPayloadKey);

    const clientPayloadKeys = payloadKeys.filter(
      (key) =>
        Object.values(PlainSessionPayloadKey).includes(key as PlainSessionPayloadKey) || isEncryptedKey(key)
    ) as SessionPayloadKey[];

    for (const key of clientPayloadKeys) {
      const payloadValue = isEncryptedKey(key)
        ? this._getEncodedPayload(key)
        : this.getPayload(key as PlainSessionPayloadKey);
      await this._onSessionPayloadChangeEvent[key].fire(payloadValue);
    }
  }

  /**
   * Response interceptor for x-wbd-session-state
   * @internal
   */
  private _responseInterceptor: IInterceptor<IResponse> = async (response) => {
    const sessionStateValue = response.headers['x-wbd-session-state'];

    if (sessionStateValue) {
      // Writes `x-wbd-session-state` from response headers to storage
      this.setHeader(sessionStateValue);
    }

    return response;
  };

  /**
   * Request interceptor for x-wbd-session-state
   * @internal
   */
  private _requestInterceptor: IInterceptor<IRequestConfig> = (config) => {
    const sessionStateHeader = this.getHeader();
    // Adds `x-wbd-session-state` inside request headers by reading from session storage
    if (sessionStateHeader && config.headers) {
      config.headers['x-wbd-session-state'] = sessionStateHeader;
    }

    return config;
  };

  /**
   * Configures the interceptors
   * @public
   */
  public configureInterceptors(interceptors: IInterceptors): void {
    interceptors.request.use(this._requestInterceptor);
    interceptors.response.use(this._responseInterceptor);
  }

  /**
   * Gets the current payload headers if sessionState private variable is undefined
   * @returns
   * @public
   */
  public getHeader(): string | undefined {
    return this._sessionStateHeader;
  }

  /**
   * Takes a key/value pair and saves it to storage synchronously
   * @param value - The value to be stored under `key`
   * @throws An Exception when the key/value pair cannot be added
   */
  public setHeader(value: string): void {
    // break the header down in its payload values
    if (value) {
      // update payload state
      const delta = this._sessionStateHeaderToPayloadValues(value);
      const newPayloadValues = this._deltaMergePayloadValues(this._payloadValues, delta);
      const mutatedKeys = this._getMutatedSessionPayloadKeys(this._payloadValues, newPayloadValues);
      this._payloadValues = newPayloadValues;

      // update header cache based on latest payload state
      const header = this._payloadValuesToSessionStateHeader(this._payloadValues);
      this._sessionStateHeader = header;

      // fire payload change listeners
      this._triggerOnSessionPayloadChangeEvents(mutatedKeys).catch((error) => {
        this._errorSubscribers.fire(error).catch(() => {
          // silently fail exceptions in the error subscriber
        });
      });
    }
  }

  /**
   * Get the mutated set of session state payload keys
   * comparing a current session-state payloads against a previous set
   * @param oldValues - old session state key/value object
   * @param newValues - new session state key/value object
   * @returns the session-state keys for which the values have changed
   */
  private _getMutatedSessionPayloadKeys(
    oldValues: Record<string, string> | undefined,
    newValues: Record<string, string>
  ): string[] {
    return Object.keys(newValues).filter(
      (k) => !(oldValues && k in oldValues && newValues[k] === oldValues[k])
    );
  }

  /**
   * Get a client session-state payload encoded value from the session state header.
   * @param key - session-state payload key
   * @returns
   */
  private _getEncodedPayload<T extends SessionPayloadKey>(key: T): string | undefined {
    return this._payloadValues ? this._payloadValues[key] : undefined;
  }

  /**
   * Get a client decodable session-state payload value from the session state header.
   * @param key - session-state payload key
   * @returns
   */
  public getPayload<T extends PlainSessionPayloadKey>(key: T): SessionPayloadValueForKey<T> {
    const payloadValue = this._getEncodedPayload(key);
    if (!payloadValue) return;
    return this._decodePayload<SessionPayloadValueForKey<T>>(payloadValue);
  }

  /**
   * Subscribe to payload changes in the session-state
   * @param type - session payload key to subscribe to
   * @param listener - callback listener to receive events on
   * @returns unsubscribe function
   */
  public onSessionPayloadChange<T extends SessionPayloadKey>(
    type: T,
    listener: Listener<SessionPayloadEventValueForKey<T>>
  ): Unsubscribe {
    return this._onSessionPayloadChangeEvent[type].addListener(
      listener as Listener<SessionPayloadEventValueForKey<SessionPayloadKey>>
    );
  }

  /**
   * Converts a string of key:value pairs, separated by semicolons, into a session-state payload object.
   *
   * The string is expected to be in the following form:
   *   key1:val1;key2:val2;...keyN:valN;
   *
   * This function is unsafe in the sense that no validation is done on the input string's format, for performance.
   * If the input does not follow the expected format, the output will be malformed.
   *
   * @param header - String of key-value pairs, separated by semicolon (see function documentation for expected format)
   * @returns An object composed of the key-value pairs parsed from the input.
   */
  private _sessionStateHeaderToPayloadValues(header: string): Record<string, string> {
    const keyValuePairs = header.split(';');
    const payload: Record<string, string> = {};
    keyValuePairs.forEach((keyValuePair) => {
      if (keyValuePair && keyValuePair.length) {
        const [key, value] = keyValuePair.split(':');
        payload[key] = value;
      }
    });
    return payload;
  }

  /**
   * Delta merge incremental session-state payload into an existing payload
   *
   * Bolt API will return delta changes as session-state response headers,
   * which should be merged into client maintained session-state payload
   * - update any key / value pairs
   *   key1:val1;key2:val2;
   * - delete any keys that have no defined value
   *   key3:;
   *
   * @param payload - key-value pair object holding session-state information
   * @param deltaPayload - key-value pair object holding incremental session-state changes
   *
   * @returns A merged object composed of the key-value pairs parsed from the input.
   */
  private _deltaMergePayloadValues(
    payload: Record<string, string> | undefined,
    deltaPayload: Record<string, string>
  ): Record<string, string> {
    const mergedPayload = Object.assign({}, payload);
    for (const key in deltaPayload) {
      if (Object.prototype.hasOwnProperty.call(deltaPayload, key)) {
        const value = deltaPayload[key];
        if (value) {
          mergedPayload[key] = value;
        } else {
          delete mergedPayload[key];
        }
      }
    }
    return mergedPayload;
  }

  /**
   * Converts key:value pairs into a string.
   *
   * The string is expected to be in the following form:
   *   key1:val1;key2:val2;...keyN:valN;
   * @returns header key:value pairs concatenated as string
   */
  private _payloadValuesToSessionStateHeader(payload: Record<string, string> | undefined): string {
    let header = '';
    if (payload) {
      for (const key in payload) {
        if (Object.prototype.hasOwnProperty.call(payload, key)) {
          header += `${key}:${payload[key]};`;
        }
      }
    }
    return header;
  }

  /**
   * decodes a base64 encoded payload value into a json object
   * @param payloadValue - the payload value from the headwaiter response header
   * @returns Decoded payload object
   */
  private _decodePayload<DecodedPayloadFormat>(payloadValue: string): DecodedPayloadFormat | undefined {
    try {
      const decodedValue = atob(payloadValue.split('.')[1]);
      return JSON.parse(decodedValue);
    } catch (e) {
      /**
       * if we can not parse decoded payload throw an error
       */
      const error = new Error(`Invalid encoding for session-state header with payload: ${payloadValue}`);
      this._errorSubscribers.fire(error).catch(() => {
        // silently fail exceptions in the error subscriber
      });
      return;
    }
  }

  /**
   * Expose an error subscriber to allow downstream consumers to capture session state related exception
   * for example in payload decoding and when calling \'onSessionPayloadChange\' listeners
   * @returns
   */
  public onError(listener: (error: unknown) => void): Unsubscribe {
    return this._errorSubscribers.addListener(listener);
  }
}
