// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { AxiosInterceptorManager } from 'axios';
import axios from 'axios';
import axiosRetry, { type IAxiosRetryConfig } from 'axios-retry';
import type {
  IDefaultRequestOptions,
  IHttpModuleInstance,
  IInterceptor,
  IInterceptorManager,
  IRequestConfig,
  IRequestRetryConfig,
  IResponse
} from '../http-internal';
import { transformRequestMethod, transformRequestMethodWithData } from './transformRequestMethod';

/**
 * Expose axios some internal interceptor manager
 * functions to support patching execution order
 */
export interface IAxiosInterceptorManagerInternal<V> extends AxiosInterceptorManager<V> {
  handlers: IInterceptor<V>[];
  forEach: (fn: (handler: IInterceptor<V>) => void) => void;
}

/**
 * Axios executes request interceptors in last in first out order, this makes it particularly useless
 * for us as we expect the base interceptors to always fire first. This overrides the forEach method to
 * execute in reverse order, without modifying the original array.
 *
 * https://github.com/axios/axios/blob/v1.x/lib/core/Axios.js#L90-L97
 *
 * https://github.com/axios/axios/issues/1663
 *
 * @param interceptorManager - interceptor manager
 */
function reverseInterceptorExecutionOrder<D>(interceptorManager: AxiosInterceptorManager<D>): void {
  (interceptorManager as IAxiosInterceptorManagerInternal<D>).forEach = (fn) => {
    const handlers = (interceptorManager as IAxiosInterceptorManagerInternal<D>).handlers;
    [...handlers].reverse().forEach((handler) => {
      if (handler !== null) {
        fn(handler);
      }
    });
  };
}

/**
 * Internal constructor to create the HTTP client
 * @param requestConfig - optional default request configuration
 * @param retryConfig - optional default retry configuration
 * @returns http instance
 */
export function createHttpModule(
  requestConfig?: Partial<IRequestConfig> & IDefaultRequestOptions,
  retryConfig?: IRequestRetryConfig
): IHttpModuleInstance {
  const axiosInstance = axios.create({
    ...requestConfig,
    // map bolt-http baseUrl to axios baseURL
    ...(requestConfig?.baseUrl ? { baseURL: requestConfig.baseUrl } : {}),
    ...(requestConfig?.requestAdapter ? { adapter: requestConfig.requestAdapter } : {}),
    transitional: {
      /**
       * this will cause axios to throw a ETIMEDOUT error instead of the generic ECONNABORTED
       * when a timeout has occurred.
       * see transitional under https://github.com/axios/axios#request-config
       */
      clarifyTimeoutError: true
    }
  });

  // Patch axios interceptor execution order.
  reverseInterceptorExecutionOrder(axiosInstance.interceptors.request);

  // Enable retry support with axios-retry extension.
  axiosRetry(axiosInstance, {
    ...(retryConfig as IAxiosRetryConfig | undefined),

    // Retries should reset the timeout, so that don't immediately retry with
    // no time remaining, and immediately fail again.
    shouldResetTimeout: true
  });

  // return axios http-module
  const axiosModule: IHttpModuleInstance = {
    interceptors: {
      get request() {
        return axiosInstance.interceptors.request as IInterceptorManager<IRequestConfig>;
      },
      get response() {
        return axiosInstance.interceptors.response as IInterceptorManager<IResponse>;
      }
    },
    get: transformRequestMethod(axiosInstance.get),
    put: transformRequestMethodWithData(axiosInstance.put),
    post: transformRequestMethodWithData(axiosInstance.post),
    patch: transformRequestMethodWithData(axiosInstance.patch),
    delete: transformRequestMethod(axiosInstance.delete)
  };
  return axiosModule;
}
