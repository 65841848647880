// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IBootstrapConfig, IBootstrapConfigRouteKey } from './IBootstrapConfig';

import type { BoltEnvironment } from '../session-config';
import BOOTSTRAP_CONFIG_DEFAULT from './BootstrapConfigDefault.json';

/**
 * Helper to merge 2 client config route key objects
 */
const mergeClientConfigRouteKeys = function (
  defaultConfig: IBootstrapConfigRouteKey,
  overrideConfig: IBootstrapConfigRouteKey
): IBootstrapConfigRouteKey {
  return {
    ...defaultConfig,
    ...overrideConfig
  };
};

/**
 * Base configuration for headwaiter endpoints that is expected to be overwritten via the headwaiter service.
 * @public
 */
export function clientConfigDefaults(env: BoltEnvironment, domain: string): IBootstrapConfig {
  return {
    ...BOOTSTRAP_CONFIG_DEFAULT,
    routing: mergeClientConfigRouteKeys(BOOTSTRAP_CONFIG_DEFAULT.routing as IBootstrapConfigRouteKey, {
      env,
      domain
    })
  };
}
