// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ILabsSDKOptions } from './LabsOptions.types';

/**
 * Enum of Labs SDK observable events.
 * @public
 */
export enum LabsSDKEvents {
  /** Decision Accessed event fired by LabsSDK.getLabsDecision() */
  DecisionAccessed = 'decision_accessed',
  /** Request Error event fired by LabsResponseCache on failed labs request */
  RequestError = 'request_error'
}

/**
 * Decision access parameters
 * @public
 */
export interface IDecisionAccessPayload {
  flagId: string;
  source: string;
  variantId: string;
  seedId?: string;
}

/**
 * Returned parameters for Labs SDK DecisionAccessed event.
 * @public
 */
export interface ILabsSDKEventDecisionAccessedParams {
  /** 'flagId' | 'source' | 'variantId' needed for access tracking. {@link ILabsDecisionData} */
  payload: IDecisionAccessPayload;
  /** ProjectId of the labs project {@link ILabsSDKOptions} */
  projectId: ILabsSDKOptions['projectId'];
}

/**
 * Returned parameters for Labs SDK Error events.
 * @public
 */
export interface ILabsSDKEventError {
  /** Error object */
  error: Error;
}

/**
 * Defines parameters for Labs SDK observable events by event type.
 * @public
 */
export type ILabsSDKEventParams<E> = E extends LabsSDKEvents.DecisionAccessed
  ? ILabsSDKEventDecisionAccessedParams
  : E extends LabsSDKEvents.RequestError
  ? ILabsSDKEventError
  : unknown;
