// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { JsonApiDocument } from 'json-api-models';
import type { DataTypes, Flatten } from '../json-api';

/**
 * @public
 */
export type EntryType = DataTypes;

/**
 * @public
 */
export interface IStoreLight {
  [key: string]: Record<string, IStoreLightEntry>;
}

/**
 * @public
 */
export interface IStoreLightRelationship {
  id: string;
  type: string;
  attributes?: object;
}

/**
 * @public
 */
export interface IStoreLightEntry {
  type: string;
  id: string;
  attributes?: object;
  meta?: unknown;
  relationships?: Record<string, { data: IStoreLightRelationship | IStoreLightRelationship[] }>;
  __resolved?: boolean;
}

function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null;
}

function resolve(graph: IStoreLight, entry: IStoreLightEntry): IStoreLightEntry {
  if (!isObject(entry) || entry.__resolved) {
    return entry;
  }

  Object.defineProperty(entry, '__resolved', { value: true, enumerable: false });
  const { attributes, relationships } = entry;

  // copy attributes
  if (isObject(attributes)) {
    for (const key in attributes) {
      // don't overwrite type
      if (key === 'type') {
        entry.typeAttribute = attributes.type;
      } else {
        entry[key] = attributes[key];
      }
    }
  }

  // resolve relationships
  if (relationships) {
    let defaultTarget = undefined;
    (Object.keys(relationships) as EntryType[]).forEach((key) => {
      const data = relationships[key].data;
      if (Array.isArray(data)) {
        const collection: IStoreLightEntry[] = [];

        data.forEach((relationshipEntry) => {
          const cursor = graph[relationshipEntry.type]?.[relationshipEntry.id];
          if (cursor) {
            collection.push(resolve(graph, cursor));
          }
        });

        entry[key] = collection;
      } else {
        const cursor = graph[data.type]?.[data.id];
        if (cursor) {
          entry[key] = resolve(graph, cursor);
          defaultTarget = entry[key];
        } else if (!entry[key]) {
          // If attributes are present then it's a complete object and don't need to mark __missingRelationship: true
          if (data.attributes) {
            entry[key] = { ...data };
          } else {
            // Create placeholder `{ id, type, __missingRelationship }` object when relationship is missing in API response
            entry[key] = { __missingRelationship: true, ...data };
          }
        }
      }
    });

    if (entry.target === undefined && defaultTarget) {
      Object.defineProperty(entry, 'target', { value: defaultTarget, enumerable: false });
    }

    // @deprecated
    // for backwards compabity, need rework later
    // delete entry.relationships;
    Object.defineProperty(entry, 'relationships', { enumerable: false });
  }

  if (!entry.meta) entry.meta = {};

  return entry;
}

interface IJsonApiDocumentLight {
  data: IStoreLightEntry | IStoreLightEntry[];
  included?: IStoreLightEntry[];
  meta?: unknown;
}

function deserialize(json: IJsonApiDocumentLight): IStoreLight {
  const graph: IStoreLight = {};

  const inject = (o: { type: string; id: string }): void => {
    graph[o.type] ||= {};
    graph[o.type][o.id] ||= o;
  };

  // registering objects in the graph for later resolution
  if (json.included) {
    json.included.forEach(inject);
  }

  // the response might be single as well as a collection of objects
  if (Array.isArray(json.data)) {
    json.data.forEach(inject);
  } else {
    inject(json.data);
  }

  return graph;
}

/**
 * Simple JSON API transformer which reconstructs the JSON document without database-like features
 *
 * @public
 */
export class TransformerLight<
  T extends DataTypes | DataTypes[],
  M extends Record<DataTypes, unknown>,
  R extends DataTypes = Flatten<T>,
  D = T extends DataTypes[] ? M[R][] : M[R]
> {
  private _store: IStoreLight;
  private _data: IStoreLightEntry | IStoreLightEntry[];

  /**
   * Transformed response payload
   */
  public get data(): D {
    const { _store, _data } = this;
    if (Array.isArray(_data)) {
      return _data.map((value) => resolve(_store, value)) as D;
    } else {
      return resolve(_store, _data) as D;
    }
  }

  /**
   * Transform a JSON API response
   */
  public constructor(document: JsonApiDocument<R>) {
    this._store = deserialize(document as IJsonApiDocumentLight);
    this._data = document.data as IStoreLightEntry | IStoreLightEntry[];
  }

  /**
   * Look up a resource by `type` and `id`
   */
  public find<Type extends keyof M & string>(type: Type, id: string): M[Type] | undefined {
    return resolve(this._store, this._store[type]?.[id]) as unknown as M[Type];
  }

  /**
   * Look up all resources by `type`
   */
  public findAll<Type extends keyof M & string>(type: Type): M[Type][] {
    return Object.values(this._store[type] || []).map((value) =>
      resolve(this._store, value)
    ) as unknown as M[Type][];
  }
}
