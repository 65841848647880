// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { SessionManager } from '@wbd/instrumentation-session-manager';
import { Emitter } from '../core';
import { Coordinater, GlobalContextManager, HeartbeatMonitor, PerformanceManager } from '../managers';
import { EventQueueMonitor } from '../transmission';

const { initialize, updateConfiguration } = Coordinater;
const {
  updateApplication,
  updateApplicationState,
  updatePageApplicationState,
  updateDevice,
  updateGlobalContext
} = GlobalContextManager;

/**
 * Instrumentation SDK
 * @public
 */
/* eslint-disable-next-line @rushstack/typedef-var */
export const InstrumentationSDK = {
  sessionId: SessionManager.sessionId,

  initialize: initialize.bind(Coordinater),

  updateApplication: updateApplication.bind(GlobalContextManager),

  updateApplicationState: updateApplicationState.bind(GlobalContextManager),

  updatePageApplicationState: updatePageApplicationState.bind(GlobalContextManager),

  updateConfiguration: updateConfiguration.bind(Coordinater),

  updateDevice: updateDevice.bind(GlobalContextManager),

  updateGlobalContext: updateGlobalContext.bind(GlobalContextManager),

  flushEventQueue: EventQueueMonitor.flushEventQueue.bind(Emitter)
} as const;

/**
 * Heartbeat monitor class used to enable, disable and emit heartbeat events
 * @public
 */
const { Heartbeat } = HeartbeatMonitor;
export { Heartbeat as ApplicationHeartbeat };

/**
 * Performance monitor class used to create marks
 * @public
 */
const { Mark } = PerformanceManager;
export { Mark as PerformanceMark };

/**
 * Performance monitor class used to create marks
 * @public
 */
const { Measure } = PerformanceManager;
export { Measure as PerformanceMeasure };

/**
 * Adds an event listener for only events
 * @public
 */
export const addEventsListener: typeof Emitter.addEventListener = Emitter.addEventKindListener.bind(
  Emitter,
  'events'
);

/**
 * Adds an event listener for only errors
 * @public
 */
export const addErrorsListener: typeof Emitter.addEventListener = Emitter.addEventKindListener.bind(
  Emitter,
  'errors'
);

/**
 * Adds an event listener for all errors and events
 * @public
 */
export const addAllEventsListener: typeof Emitter.addEventListener = Emitter.addEventListener.bind(Emitter);

/**
 * Adds an event listener for all errors and events that provides the context with the event
 * @public
 */
export const addEventWithContextListener: typeof Emitter.addEventWithContextListener =
  Emitter.addEventWithContextListener.bind(Emitter);
