// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { createSignal, type Accessor } from 'solid-js';
import type { TFontCategory } from '../fonts';
import type { IFuseCTVResources } from './IFuseCTVResources';
import { Resources } from './Resources';
import { BorderToken, ColorToken, ElevationTokens, GradientTokens, MotionToken, TextToken } from './tokens';
import type { ITextTokens } from '../generated';

const [category, setCategory] = createSignal<TFontCategory>('english-like');

/**
 * Reactive text token category value
 * @public
 */
export const textTokenCategory: Accessor<TFontCategory> = category;

/**
 * Update the text token category, which should reactively update styles using the text tokens
 * @public
 */
export function setTextTokenCategory(category: TFontCategory): void {
  setCategory(category);
}

/**
 * Loads resources into the global `Resources` variable
 *
 * @param brandResources - The brand-specific values
 *
 * @public
 */
export function loadResources(brandResources: IFuseCTVResources): void {
  Object.assign(Resources, brandResources);

  // Assign static tokens
  Object.assign(BorderToken, Resources.design.border);
  Object.assign(ColorToken, Resources.design.color);
  Object.assign(ElevationTokens, Resources.design.elevation);
  Object.assign(GradientTokens, Resources.design.gradient);
  Object.assign(MotionToken, Resources.design.motion);

  // Assign reactive tokens
  const defaultTextTokens = Resources.design.text['english-like'];
  (Object.keys(defaultTextTokens) as (keyof ITextTokens)[]).forEach((key) => {
    Object.defineProperty(TextToken, key, {
      get() {
        return Resources.design.text[category()][key];
      },
      enumerable: true,
      configurable: true
    });
  });
}
