// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { GlobalizeStatic } from '@wbd/localization';

export async function loadLanguagePack(languageCode: string): Promise<GlobalizeStatic> {
  const languagePack = await import(
    /* webpackChunkName: "i18n/language-packs/[request]" */
    `./language-packs/${languageCode}/compiled-messages.js`
  );
  return languagePack.default;
}
