// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IStorage } from '@wbd/beam-ctv-platform-adapters';
import { AbstractDevice, DevicePerformance, DeviceType } from '@wbd/beam-ctv-platform-adapters';
import type { IPlatformDeviceInfo } from '../../service';

export async function validateDeviceInfo(
  deviceInfoOriginal: IPlatformDeviceInfo,
  storage: IStorage
): Promise<IPlatformDeviceInfo> {
  // to avoid ESLint eslintrequire-atomic-updates warning
  const deviceInfo = deviceInfoOriginal;

  if (!deviceInfo.deviceId || typeof deviceInfo.deviceId !== 'string') {
    deviceInfo.deviceId = await AbstractDevice.getDeviceIdentifierAsync(storage);
  }
  if (!deviceInfo.deviceAdIdentifier || typeof deviceInfo.deviceAdIdentifier !== 'string') {
    deviceInfo.deviceAdIdentifier = await AbstractDevice.getDeviceAdIdentifierAsync(storage);
  }
  if (typeof deviceInfo.model !== 'string') {
    deviceInfo.model = await AbstractDevice.getDeviceModelNameAsync();
  }
  if (typeof deviceInfo.modelDisplayName !== 'string') {
    deviceInfo.modelDisplayName = await AbstractDevice.getDeviceModelDisplayNameAsync();
  }
  if (typeof deviceInfo.modelGroup !== 'string') {
    deviceInfo.modelGroup = await AbstractDevice.getDeviceModelGroupAsync();
  }
  if (typeof deviceInfo.modelYear !== 'string') {
    deviceInfo.modelYear = await AbstractDevice.getDeviceModelYearAsync();
  }
  if (!Object.values(DevicePerformance).includes(deviceInfo.performance)) {
    deviceInfo.performance = DevicePerformance.AVERAGE;
  }
  if (!Object.values(DeviceType).includes(deviceInfo.type)) {
    deviceInfo.type = DeviceType.STB;
  }
  if (typeof deviceInfo.operatingSystem !== 'string') {
    deviceInfo.operatingSystem = await AbstractDevice.getDeviceOsAsync();
  }
  if (typeof deviceInfo.operatingSystemVersion !== 'string') {
    deviceInfo.operatingSystemVersion = await AbstractDevice.getDeviceOSVersionAsync();
  }
  if (typeof deviceInfo.brand !== 'string') {
    deviceInfo.brand = await AbstractDevice.getDeviceBrandAsync();
  }
  if (typeof deviceInfo.manufacturer !== 'string') {
    deviceInfo.manufacturer = await AbstractDevice.getDeviceManufacturerAsync();
  }
  if (typeof deviceInfo.locale !== 'string') {
    deviceInfo.locale = await AbstractDevice.getDeviceLocaleAsync();
  }
  if (typeof deviceInfo.storeCountryCode !== 'string') {
    deviceInfo.storeCountryCode = await AbstractDevice.getStoreCountryCodeAsync();
  }

  return deviceInfo;
}
