// Copyright text placeholder, Warner Bros. Discovery, Inc.

import axios from 'axios';
import type { IHttpError } from '../http-internal';

/**
 * @public
 *
 * Checks if an error is HttpError
 *
 * @param error - error
 * @returns whether if the error is a HttpError error or not
 */
export const isHttpError = (error: unknown): error is IHttpError => axios.isAxiosError(error);
