// Copyright text placeholder, Warner Bros. Discovery, Inc.

/* eslint-disable @typescript-eslint/naming-convention */

import { type ILabsDecisionData } from '@wbd/labs-sdk';
import type { ICDevFeatureFlagConfig } from './ICDevFeatureFlagConfig';

type ICDevFeatureFlagDefaults = {
  [flag in keyof ICDevFeatureFlagConfig]: ILabsDecisionData<ICDevFeatureFlagConfig[flag]>;
};

/**
 * Default feature flag value configuration
 */
export const LABS_FLAGS_DEFAULTS: ICDevFeatureFlagDefaults = {
  /**
   * Whether to display adult rating on Edit Profile
   */
  Adult_Ratings: {
    type: 'dynamic',
    on: false
  },

  /**
   * Whether to enable the AuthUp features.
   */
  Auth_Up: {
    type: 'dynamic',
    on: false
  },

  /**
   * Enable showing viewers ads as they browse for content on the Home Page
   */
  Branded_Discovery: {
    type: 'dynamic',
    on: false
  },

  /**
   * Sonic routes for CDEV
   */
  CDEV_Routes: {
    type: 'dynamic',
    on: true
  },

  /**
   * Whether to enable Profile PIN on Kid's Edit Profile
   */
  Child_Profile_Pin: {
    type: 'dynamic',
    on: false
  },

  /**
   * Configuration for iSDK
   */
  config_isdk: {
    type: 'dynamic',
    on: false
  },

  /**
   * Configuration for Mercury messaging
   */
  config_mercury: {
    type: 'dynamic',
    on: false
  },

  /**
   * Whether to enable Contextual Discovery
   */
  Contextual_Discovery: {
    type: 'dynamic',
    on: false
  },

  /**
   * Whether to enable Kids-proof exit by default on Create Profile
   */
  Default_Kid_Proof_Exit: {
    type: 'dynamic',
    on: false
  },

  /**
   * Whether to enable the default kid settings
   */
  Default_Kid_Settings: {
    type: 'dynamic',
    on: false
  },

  /**
   * Enables DOM subtitle renderer in player.
   */
  Dom_Cue: {
    type: 'dynamic',
    on: false
  },

  /**
   * Whether to enable Dynamic paywall page
   */
  Dynamic_Paywall: {
    type: 'dynamic',
    on: false
  },

  /**
   * Whether to enable Dynamic welcome page
   */
  Dynamic_Welcome_Page: {
    type: 'dynamic',
    on: false
  },

  /**
   * Whether the device support end card experience
   */
  End_Card: {
    type: 'dynamic',
    on: true
  },

  /**
   * Whether the device support end card experience during credits
   */
  End_Card_Start_During_Credits: {
    type: 'dynamic',
    on: true
  },

  /**
   * Enables key moments in sport skin.
   */
  Key_Moments: {
    type: 'dynamic',
    on: false
  },

  /**
   * Immersive Hero delay between each slide (this is only for image based tiles, not video)
   */
  Immersive_Hero: {
    type: 'dynamic',
    on: true
  },

  /**
   * Whether the device supports playing video inline with content such as Hero
   */
  Inline_Video: {
    type: 'dynamic',
    on: true
  },

  /**
   * Enables discovery rails below the progress bar.
   */
  In_Player_Discovery: {
    type: 'dynamic',
    on: false
  },

  /**
   * Flag to control what we display in the IPD
   */
  IPD_Component_Allowlist: {
    type: 'dynamic',
    on: true
  },

  /**
   * Enables mobile sign up and sign in
   */
  Is_Mobile_Auth_Enabled: {
    type: 'dynamic',
    on: false
  },

  /**
   * Whether to enable fetching the Kids Mode description from the API.
   */
  Kids_Mode_Policy: {
    type: 'dynamic',
    on: false
  },

  /**
   * Whether to enable language selector on Edit Profile
   */
  Language_Selector: {
    type: 'dynamic',
    on: false
  },

  /**
   * Config for live event metadata includes live edge offset
   */
  Live_Event_Metadata: {
    type: 'dynamic',
    on: true
  },

  /**
   * Config for live linear metadata minutes to start prefetching
   * before current live content ends
   */
  Live_Linear_Metadata: {
    type: 'dynamic',
    on: true
  },

  /**
   * default config value for profile lock configuration
   */
  Lock_Profile: {
    type: 'dynamic',
    on: false
  },

  /**
   * The Sonic alias for the app side menu bar
   */
  Menu_Alias: {
    type: 'dynamic',
    on: true
  },

  /**
   * Whether to display Device Id on Privacy & Terms page
   */
  Multiverse_Device_Id: {
    type: 'dynamic',
    on: true
  },

  /**
   * Whether to source the AVFS from the main hero collection without the additional API call
   */
  New_ActiveVideoForShow: {
    type: 'dynamic',
    on: false
  },

  /**
   * Whether the user can sign-in with their username/password
   */
  On_Device_Sign_In: {
    type: 'dynamic',
    on: true
  },

  /**
   * When out of footprint, defines whether to display the hardcoded geoblock
   * page instead of the CMS-driven enhanced OOF experience
   */
  OOF_Geoblock: {
    type: 'dynamic',
    on: true
  },

  /**
   * The default value for Autoplay Episodes in Playback Settings
   */
  Playback_Settings_Autoplay_Episodes: {
    type: 'dynamic',
    on: true
  },

  /**
   * The default value for Autoplay Previews in Playback Settings
   */
  Playback_Settings_Autoplay_Previews: {
    type: 'dynamic',
    on: true
  },

  /**
   * Default config values for player resiliency
   */
  player_config_resiliency: {
    type: 'dynamic',
    on: true
  },

  /**
   * default config values for player disable retry button on error view
   */
  player_disable_retry_button_on_error_view: {
    type: 'dynamic',
    on: true
  },

  /**
   * Default alias for privacy links
   */
  Privacy_Alias: {
    type: 'dynamic',
    on: true
  },

  /**
   * Whether to enable age and gender capture
   */
  Profile_Age_Gender: {
    type: 'dynamic',
    on: false
  },

  /**
   * Whether to enable the R21 features.
   */
  R21_Pin_Enabled: {
    type: 'dynamic',
    on: false
  },

  /**
   * Temporary flag to control switching between the new normaliser lib and legacy normaliser
   */
  Rail_Normaliser_V2: {
    type: 'dynamic',
    on: true
  },

  /**
   * default visibility rules for player ratings
   */
  'ratings-regional-visibility-rules': {
    type: 'dynamic',
    on: true
  },

  /**
   * Temporary flag for consent experience reconsent from session state
   */
  Reconsent_From_Session_State: {
    type: 'dynamic',
    on: false
  },

  /**
   * Whether to enable Search Suggestions within the app
   */
  Search_Suggestions: {
    type: 'dynamic',
    on: false
  },

  /**
   * Whether to enable the texture pinning logic to prevent tile images being GC'd
   */
  Sticky_Textures: {
    type: 'dynamic',
    on: true
  },

  /**
   * Sports Skin configuration, by default the url is undefined
   */
  Sports_Skin_Config: {
    type: 'dynamic',
    on: true
  },

  /**
   * List for the IAB applicable US states
   */
  US_Iab_Applicable_States: {
    type: 'dynamic',
    on: true
  },

  /**
   * Whether the client should be attaching Arkose headers to login request
   */
  Use_Arkose: {
    type: 'dynamic',
    on: true
  },

  /**
   * Whether to refresh the app on the refresh UX signal
   */
  UX_Refresh: {
    type: 'dynamic',
    on: true
  },

  /**
   * Default alias for welcome config routes
   */
  Welcome_Config_Routes: {
    type: 'dynamic',
    on: true
  },

  /**
   * Images for the welcome screens
   */
  Welcome_Screen: {
    type: 'dynamic',
    on: true
  }
};
