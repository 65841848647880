// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { noop } from '@wbd/beam-js-extensions';
import type { TFontScript } from '@wbd/fuse-ctv-resources-common';
import FontFaceObserver from 'fontfaceobserver';

interface IFont {
  fontFamily: string;
  fontWeight?: string;
  testString?: string;
}

const fontFaceObserverTimeout: number = 5000; // Default is 3000, we will adjust + fine tune

/**
 *
 * Create font loaders from a configuration list
 *
 * @param fonts - the list of fonts to load
 * @returns A promise that resolves when all fonts started (or finished) loaded
 * @public
 */
function preloadWebFonts(fonts: IFont[]): Promise<void> {
  const fontsLoaders = fonts.map((font) => {
    const fontObserver = new FontFaceObserver(font.fontFamily, {
      weight: font.fontWeight
    });
    const awaiter = fontObserver.load(font.testString, fontFaceObserverTimeout).catch((error) => {
      throw new Error(`Failed to load font: ${font.fontFamily} - error: ${error?.message}`);
    });

    return awaiter;
  });

  return Promise.allSettled(fontsLoaders).then(noop);
}

/**
 * Load fonts for the required scripts coverage
 *
 * @returns A promise that resolves when the css files have been loaded
 * @public
 */
export function loadFontsCss(script: TFontScript): Promise<unknown> {
  switch (script) {
    case 'Hans':
      return import('./fonts-sc.css');
    case 'Hant-TW':
      return import('./fonts-tc.css');
    case 'Hant-HK':
      return import('./fonts-hk.css');
    case 'Thai':
      return import('./fonts-thai.css');
    default:
      return import('./fonts-default.css');
  }
}

/**
 * Preloads a list of fonts
 *
 * @returns - A promise that resolves when the list of fonts is processed
 */
export function loadFonts(): Promise<void> {
  const fonts: IFont[] = [
    // main application fonts
    { fontFamily: 'book' },
    { fontFamily: 'book', fontWeight: 'bold' }
  ];

  return preloadWebFonts(fonts);
}

/**
 * Preload less essential fonts at a later time in the application.
 *
 * @param onError - Function to run when an error occurs loading extra fonts.
 * @returns - void
 * @public
 */
export async function loadExtraFonts(): Promise<void> {
  const fonts: IFont[] = [
    // captions fonts
    { fontFamily: 'ui_cinecav' },
    { fontFamily: 'serif_cinecav' },
    { fontFamily: 'monospace_cinecav' },
    { fontFamily: 'sansserif_cinecav' },
    { fontFamily: 'casual' },
    { fontFamily: 'script' },
    { fontFamily: 'small-caps' }
  ];

  return import('./fonts-extras.css').then(() => preloadWebFonts(fonts));
}

/**
 * Ensure project fonts are loaded
 *
 * @public
 */
export async function loadAllFonts(script: TFontScript = 'Latn'): Promise<void> {
  await loadFontsCss(script);
  await loadFonts();
}
