// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { AsyncTimer } from '@wbd/beam-js-extensions';
import { aggregateDebounce, timer } from '@wbd/beam-js-extensions';
import type { Unsubscribe } from '@wbd/light-events';
import { EventWithParams } from '@wbd/light-events';
import type { BootstrapConfig } from '../bootstrap-config';
import type { IInterceptor, IInterceptors, IResponse } from '../http-internal';
import type { ISessionState, SessionState } from '../session-state';
import { EncryptedSessionPayloadKey, PlainSessionPayloadKey } from '../session-state';
import type {
  IRefreshManager,
  RefreshEventParams,
  RefreshEventSessionPayloadSignal,
  RefreshListener
} from './IRefreshManager';
import type { RefreshPayload } from './RefreshSignal';
import { RefreshSignal, parseRefreshHeader } from './RefreshSignal';

/**
 * Maximum time in milliseconds that a refresh lock will be in place.
 */
export const REFRESH_LOCK_TIMEOUT: number = 5000;

/**
 * Debounce delay before the refresh event will fire
 */
export const DEBOUNCE_REFRESH_EVENT_DELAY: number = 100;

/**
 * Helper function that can merge two refresh event argument values
 * @param a - previous refresh signal argument value
 * @param b - latest refresh signal argument value
 * @returns merged refresh signal argument value
 */
function mergeRefreshEventArgument(
  a: RefreshSignal[] | RefreshEventSessionPayloadSignal,
  b: RefreshSignal[] | RefreshEventSessionPayloadSignal
): RefreshSignal[] | RefreshEventSessionPayloadSignal {
  // Unique merge RefreshSignal[]
  // shallow merge RefreshEventSessionPayloadSignal object
  // and return latest value if first is undefined
  if (Array.isArray(a) && Array.isArray(b)) {
    return [...new Set([...a, ...b])];
  } else if (typeof a === 'object' && !!a && typeof b === 'object' && !!b) {
    return { ...a, ...b };
  } else {
    return b;
  }
}

/**
 * Handles all aspects of responding to x-wbd-refresh signals.
 *
 * Coordinates closely with BootstrapConfig in order to orchestrate managed bootstrap
 * configuration.
 *
 * @public
 */
export class RefreshManager implements IRefreshManager {
  /**
   * private BootstrapConfig
   */
  private readonly _bootstrapConfig: BootstrapConfig | undefined;
  /**
   * private SessionState
   */
  private readonly _sessionState: ISessionState;

  /**
   * A promise that will resolve when the refresh lock has been removed.
   *
   * When no refresh lock is in place, this attribute will be undefined.
   *
   * WARNING: All API calls will be blocked by this promise.
   *
   * It is critical that this promise is guaranteed to reject or resolve in a timely fashion.
   */
  private _refreshLock: Promise<void> | undefined;

  /**
   * A channel that notifies subscribers when refresh signals are received.
   */
  private readonly _onRefreshEvent: EventWithParams<RefreshEventParams> =
    new EventWithParams<RefreshEventParams>();

  /**
   * A channel that notifies subscribers when there is a refresh manager / event exception
   */
  private readonly _errorSubscribers: EventWithParams<[unknown]> = new EventWithParams<[unknown]>();

  /**
   * A helper that debounces the 'fire' signals for the _onRefreshEvent
   */
  private _onRefreshEventFireDebounced: (...args: RefreshEventParams) => () => void = aggregateDebounce(
    (...args: RefreshEventParams) => {
      // Fire downstream refresh event if debounce releases.
      this._onRefreshEvent.fire(...args).catch((error) => {
        this._errorSubscribers.fire(error).catch(() => {
          // Fail silently.
        });
      });
    },
    DEBOUNCE_REFRESH_EVENT_DELAY,
    mergeRefreshEventArgument
  );

  public constructor(bootstrapConfig: BootstrapConfig | undefined, sessionState: SessionState) {
    this._bootstrapConfig = bootstrapConfig;
    this._sessionState = sessionState;
    const sessionPayloadKeys = [
      ...Object.values(PlainSessionPayloadKey),
      ...Object.values(EncryptedSessionPayloadKey)
    ];

    // Monitor for session payload changes on the session manager.
    for (const key of sessionPayloadKeys) {
      this._sessionState.onSessionPayloadChange(key, (payload) => {
        // Fire client refresh logic.
        const updatedSessionPayload = { [key]: payload };
        this._onRefreshEventFireDebounced([], updatedSessionPayload);
      });
    }
  }

  /**
   * Handle HTTP responses. Detect refresh signals and handle requests that are
   * in-flight when the bootstrap configuration is refreshed.
   */
  private readonly _responseInterceptor: IInterceptor<IResponse> = async (response) => {
    const refreshType = response.headers['x-wbd-refresh'] as RefreshPayload;
    const refreshSignals = parseRefreshHeader(refreshType);
    if (refreshSignals.length > 0) {
      // If a request triggers a lock, wait for it to resolve
      await this._applyRefreshLock(refreshSignals, response);

      // Fire client side refresh logic.
      this._onRefreshEventFireDebounced(refreshSignals, {});
    }

    return response;
  };

  /**
   * Inspect the refresh signals on an HTTP response and if appropriate initiate the refresh process.
   *
   * When a refresh is started, a promise that will resolve when the refresh has completed, failed,
   * or timed out will be stored in the `_refreshLock` property.
   *
   * @param refreshSignals - an array of refresh signals parsed from a response header
   * @param response - the HTTP client response that is being checked.  It is passed to the function that initiates the refresh.
   *
   * @returns when a refresh is initiated, the lock is returned, otherwise we return a promise that resolves immediately.
   */
  private async _applyRefreshLock(refreshSignals: RefreshSignal[], response: IResponse): Promise<void> {
    if (this._refreshLock) return;
    if (!refreshSignals.includes(RefreshSignal.BOOTSTRAP)) return;

    const refresh = this._bootstrapConfig?.refreshBootstrap(response);

    const timeout: AsyncTimer = timer(REFRESH_LOCK_TIMEOUT);

    this._refreshLock = Promise.race([refresh, timeout])
      .catch(() => undefined) // Do not leak exceptions/rejections
      .finally(() => {
        this._refreshLock = undefined;
        timeout.cancel();
      });

    return this._refreshLock;
  }

  /**
   * Call to install required interceptors on HTTP Client instance.
   * @internal
   */
  public configureInterceptors(interceptors: IInterceptors): void {
    interceptors.response.use(this._responseInterceptor.bind(this));
  }

  /**
   * Adds a listener to be called when a Session Refresh signal is received from the x-wbd-refresh header
   * and / or when a Session Payload change is detected in the x-wbd-session-state header.
   * (limited to payloads readable by the client)
   *
   * The callback is debounced for incremental events and
   * if multiple signals are received the callback arguments are merged for the listener.
   * @param listener - The listener to be added to the onRefreshEvent
   * @public
   */
  public onRefresh(listener: RefreshListener): Unsubscribe {
    return this._onRefreshEvent.addListener(listener);
  }

  /**
   * Returns a promise that will resolve when the refresh lock is no longer in place.
   *
   * If managed bootstrap refreshes are not enabled, this will resolve immediately.
   */
  public async checkRefreshLock(config?: { bypassBootstrapRefreshLock?: boolean }): Promise<void> {
    if (!config?.bypassBootstrapRefreshLock) {
      // Prevent refresh lock rejections breaking requests
      await this._refreshLock?.catch(() => undefined);
    }
  }

  /**
   * Expose an error subscriber to allow downstream consumers to capture \'onRefresh\' listener callback exceptions
   * @returns
   */
  public onError(listener: (error: unknown) => void): void {
    this._errorSubscribers.addListener(listener);
  }
}
