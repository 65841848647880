// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type {
  IBorderTokens,
  IColorTokens,
  IElevationTokens,
  IGradientTokens,
  IMotionTokens,
  ITextTokens
} from '../generated';

/**
 * The border token definitions
 *
 * @public
 */
export const BorderToken: IBorderTokens = {} as IBorderTokens;
/**
 * The color token definitions
 *
 * @public
 */
export const ColorToken: IColorTokens = {} as IColorTokens;
/**
 * The elevation token definitions
 *
 * @public
 */
export const ElevationTokens: IElevationTokens = {} as IElevationTokens;
/**
 * The gradient token definitions
 *
 * @public
 */
export const GradientTokens: IGradientTokens = {} as IGradientTokens;
/**
 * The motion token definitions
 *
 * @public
 */
export const MotionToken: IMotionTokens = {} as IMotionTokens;
/**
 * The text token definitions
 *
 * @public
 */
export const TextToken: ITextTokens = {} as ITextTokens;
