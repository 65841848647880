// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { IGluonConfig } from '../types/IGluonConfig';

/**
 *  Gluon Playback Engine Config from Sonic Labs
 * @public
 */
export interface IGluonPlaybackEngineLabsConfig extends IGluonConfig {}

/**
 * A default configuration for GluonPlaybackEngineConfig.
 * @public
 */
export const defaultGluonPlaybackEngineLabsConfig: IGluonPlaybackEngineLabsConfig = {};
