// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { queryStringify } from '@wbd/beam-js-extensions';
import { BootstrapConfig, clientConfigDefaults } from '../bootstrap-config';
import type {
  IDefaultRequestOptions,
  IHttpError,
  IHttpModule,
  IHttpModuleInstance,
  IInterceptors,
  IRequestConfig,
  IRequestMethodConfig,
  IRequestRetryConfig,
  IResponse
} from '../http-internal';
import HttpModuleAxios from '../http-module-axios';
import { discoHeadersInterceptorFactory } from '../interceptors';
import { RefreshManager } from '../refresh-manager';
import type { ISessionConfig } from '../session-config';
import { SessionConfig } from '../session-config';
import type { IStorage } from '../session-state';
import { SessionState } from '../session-state';
import { exponentialDelay } from './exponentialDelay';

/**
 * The maximum number of retries before failing a request
 * @public
 */
export const REQUEST_MAX_RETRIES: number = 2;

/**
 * The maximum time before failing a request
 * @public
 */
export const REQUEST_MAX_TIMEOUT: number = 20_000;

/**
 * Returns the raw unmapped json api responses from Sonic CMS
 * @public
 */
export class BoltHttpClient {
  /**
   * Internal reference to the http module
   */
  public _module: IHttpModule;
  /**
   * Internal instance of http module
   */
  private _httpInstance: IHttpModuleInstance;

  /**
   * Bootstrap configuration management
   * Tracks bootstrap configuration changes and handles URL resolution
   */
  public readonly bootstrapConfig: BootstrapConfig | undefined;

  /**
   * HTTPClient config as passed on creation
   */
  public readonly sessionConfig: SessionConfig;

  /**
   * HTTPClient interceptors
   */
  public readonly interceptors: IInterceptors;

  /**
   * HTTPClient session state
   */
  public readonly sessionState: SessionState;

  public readonly refreshManager: RefreshManager;

  /**
   * Internal constructor to create the HTTP client
   * @param config - session configuration
   * @param requestConfig - optional default request configuration
   * @param retryConfig - optional default retry configuration
   *
   * @internal
   */
  private constructor(
    sessionConfig: ISessionConfig,
    requestOptions?: IDefaultRequestOptions,
    storage?: IStorage
  ) {
    this._module = HttpModuleAxios;
    this.sessionConfig = new SessionConfig(sessionConfig);
    this.sessionState = new SessionState();

    // Create default bolt-http request config
    const requestConfig: Partial<IRequestConfig> & IDefaultRequestOptions = {
      baseUrl: requestOptions?.baseUrl,
      paramsSerializer: (params) => {
        return params instanceof URLSearchParams
          ? params.toString()
          : queryStringify(params, { encode: false });
      },
      withCredentials: requestOptions?.withCredentials || false,
      requestAdapter: requestOptions?.requestAdapter,
      timeout: REQUEST_MAX_TIMEOUT
    };

    // Create default bolt-http retry config:
    // - retry on network timeout failures solely
    // - consumers can override the default retry behavior on a request by request basis
    // - future state: the expectation is that in the near future Headwaiter will return retry policy by endpoint
    const retryConfig: IRequestRetryConfig = {
      retries: REQUEST_MAX_RETRIES,
      retryCondition: (error) => this._module.isHttpTimeoutError(error),
      retryDelay: exponentialDelay
    };

    this._httpInstance = this._module.createHttpModule(requestConfig, retryConfig);
    this.interceptors = this._httpInstance.interceptors;

    // create default bootstrap config
    // for the targeted environment
    const defaultBootstrapConfig = clientConfigDefaults(
      this.sessionConfig.environment,
      this.sessionConfig.globalDomain
    );

    // init bootstrap config except if there is a baseUrl override
    // this allows clients to still connect to legacy D+ backend
    if (requestConfig?.baseUrl === undefined) {
      this.bootstrapConfig = new BootstrapConfig(
        defaultBootstrapConfig,
        storage,
        this.sessionConfig.environment
      );
    }

    this.refreshManager = new RefreshManager(this.bootstrapConfig, this.sessionState);

    this.sessionState.configureInterceptors(this.interceptors);
    this.refreshManager.configureInterceptors(this.interceptors);
  }

  /**
   * Create a new instance of HTTP client.
   * @param session - session configuration
   * @param requestOptions - optional request options
   * @returns
   */
  public static create(
    sessionConfig: ISessionConfig,
    requestOptions?: IDefaultRequestOptions,
    storage?: IStorage
  ): BoltHttpClient {
    const httpClient = new BoltHttpClient(sessionConfig, requestOptions, storage);
    httpClient.interceptors.request.use(discoHeadersInterceptorFactory(httpClient.sessionConfig));

    return httpClient;
  }

  /**
   * Internal helper to resolve the url route and build the final request config
   * @param options - request builder options
   * @returns resolved url and final request config
   */
  private _buildRequestConfig({
    url,
    config
  }: {
    url: string;
    config?: IRequestMethodConfig;
  }): [string, IRequestMethodConfig] {
    const resolvedUrl = this.bootstrapConfig?.resolveRequestUrl(url) ?? url;
    const bootstrapConfigFingerprint = this.bootstrapConfig?.getFingerprint();
    const requestConfig: Partial<IRequestConfig> = {
      ...config,
      bootstrapConfigFingerprint,
      originalRequestUrl: url
    };
    return [resolvedUrl, requestConfig];
  }

  /**
   * Execute a GET request
   * @param url - request url
   * @param config - request config
   * @returns
   */
  public async get<T, D = unknown, R = IResponse<T, D>>(
    url: string,
    config?: IRequestMethodConfig,
    retryConfig?: IRequestRetryConfig
  ): Promise<R> {
    await this.refreshManager.checkRefreshLock(config);
    const [resolvedUrl, requestConfig] = this._buildRequestConfig({ url, config });

    return this._httpInstance.get<T, R, D>(resolvedUrl, requestConfig, retryConfig);
  }

  /**
   * Execute a POST request
   * @param url - request url
   * @param data - request data payload
   * @param config - request config
   * @param retryConfig - retry config
   * @returns
   */
  public async post<T, D = unknown, R = IResponse<T, D>>(
    url: string,
    data?: D,
    config?: IRequestMethodConfig,
    retryConfig?: IRequestRetryConfig
  ): Promise<R> {
    await this.refreshManager.checkRefreshLock(config);
    const [resolvedUrl, requestConfig] = this._buildRequestConfig({ url, config });

    return this._httpInstance.post<T, R, D>(resolvedUrl, data, requestConfig, retryConfig);
  }

  /**
   * Execute a PATCH request
   * @param url - request url
   * @param data - request data payload
   * @param config - request config
   * @returns
   */
  public async patch<T, D = unknown, R = IResponse<T, D>>(
    url: string,
    data?: D,
    config?: IRequestMethodConfig,
    retryConfig?: IRequestRetryConfig
  ): Promise<R> {
    await this.refreshManager.checkRefreshLock(config);
    const [resolvedUrl, requestConfig] = this._buildRequestConfig({ url, config });

    return this._httpInstance.patch<T, R, D>(resolvedUrl, data, requestConfig, retryConfig);
  }

  /**
   * Execute a DELETE request
   * @param url - request url
   * @param config - request config
   * @returns
   */
  public async delete<T, D = unknown, R = IResponse<T, D>>(
    url: string,
    config?: IRequestMethodConfig,
    retryConfig?: IRequestRetryConfig
  ): Promise<R> {
    await this.refreshManager.checkRefreshLock(config);
    const [resolvedUrl, requestConfig] = this._buildRequestConfig({ url, config });

    return this._httpInstance.delete<T, R, D>(resolvedUrl, requestConfig, retryConfig);
  }

  /**
   * Execute a PUT request
   * @param url - request url
   * @param config - request config
   * @returns
   */
  public async put<T, D = unknown, R = IResponse<T, D>>(
    url: string,
    data?: D,
    config?: IRequestMethodConfig,
    retryConfig?: IRequestRetryConfig
  ): Promise<R> {
    await this.refreshManager.checkRefreshLock(config);
    const [resolvedUrl, requestConfig] = this._buildRequestConfig({ url, config });
    return this._httpInstance.put<T, R, D>(resolvedUrl, data, requestConfig, retryConfig);
  }
  /**
   * Checks if an error is HttpError
   *
   * @param error - error
   * @returns whether if the error is a HttpError error or not
   */
  public isHttpError(error: unknown): error is IHttpError {
    return this._module.isHttpError(error);
  }
  /**
   * Checks if an error is HttpTimeoutError
   *
   * @param error - error
   * @returns whether if the error is a IHttpError error or not
   */
  public isHttpTimeoutError(error: unknown): error is IHttpError {
    return this._module.isHttpTimeoutError(error);
  }
}
