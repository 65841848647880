// Copyright text placeholder, Warner Bros. Discovery, Inc.

// Design has a specific naming pattern for tokens (with dashes), which are used heavily in this file.
/* eslint-disable @typescript-eslint/naming-convention */

// ***DO NOT EDIT THIS FILE DIRECTLY***
// Generated with @wbd/generate-design-resources

/* eslint-disable @rushstack/typedef-var */

/**
 * ColorTokens derived from design data.
 * @public
 */
export const ColorToken = {
  action: {
    fill_focus: 0xfff5f5f7,
    fill_press: 0xffdddddd,
    label_focus: 0xff000000
  },
  background: {
    alt: {
      surface_00: 0xfff5f5f7,
      surface_01: 0xffdddddd,
      surface_01_glass: 0x1adddddd
    },
    base: {
      scrim_01: 0xb3000000,
      scrim_02: 0x66000000,
      surface_00: 0xff000000,
      surface_01: 0xff0f0f0f,
      surface_02: 0xff191919,
      surface_03: 0xff282828,
      surface_accent: 0xff07071c,
      surface_accent_01: 0xff0f1533
    }
  },
  badge: {
    fill_highlight_free: 0x26ffffff,
    fill_tile_free: 0xffffffff,
    outline_highlight_free: 0xffffffff,
    text_highlight_free: 0xffffffff,
    text_tile_free: 0xff000000
  },
  chip: {
    fill_selected: 0x00000000,
    outline_selected: 0x80ffffff,
    outline_unselected: 0x00000000
  },
  fill: {
    action_accent_dim: 0xe0171d31,
    action_accent_film: 0x99465897,
    action_accent_loud: 0xff2e55ff,
    action_accent_shade: 0xb302021e,
    action_default: 0x4d8697ce,
    action_focused: 0xfff5f5f7,
    action_fog: 0x99404459,
    indicator_advertisement: 0xfffaff11,
    indicator_live: 0xffba236c,
    indicator_loud_background: 0x808697ce,
    indicator_loud_foreground: 0xffffffff,
    indicator_quiet: 0xff4d5999,
    indicator_quiet_background: 0x33ffffff,
    indicator_quiet_foreground: 0xffcccccc,
    notify_error: 0x26ff4c4c,
    notify_message: 0x266a96ed,
    skeleton: 0x9936354d,
    skeleton_focused: 0xff36354d
  },
  foreground: {
    onalt: {
      text_01: 0xff000000,
      text_02: 0xcc000000,
      text_03: 0x99000000
    },
    onbase: {
      text_01: 0xffffffff,
      text_02: 0xccffffff,
      text_03: 0xa6ffffff,
      text_action_accent: 0xff4f8bff,
      text_notify_error: 0xffff6d64,
      text_notify_message: 0xffe9eefc
    }
  },
  indicator: {
    fill_background: 0x808697ce,
    fill_foreground_loud: 0xff2e55ff
  },
  input: {
    field: {
      fill_focus: 0xb302021e,
      text_focus: 0xffffffff
    },
    option: {
      fill_radio_selected: 0xff2e55ff,
      fill_selected: 0xff2e55ff,
      fill_selected_focus: 0xfff5f5f7,
      icon_status_selected_focus: 0xffffffff,
      outline_selected: 0xff2e55ff,
      outline_selected_focus: 0xfff5f5f7,
      outline_unselected: 0xfff5f5f7,
      outline_unselected_focus: 0xffffffff
    },
    outline_error: 0xffff6d64,
    toggle: {
      outline_selected: 0xff2e55ff,
      outline_unselected: 0x808697ce
    }
  },
  notify: {
    banner: {
      text_error: 0xffff6d64,
      text_message: 0xffe9eefc,
      text_onfocus: 0xff000000
    },
    fill_error_onfocus: 0x26ff4c4c,
    fill_message_onfocus: 0x266a96ed,
    icon_onfocus: 0xff000000,
    outline_error: 0x00000000,
    outline_error_onfocus: 0xffff6d64,
    outline_message: 0x00000000,
    outline_message_onfocus: 0xff4f8bff
  },
  stroke: {
    onbase: {
      action_default: 0x80ffffff,
      action_focused: 0xffffffff,
      outline_01: 0x4dffffff,
      outline_02: 0x26ffffff
    }
  },
  transparent: 0x00000000
} as const;
