// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { bootstrapApp as commonBootstrapApp, type IBootstrapAppOptions } from '@wbd/fuse-ctv-common';
import { data as maxResources } from '@wbd/fuse-ctv-resources-max';
import { data as hbomaxResources } from '@wbd/fuse-ctv-resources-hbomax';

export function bootstrapApp(options: IBootstrapAppOptions): void {
  const domainFragments = location.hostname.split('.');
  const resources = domainFragments.includes('play-hbo') ? hbomaxResources : maxResources;

  commonBootstrapApp({ ...options, resources });
}
