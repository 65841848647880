// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { AbstractAccount } from './packlets/account';
import { AbstractCommerce } from './packlets/commerce';
import { AbstractDevice } from './packlets/device';
import { AbstractInput } from './packlets/input';
import { AbstractStage } from './packlets/stage';
import { AbstractStorage } from './packlets/storage';
import { AbstractVideoCapabilities } from './packlets/video';
import type { IPlatformAdapter } from './IPlatformAdapter';

/**
 * Default (HTML5 browser) adapter implementation
 * @public
 */
export const defaultAdapter: IPlatformAdapter = {
  input: AbstractInput,
  storage: AbstractStorage,
  device: AbstractDevice,
  account: AbstractAccount,
  commerce: AbstractCommerce,
  stage: AbstractStage,
  video: AbstractVideoCapabilities
};
