// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  createGlobalizerContext,
  loadGlobalizerAsync
} from '@wbd/beam-ctv-localization/lib/packlets/globalizer';
import { loggerFactory } from '@wbd/beam-ctv-logger';
import type { IPlatformAdapter, IProfileInfo } from '@wbd/beam-ctv-platform-adapters';
import type { SequenceError } from '@wbd/beam-js-extensions';
import { createSequence, noop, type ISequence } from '@wbd/beam-js-extensions';
import {
  ConsentExperienceAlias,
  getBootstrap,
  getPaywallType,
  getProfiles,
  getUser,
  getUserEntitlements,
  loginSiteToken,
  logout,
  type User
} from '@wbd/bolt-dataservice';
import {
  BoltEnvironment,
  PlainSessionPayloadKey,
  RefreshSignal,
  type ClientId,
  type Platform
} from '@wbd/bolt-http';
import type { IFontConfiguration, IGlobalErrorContext, IInitialContext } from '../application';
import {
  generateErr,
  GlobalContextConnectionType,
  InstrumentationSDK,
  iPlatformConfigurationError,
  PlatformConfigurationErrorClassification,
  PlatformConfigurationErrorScope,
  type IPlatformConfigurationErrorErr
} from '../instrumentation';
import { getEnvironmentFromUrl } from './EnvironmentFromUrl';
import { bootstrapBolt } from './bootstrapBolt';
import { getBootstrapDeviceInformation } from './bootstrapDeviceInfo';
import { bootstrapSplash } from './bootstrapSplash';
import { getGeoblock } from './getGeoblock';
import { retryOnInvalidToken } from './retryOnInvalidToken';

import Resources, {
  getClientEnabledLanguageCodes,
  getFontScript,
  getLanguageCategory
} from '@wbd/fuse-ctv-resources-common';

// for tree-shaking
// eslint-disable-next-line @rushstack/packlets/mechanics
import { fetchDebugOverrides } from '../application/debug-settings/fetchDebugOverrides';
// eslint-disable-next-line @rushstack/packlets/mechanics
import { trackMilestone } from '../../milestones';
import { resolveDeeplinkTrackingCode } from './resolveDeeplinkTrackingCode';
import { validateDeviceInfo } from './utils/validateDeviceInfo';
import type { LanguageCode } from '@wbd/localization-core';
// eslint-disable-next-line @rushstack/packlets/mechanics
import { createLabsService } from '../application/feature-flags/createLabsService';
// eslint-disable-next-line @rushstack/packlets/mechanics
import { FeatureFlagService } from '../service/feature-flags';
import { loadUIFonts } from './loadUIFonts';
import type { ILocalizationService } from '@wbd/beam-ctv-localization';
import { getPaywallTypeFilters } from './getPaywallFilters';

export interface IBootstrapSetupOptions {
  platform: Platform;
  clientId: ClientId;
  platformAdapter: IPlatformAdapter;
  globalError: IGlobalErrorContext;
  appVersion: string;
  platformDisplayName: string;
  omdComponentName: string;
  onUserActivity?: (translation: ILocalizationService, profileInfo: IProfileInfo | undefined) => void;
  commercePartnerId?: string;
}

export function handleTargetConsentExperienceState(
  user: User,
  targetConsentExperience: ConsentExperienceAlias | undefined
): ConsentExperienceAlias | undefined {
  if (user) {
    if (user.migrationStatus === 'MIGRATED_WELCOMED') {
      // if current target experience is migrationRetain then clear to prevent reconsent
      if (targetConsentExperience === ConsentExperienceAlias.MIGRATION_RETAIN) {
        return undefined;
      }
    }

    if (user.anonymous && targetConsentExperience === ConsentExperienceAlias.MIGRATION_RETAIN) {
      // if user is anonymous and retain has failed then we trigger fallback to refresh
      return ConsentExperienceAlias.REFRESH;
    }
  }
  return targetConsentExperience;
}

export function handleRefresh(context: IInitialContext): (refreshSignals: RefreshSignal[]) => void {
  return (refreshSignals: RefreshSignal[]): void => {
    if (refreshSignals.includes(RefreshSignal.CONSENTS)) {
      context.hasPendingConsentRefresh = true;
    }
  };
}

type BootSequenceSteps =
  | 'Initial context'
  | 'Load device info'
  | 'Prepare Splash'
  | 'Save deeplink'
  | 'Bolt Environment'
  | 'Initialize local feature flags'
  | 'Bootstrap Bolt'
  | 'Initialize instrumentation'
  | 'Load locales'
  | 'Set font scripts'
  | 'Get client config'
  | 'Load user data'
  | 'Return context';

function createBootSequence(): ISequence<Partial<IInitialContext>, IInitialContext, BootSequenceSteps> {
  let context!: IInitialContext;
  let unsubscribeOnRefresh: VoidFunction | undefined;
  let debugOverride = fetchDebugOverrides();
  const bootstrapLogger = loggerFactory.createLogger('Bootstrap');

  trackMilestone('createBootSequence');

  return createSequence('Boot sequence')
    .pipe('Initial context', async (initialContext: Partial<IInitialContext>) => {
      context = initialContext as IInitialContext;
      context.appName = Resources.appName;
      debugOverride = fetchDebugOverrides(context.platformAdapter.storage);
      trackMilestone('Initial context');

      return context.platformAdapter;
    })
    .pipe('Load device info', async (adapter: IPlatformAdapter) => {
      const deviceBootInfo = await getBootstrapDeviceInformation({
        boltPlatformName: context.platform,
        device: adapter.device,
        video: adapter.video,
        storage: adapter.storage,
        platformDisplayName: context.platformDisplayName
      });

      // eslint-disable-next-line require-atomic-updates
      context.deviceBootInfo = await validateDeviceInfo(deviceBootInfo, adapter.storage);
      trackMilestone('Load device info');
      return adapter;
    })
    .pipe('Prepare Splash', async (adapter: IPlatformAdapter) => {
      bootstrapSplash(context);
      trackMilestone('Prepare Splash');
      return adapter;
    })
    .pipe('Save deeplink', async (adapter: IPlatformAdapter) => {
      const rawDeeplinkParams = await adapter.device.getDeeplinkParamsAsync();

      if (rawDeeplinkParams) {
        const { id, type } = rawDeeplinkParams;
        const trackingCode = resolveDeeplinkTrackingCode(rawDeeplinkParams);

        context.deeplinkParams = {
          id,
          type,
          trackingCode
        };
      }

      trackMilestone('Save deeplink');
    })
    .pipe('Initialize local feature flags', async () => {
      const featureFlagsEnabled: boolean =
        Boolean(context.platformAdapter.device.getURLParams().get('debug')) || debugOverride.isEnabled();

      const featureFlags = FeatureFlagService.create({
        isEnabled: featureFlagsEnabled,
        platformAdapter: context.platformAdapter
      });

      context.featureFlags = featureFlags;

      if (featureFlags.isEnabled('ClearStorage')) {
        featureFlags.disable('ClearStorage');
        await context.platformAdapter.storage.clearAsync();
      }

      const hotelSiteToken = context.platformAdapter.account.getHotelSiteToken();
      if (hotelSiteToken) {
        featureFlags.enable('Hospitality');
        await context.platformAdapter.storage.clearAsync();
      }

      trackMilestone('Initialize local feature flags');
    })
    .pipe('Bolt Environment', async () => {
      const defaultBoltEnvironment = BoltEnvironment.PRD;
      const overrideBoltEnvironment = getEnvironmentFromUrl(context.platformAdapter);

      context.boltEnvironment = debugOverride.isEnabled()
        ? debugOverride.environment()
        : overrideBoltEnvironment ?? defaultBoltEnvironment;
      trackMilestone('Bolt Environment');
    })
    .pipe('Bootstrap Bolt', async () => {
      const initialContext = context;
      const { httpClient, onRefreshSubscription } = await bootstrapBolt(context, handleRefresh);
      unsubscribeOnRefresh = onRefreshSubscription;

      initialContext.httpClient = httpClient;
      trackMilestone('Bootstrap Bolt');
      return httpClient;
    })
    .pipe('Initialize instrumentation', async (httpClient) => {
      const versionParts = httpClient.sessionConfig.applicationVersion.split('.');
      const deployedVersion = versionParts.slice(0, 3).join('.');
      const buildNumber = Number(versionParts[3]);
      const binaryVersion = context.deviceBootInfo.binaryVersion;

      InstrumentationSDK.updateGlobalContext({
        application: {
          deployedVersion,
          binaryVersion,
          buildNumber,
          omd: {
            component: context.omdComponentName
          }
        },
        // device: {
        // browser: {
        // name: BROWSER_NAME_HERE,
        // version: BROWSER_VERSION_HERE
        // }
        // },
        applicationState: {
          activeLanguage: 'en-US',
          device: {
            limitAdTracking: false
          },
          network: {
            connectionType: GlobalContextConnectionType.UNSPECIFIED
          },
          screen: {
            width: window.outerWidth,
            height: window.outerHeight
          }
        }
      });
      context.isInstrumentationReady = true;
      trackMilestone('Initialize instrumentation');
    })
    .pipe('Get client config', async () => {
      // call headwaiter to get fresh bootstrap config payload
      // this should be the first call to services on boot (after acquiring a valid token)
      try {
        await retryOnInvalidToken(() => getBootstrap());
      } catch (error) {
        // bootstrap failed to load but we can continue with defaults.
        // if we happen to have a stale payload services will tell us to reload it
        iPlatformConfigurationError.capture({
          err: generateErr<IPlatformConfigurationErrorErr>(error, {
            classification: PlatformConfigurationErrorClassification.HEADWAITER,
            scope: PlatformConfigurationErrorScope.RECOVERABLE
          })
        });
      }
      return { httpClient: context.httpClient };
    })
    .pipe('Load locales', async ({ httpClient }) => {
      const originalContext = context;
      // get language information from headwaiter payload
      const localizationPayload = httpClient.sessionState.getPayload(PlainSessionPayloadKey.Localization);
      // define wantLanguagesCodes and formatCode
      const wantLanguageCodes =
        localizationPayload?.selectedLanguages ?? httpClient.sessionConfig.devicePreferredLanguages;
      const formatCode = localizationPayload?.format;
      const languageCodeOverride = debugOverride.isEnabled() ? debugOverride.language() : undefined;

      // create globalizer localization instance
      const globalizeContext = createGlobalizerContext(
        getClientEnabledLanguageCodes(),
        wantLanguageCodes,
        formatCode,
        languageCodeOverride
      );
      originalContext.globalizer = await loadGlobalizerAsync(globalizeContext, Resources.loadLanguagePack);
      bootstrapLogger.info(
        `Localization initialized with language-code: ${globalizeContext.languageCode} format-code ${globalizeContext.formatCode}`
      );
      trackMilestone('Load locales');
      return globalizeContext.languageCode;
    })
    .pipe('Set font scripts', async (languageCode: LanguageCode) => {
      const content = context.httpClient.bootstrapConfig?.get()?.content;
      const scriptOverride = debugOverride.isEnabled() ? debugOverride.script() : undefined;

      const fontConfig: IFontConfiguration = {
        script: scriptOverride ?? getFontScript(content?.scripts ?? []),
        category: getLanguageCategory(languageCode)
      };
      context.fontConfiguration = fontConfig;
      bootstrapLogger.info('Font scripts:', content?.scripts, fontConfig);

      // load fonts in parallel, it will be awaited before startup
      fontConfig.pendingFonts = loadUIFonts(fontConfig.script);
    })
    .pipe('Login hospitality device', async () => {
      const hotelSiteToken = context.platformAdapter.account.getHotelSiteToken();

      if (hotelSiteToken) {
        // do a logout before any login to avoid login service issues
        // if app is exited aka closed unexpectedly
        await logout();

        const token = await loginSiteToken(hotelSiteToken);
        await context.httpClient.sessionConfig.authTokenProvider?.setTokenAsync(token);
      }
    })
    .pipe('Load user data', async () => {
      const initialContext = context;
      const paywallTypeFilters = getPaywallTypeFilters(
        initialContext.platformAdapter.commerce,
        initialContext.deviceBootInfo
      );

      async function loadUserData(): Promise<void> {
        const [user, currentPaywallType, profiles, entitlements, isRegionAllowed, labsService] =
          await Promise.all([
            getUser(),
            getPaywallType(paywallTypeFilters),
            getProfiles(),
            getUserEntitlements(),
            getGeoblock(),
            createLabsService(context.httpClient, context.deviceBootInfo, context.boltEnvironment)
          ]);

        initialContext.labsService = labsService;
        initialContext.user = user;
        initialContext.paywall = { type: currentPaywallType, filters: paywallTypeFilters };
        initialContext.profiles = profiles;
        initialContext.entitlements = entitlements;
        initialContext.isRegionAllowed = isRegionAllowed;
        initialContext.targetConsentExperience = handleTargetConsentExperienceState(
          user,
          initialContext.targetConsentExperience
        );
      }

      await retryOnInvalidToken(loadUserData);

      const iSDKConfig = initialContext.labsService?.getFlagConfig('config_isdk');
      if (iSDKConfig) {
        InstrumentationSDK.updateConfiguration(iSDKConfig);
      }

      if (!initialContext.user.anonymous) {
        initialContext.platformAdapter.account.trackSilentSignIn();
      }

      // load an initial labs response to serve the SDKs
      initialContext.labsService?.updateLabs().catch(noop);

      if (initialContext.labsService?.isFlagEnabled('Reconsent_From_Session_State')) {
        const consentsPayload = initialContext.httpClient.sessionState.getPayload(
          PlainSessionPayloadKey.Consents
        );
        if (consentsPayload?.reconsent) {
          initialContext.targetConsentExperience = ConsentExperienceAlias.REFRESH;
        }
      } else if (initialContext.hasPendingConsentRefresh) {
        //set to default target experience if refresh signal has triggerd in handleRefresh
        initialContext.targetConsentExperience = ConsentExperienceAlias.REFRESH;
      }

      trackMilestone('Load user data');
    })
    .pipe('Return context', async () => {
      unsubscribeOnRefresh?.();
      return context;
    });
}

export async function setup({
  platform,
  clientId,
  platformAdapter,
  globalError,
  appVersion,
  platformDisplayName,
  omdComponentName,
  onUserActivity,
  commercePartnerId
}: IBootstrapSetupOptions): Promise<Partial<IInitialContext>> {
  trackMilestone('Setup');
  const sequence = createBootSequence();
  const context: Partial<IInitialContext> = {
    platform,
    clientId,
    platformAdapter,
    globalError,
    appVersion,
    hasPendingConsentRefresh: false,
    targetConsentExperience: undefined,
    platformDisplayName,
    omdComponentName,
    onUserActivity,
    commercePartnerId
  };

  return sequence.start(context).catch((startError: SequenceError) => {
    context.startupError = startError;
    return context;
  });
}
