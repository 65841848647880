// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { CMS_DECORATORS } from '../http-client';

/**
 * Get the CMS params.
 * @param params - The params.
 * @param applyDecorators - Apply the default decorators.
 * @public
 */
export const getCMSParams = (params = {}, applyDecorators = true): object => ({
  include: 'default',
  ...(applyDecorators && { decorators: CMS_DECORATORS }),
  ...params
});
