// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IStorage } from '@wbd/beam-ctv-platform-adapters';
import { BoltEnvironment } from '@wbd/bolt-http';
import type { TFontScript } from '@wbd/fuse-ctv-resources-common';
import type { LanguageCode } from '@wbd/localization-core';
import type { Accessor } from 'solid-js';

export function fetchDebugOverrides(storage?: IStorage): {
  isEnabled: Accessor<boolean>;
  environment: Accessor<BoltEnvironment>;
  language: Accessor<LanguageCode>;
  script: Accessor<TFontScript>;
} {
  const overrides = storage?.readSync('debugOverrides');
  if (overrides) {
    const debugOverrides = JSON.parse(overrides);

    return {
      isEnabled: () => debugOverrides.isEnabled,
      environment: () => debugOverrides.environment,
      language: () => debugOverrides.language,
      script: () => getScriptFromLanguage(debugOverrides.language)
    };
  }

  return {
    isEnabled: () => false,
    environment: () => BoltEnvironment.PRD,
    language: () => 'en-US' as LanguageCode,
    script: () => 'Latn'
  };
}

function getScriptFromLanguage(language?: string): TFontScript {
  if (!language) return 'Latn';
  if (language.startsWith('th-')) return 'Thai';
  if (language.startsWith('zh-Hans')) return 'Hans';
  if (language === 'zh-Hant-HK') return 'Hant-HK';
  if (language.startsWith('zh-Hant')) return 'Hant-TW';
  return 'Latn';
}
