// Copyright text placeholder, Warner Bros. Discovery, Inc.

// Design has a specific naming pattern for tokens (with dashes), which are used heavily in this file.
/* eslint-disable @typescript-eslint/naming-convention */

// ***DO NOT EDIT THIS FILE DIRECTLY***
// Generated with @wbd/generate-design-resources

/* eslint-disable @rushstack/typedef-var */

/**
 * Text tokens derived from design data
 * @public
 */
export const TextToken = {
  body: {
    lg: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 33,
      fontStyle: '400',
      lineHeight: 50.5
    },
    lg_strong: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 33,
      fontStyle: '700',
      lineHeight: 50.5
    },
    md: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 28,
      fontStyle: '400',
      lineHeight: 43
    },
    md_strong: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 28,
      fontStyle: '700',
      lineHeight: 43
    },
    sm: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 25,
      fontStyle: '400',
      lineHeight: 38.5
    },
    sm_strong: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 25,
      fontStyle: '700',
      lineHeight: 38.5
    },
    xl: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 41,
      fontStyle: '400',
      lineHeight: 62.5
    },
    xl_strong: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 41,
      fontStyle: '700',
      lineHeight: 62.5
    }
  },
  heading: {
    lg: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 54,
      fontStyle: '700',
      lineHeight: 71.2
    },
    md: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 40,
      fontStyle: '700',
      lineHeight: 53
    },
    sm: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 34,
      fontStyle: '700',
      lineHeight: 45.2
    },
    xl: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 63,
      fontStyle: '700',
      lineHeight: 82.9
    },
    xs: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 28,
      fontStyle: '700',
      lineHeight: 37.4
    }
  },
  misc: {
    badge: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 22,
      fontStyle: '700',
      lineHeight: 29.6
    },
    badge_sm: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 22,
      fontStyle: '700',
      lineHeight: 29.6
    },
    caps: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 25,
      fontStyle: '400',
      lineHeight: 33.5
    },
    caps_strong: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 25,
      fontStyle: '700',
      lineHeight: 33.5
    },
    code: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 25,
      fontStyle: '400',
      lineHeight: 38.5
    },
    label_md: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 28,
      fontStyle: '400',
      lineHeight: 43
    },
    label_md_strong: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 28,
      fontStyle: '700',
      lineHeight: 43
    },
    label_sm: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 25,
      fontStyle: '400',
      lineHeight: 33.5
    },
    label_sm_strong: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 25,
      fontStyle: '700',
      lineHeight: 33.5
    },
    metadata_lg: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 28,
      fontStyle: '400',
      lineHeight: 43
    },
    metadata_lg_strong: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 28,
      fontStyle: '700',
      lineHeight: 43
    },
    metadata_md: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 25,
      fontStyle: '400',
      lineHeight: 38.5
    },
    metadata_md_strong: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 25,
      fontStyle: '700',
      lineHeight: 38.5
    },
    metadata_sm: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 23,
      fontStyle: '400',
      lineHeight: 30.9
    },
    metadata_sm_strong: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 23,
      fontStyle: '700',
      lineHeight: 30.9
    },
    timecode: {
      textBaseline: 'bottom',
      cutSy: -3,
      cutEy: 99999,
      fontSize: 28,
      fontStyle: '400',
      lineHeight: 43
    }
  }
} as const;
