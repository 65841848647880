// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Enum containing the possible session modifying actions for an event.
 * @public
 */
export enum SessionUpdate {
  EXTEND = 'EXTEND',
  CONTINUE = 'CONTINUE',
  NOT_APPLICABLE = 'NOT_APPLICABLE'
}
