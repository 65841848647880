// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ICommerce } from '@wbd/beam-ctv-platform-adapters';
import { SubscriptionFlow } from '@wbd/beam-ctv-platform-adapters';
import type { IPaywallTypeParameters } from '@wbd/bolt-dataservice';
import type { IPlatformDeviceInfo } from '../service';

export function getPaywallTypeFilters(
  commerce: ICommerce,
  deviceBootInfo: IPlatformDeviceInfo
): IPaywallTypeParameters {
  const subscriptionFlow = commerce.getSubscriptionFlow();
  let planProvider: string | undefined;
  let storeFrontCountryCode: string | undefined;

  if (subscriptionFlow === SubscriptionFlow.IAP) {
    planProvider = commerce.getPaymentProvider();
    storeFrontCountryCode = deviceBootInfo.storeCountryCode ? deviceBootInfo.storeCountryCode : undefined;
  }

  return {
    planProvider,
    storeFrontCountryCode
  };
}
