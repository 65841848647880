// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ILabsResponse, ILabsSDKOptions, TLabsResponse } from '../types';

export const labsURL: string = '/labs/api/v1/sessions/feature-flags/decisions';

/**
 * By providing a project Id (and tag) it will return a list of feature flags filtered by project Id and tag.
 * @public
 * @param boltHttp - BoltHttpClient instance
 * @param projectId - Project Id
 * @param requestOptions - Request options
 * @returns - Response from labs service
 *
 * @see https://wbdstreaming.atlassian.net/wiki/spaces/EX/pages/241008641/Authenticated+Decision+s+API+for+Beam+Clients
 */
export async function labsRequest<T = Record<string, unknown>, R extends ILabsResponse = TLabsResponse<T>>({
  boltHttp,
  projectId,
  requestOptions
}: ILabsSDKOptions<T>): Promise<Partial<R>> {
  const response = requestOptions?.context
    ? await boltHttp.post<R>(labsURL, { projectId, ...requestOptions })
    : await boltHttp.get<R>(labsURL, {
        params: { projectId, ...requestOptions }
      });

  return response.data;
}
