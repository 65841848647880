// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IProviderPaymentInfo } from '@wbd/bolt-dataservice';

/**
 * Subscription flow types
 * @public
 */
export enum SubscriptionFlow {
  /**
   * DTC flow
   */
  DTC = 'DTC',
  /**
   * IAP flow
   */
  IAP = 'IAP',
  /**
   * MVPD flow
   */
  MVPD = 'MVPD'
}

/**
 * Supported Payment providers, this will be used later on on the subscription-journey/start service
 * @public
 */
export enum PaymentProvider {
  /**
   * Amazon
   */
  Amazon = 'Amazon',
  /**
   * Apple
   */
  Apple = 'Apple',
  /**
   * Google
   */
  Google = 'Google',
  /**
   * Roku
   */
  Roku = 'Roku',
  /**
   * Samsung
   */
  Samsung = 'Samsung',
  /**
   * Vizio
   */
  Vizio = 'Vizio'
}

/**
 * Purchase plan data needed to provide to the IAP or MVPD flows
 * @public
 */
export interface IPurchasePlan {
  /**
   * Plan sku/id
   */
  id: string;
  /**
   * Plan title
   */
  title?: string;
  /**
   * Plan current price
   */
  currentPrice?: string | number;
  /**
   * Plan currency code like USD
   */
  currencyCode?: string;
  /**
   * Plan old price
   */
  originalPrice?: number;
}

/**
 * Purchase store receipt needed to create a subscription plan for the IAP flow.
 * @public
 */
export interface IStoreReceipt {
  /**
   * Fixed name required and defined by backend(Samsung, Amazon, etc.)
   */
  provider: string;
  /**
   * The invoice id retrieved from provider(Samsung, etc.) Checkout
   */
  token: string;
  /**
   * The id that identifies the user in the platform
   */
  userId: string;
  /**
   * A country code in ISO 3166-1 alpha-2 format identifying the country from the TV.
   */
  countryCode: string;
  /**
   * A price plan which refers to the productId of the product which the user purchased.
   */
  pricePlan: string;
  /**
   * application id in which the purchase was made
   */
  appId: string;
}

/**
 * Change Plan receipt.
 * @public
 */
export interface IChangePlanReceipt {
  /**
   * 	Mandatory: The subscriptions provider name
   */
  provider: string;
  /**
   * 	Mandatory: The new purchase token/receipt from the provider store. If the provider does not provide a new token, instead pass in the old one.
   */
  token: string;
  /**
   * 	Mandatory: Two letters indicating which country the store belongs to
   */
  storeFrontCountryCode: string;
  /**
   * 	App id/package name, e.g. com.hbo.hbonow or com.wbd.stream
   */
  appId: string;
  /**
   * 	User identity on provider side
   */
  vendorUserId: string;
  /**
   * In-App Store id
   */
  inAppStoreId: string;
}

/**
 * Unlinked receipt payload for the restore purchase feature.
 * @public
 */
export interface IUnlinkedReceipt {
  /**
   * Plan ID
   */
  planId: string;
  /**
   * Receipt information
   */
  receipt: IProviderPaymentInfo;
}

/**
 * Payload provided for the subscription MVDP flows
 * @public
 */
export interface ISubscriptionPayloadMVPD {
  /**
   * Purchase plan data
   */
  plan: IPurchasePlan;
  /**
   * redirect url to be used as deep link to get back to the app, not all providers support this option
   */
  redirectUrl?: string;
}

/**
 * Payload provided for the IAP change plan flow.
 * @public
 */
export interface ICreateChangePlanRequestPayload {
  /**
   * SKU of the product to be changed to.
   */
  productSku: string;
  /**
   * The global subscription ID to be changed.
   */
  subscriptionId: string;
  /**
   * The Plan ID to be changed to.
   */
  planId: string;
}

/**
 * Success response for the createChangePlanRequest method.
 * @public
 */
export interface ICreateChangePlanRequestSuccess {
  /**
   * Success status
   */
  status: 'success';
  /**
   * Function to execute the change plan request.
   */
  commitTransaction: () => Promise<IChangePlanReceipt>;
  /**
   * The date when the change plan will be effective.
   */
  startDate?: string;
}

/**
 * Error codes for the createChangePlanRequest method when the transition is not allowed.
 * @public
 */
export interface ICreateChangePlanRequestFailure {
  /**
   * Error status
   */
  status: 'error';
  /**
   * Error code
   */
  error: string;
}

/**
 * Response for the createChangePlanRequest method.
 * @public
 */
export type ICreateChangePlanRequestResult =
  | ICreateChangePlanRequestSuccess
  | ICreateChangePlanRequestFailure;
