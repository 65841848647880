// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Maps a list of desired languages following the CLDR locale format (language[-Script][-REGION])
 * onto one of a list of supported languages, applying lookup logic and an optional default language.
 *
 * Inspiration for lookup:
 * https://www.rfc-editor.org/rfc/rfc4647#section-3.4
 *
 * Searches the list of desired languages in order and returns the first
 * matching mapped language it finds.
 *
 * If no desired language matches and cannot be looked up, returns the default provided.
 *
 * @param wantLanguages - Desired languages
 * @param haveLanguages - Available languages
 * @param defaultLanguage - fallback language
 *
 * @public
 */
export function selectLanguage<T extends string>(
  wantLanguages: string[],
  haveLanguages: T[],
  defaultLanguage: T
): T {
  const tryLanguages = wantLanguages.reduce((acc, language) => {
    acc.push(...expandLanguage(language));
    return acc;
  }, [] as string[]);

  for (const language of tryLanguages) {
    if (haveLanguages.includes(language as T)) {
      return language as T;
    }
  }

  return defaultLanguage;
}

/**
 * Expand a locale code for lookup
 * Ex: 'zh-Hans-HK' to ['zh-Hans-HK', 'zh-Hans', 'zh']
 * @param language - Input language code
 * @returns a list of lookups for comparison
 *
 * @public
 */
export function expandLanguage(language: string): string[] {
  const languages = [language];
  const subtags = language.split('-');
  while (subtags.length > 1) {
    subtags.pop();
    languages.push(subtags.join('-'));
  }
  return languages;
}
