// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { Profile } from '../../data-models';
import { TransformerLight } from '../../data-transformer';
import type { RequestConfigOverride } from '../../http-client';
import type { DataTypes } from '../../json-api';
import type { ITypeMap } from '../ITypeMap';
import { getProfilesRequest } from './getProfilesRequest';

/**
 * Returns the user's profiles.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getProfiles = async (config?: RequestConfigOverride): Promise<Profile[]> => {
  const document = await getProfilesRequest(config);

  const store = new TransformerLight<DataTypes.Profile[], ITypeMap>(document);
  return store.data;
};
