// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * @public
 */
export enum CMSRequestType {
  CONFIGS = 'configs',
  COLLECTIONS = 'collections',
  RECOMMENDATIONS = 'recommendations',
  ROUTES = 'routes'
}
