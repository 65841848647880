// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { UserEntitlementsSummary } from '../../data-models';
import { TransformerLight } from '../../data-transformer';
import type { RequestConfigOverride } from '../../http-client';
import { DataTypes } from '../../json-api';
import { getUserEntitlementsRequest } from './getUserEntitlementsRequest';
import type { ITypeMap } from '../ITypeMap';

/**
 * Returns a high level summary of the customer entitlements.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getUserEntitlements = async (
  config?: RequestConfigOverride
): Promise<UserEntitlementsSummary> => {
  const sonicEntitlements = await getUserEntitlementsRequest(config);
  const store = new TransformerLight<DataTypes.UserEntitlementsSummary, ITypeMap>(sonicEntitlements);

  return store.findAll(DataTypes.UserEntitlementsSummary)[0];
};
