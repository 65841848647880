// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { AuthToken, IResponse } from '@wbd/bolt-http';

import type { RequestConfigOverride } from '../http-client';
import { HttpClient } from '../http-client';
import type { ILegacyTokenLogin } from './interfaces/ILegacyTokenLogin';
import type { ITokenAuthenticationData } from './interfaces/ITokenAuthenticationData';
import type { IMigrationCredentials } from './interfaces/IMigrationCredentials';

/**
 * Returns a migrated user token based on the legacy token.
 * @param legacyToken - The legacy token.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getMigratedToken = async (
  migrationCredentials: IMigrationCredentials,
  config?: RequestConfigOverride
): Promise<AuthToken | undefined> => {
  const http = HttpClient.instance;
  const response = await http.post<IResponse<ITokenAuthenticationData>, ILegacyTokenLogin>(
    '/login',
    {
      credentials: {
        provider: 'migration',
        ...migrationCredentials
      }
    },
    config
  );

  const resource = response.data?.data;
  const token = resource?.attributes?.token;

  await http.sessionConfig.authTokenProvider?.setTokenAsync(token);

  return token;
};
