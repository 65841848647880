// Copyright text placeholder, Warner Bros. Discovery, Inc.

import './main.css';

import { defaultAdapter, type IPlatformAdapter } from '@wbd/beam-ctv-platform-adapters';
import { createDesktopAdapter } from '@wbd/fuse-ctv-common';
import { ClientId, Platform } from '@wbd/bolt-http';
import manifest from './generated/app-manifest.json';
import { bootstrapApp } from './bootstrapApp';

const desktopAdapter: IPlatformAdapter = createDesktopAdapter(defaultAdapter);

bootstrapApp({
  platform: Platform.LGTV,
  clientId: ClientId.WEBOS,
  platformAdapter: desktopAdapter,
  appVersion: manifest.appVersion
});
