// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { Platform } from '@wbd/bolt-http';
import type { IBootstrapAppOptions } from './bootstrapAppAsync';

export async function resolvePlatformValues(options: IBootstrapAppOptions): Promise<{
  platform: Platform;
  commercePartnerId: string | undefined;
}> {
  const resolvedPlatform: Platform =
    typeof options.platform === 'function' ? await options.platform() : options.platform;

  const resolvedCommercePartnerId =
    typeof options.commercePartnerId === 'function'
      ? options.commercePartnerId(resolvedPlatform)
      : options.commercePartnerId;

  return {
    platform: resolvedPlatform,
    commercePartnerId: resolvedCommercePartnerId
  };
}
