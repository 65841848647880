// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IPaywallType } from '../data-models';
import { TransformerLight } from '../data-transformer';
import type { RequestConfigOverride } from '../http-client';
import { DataTypes } from '../json-api';
import { getPaywallTypeRequest } from './getPaywallTypeRequest';
import type { IPaywallTypeParameters } from './IPaywallTypeParameters';
import type { ITypeMap } from './ITypeMap';

/**
 * Fetches paywall type which should be shown to the user, or 'NONE' if no paywall should be shown.
 * @param paywallTypeParameters - Optional parameters to filter the request
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getPaywallType = async (
  paywallTypeParameters: IPaywallTypeParameters = {},
  config?: RequestConfigOverride
): Promise<IPaywallType> => {
  const response = await getPaywallTypeRequest(paywallTypeParameters, config);
  const store = new TransformerLight<DataTypes.PaywallType, ITypeMap>(response);

  return store.findAll(DataTypes.PaywallType)[0];
};
