// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ConsentExperience, ConsentExperienceAlias } from '../data-models';
import { ConsentExperienceDecorator } from '../data-models';
import { TransformerLight } from '../data-transformer';
import type { RequestConfigOverride } from '../http-client';
import { HttpClient } from '../http-client';
import type { ITypedJsonApiDocumentFromModel } from '../json-api';
import { DataTypes } from '../json-api';

/**
 * @public
 * Legal consent experience url
 */
export const CONSENT_EXPERIENCE_URL: string = '/legal/v2/consentExperience';

/**
 * @public
 */
export type IConsentExperienceResponse = ITypedJsonApiDocumentFromModel<
  DataTypes.ConsentExperience,
  ConsentExperience
>;

interface ITypeMap extends Record<DataTypes, unknown> {
  [DataTypes.ConsentExperience]: ConsentExperience;
}

/**
 * @public
 * NCIS service to retrieve the consent experience data
 * @param consentExperienceAlias - Consent experience alias
 * @param decorators - Consent experience decorators to get optional properties eg. richtexthtml
 * @param config - The request config used to pass in properties like an abort signal.
 * @returns consent experience
 */
export const getConsentExperience = async (
  consentExperienceAlias: ConsentExperienceAlias,
  decorators?: ConsentExperienceDecorator[],
  config?: RequestConfigOverride
): Promise<ConsentExperience> => {
  const response = await HttpClient.instance.get<IConsentExperienceResponse>(CONSENT_EXPERIENCE_URL, {
    params: {
      alias: consentExperienceAlias,
      decorators: decorators ? decorators.join(',') : [ConsentExperienceDecorator.PLAIN_TEXT]
    },
    signal: config?.signal
  });

  const consentExperience = response.data;

  const store = new TransformerLight<DataTypes.ConsentExperience, ITypeMap>(consentExperience);

  return store.find(DataTypes.ConsentExperience, consentExperience.data.id)!;
};
