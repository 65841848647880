// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { Configuration, Emitter, IInstrumentationSDKConfig } from '../core';
import { IGlobalContextProps } from '../generated';
import { EventQueue, EventQueueMonitor, EventTransmitter } from '../transmission';
import { GlobalContextManager } from './GlobalContextManager';
import { HeartbeatMonitor } from './HeartbeatMonitor';
import { PerformanceManager } from './performance-manager';
import { RequestMonitor } from './request-monitor';

/**
 * Manager that is responsible for coordinating between multiple managers and monitors. It's a hard job, but
 * someone needs to do it.
 * @public
 */
export class Coordinater {
  private static _instrumentationConfig: IInstrumentationSDKConfig | undefined;
  /**
   * Connects and initializes multiple managers and monitors together
   * @param globalContext - optional global context to be used by the SDK
   * @param instrumentationConfig - configuration used by the Instrumentation SDK to wire up data transmission and heartbeat events
   */
  public static initialize(
    instrumentationConfig: IInstrumentationSDKConfig,
    globalContextProps?: IGlobalContextProps
  ): void {
    const configuration = new Configuration(instrumentationConfig);
    this._instrumentationConfig = configuration;

    Emitter.setContextProvider(GlobalContextManager.getGlobalContextAsync.bind(GlobalContextManager));
    GlobalContextManager.initialize(globalContextProps);
    Emitter.initialize(configuration);

    if (!configuration.emitOnly) {
      Emitter.addEventWithContextListener(EventQueue.enqueueEvent.bind(EventQueue));
      EventQueueMonitor.onQueueDisableChanged(this.setDisableState);
      HeartbeatMonitor.initialize(configuration);
      RequestMonitor.initialize(configuration);
      EventQueue.initialize(configuration);
      EventQueueMonitor.initialize(configuration);
      EventTransmitter.initialize(configuration);
      PerformanceManager.initialize();
    }
  }

  /**
   * updates the configuration provided to the SDK
   *
   * @param configuration - configuration used by the Instrumentation SDK to wire up data transmission and heartbeat events.
   * This will omit eventStorage as it should only be set during the initialize method. Storage is meant to be
   * immutable once set.
   */
  public static updateConfiguration(
    instrumentationConfig: Omit<IInstrumentationSDKConfig, 'eventStorage' | 'emitOnly'>
  ): void {
    // create a new configuration using the new data, defaulting anything that we were not provided
    const configuration = new Configuration({
      ...this._instrumentationConfig,
      ...instrumentationConfig
    });
    this._instrumentationConfig = configuration;

    Emitter.initialize(configuration);

    if (!configuration.emitOnly) {
      // send the new data to all our managers
      HeartbeatMonitor.update(configuration);
      RequestMonitor.update(configuration);
      EventQueue.initialize(configuration);
      EventQueueMonitor.initialize(configuration);
      EventTransmitter.initialize(configuration);
    }
  }

  /**
   * Coordinates the disable state across multiple managers and monitors together
   * Note: This should only be used for manually-disabled and failure-disabled scenarios.
   * @param isDisabled - boolean indicating whether or not the library should be disabled
   */
  public static setDisableState(isDisabled: boolean): void {
    if (isDisabled) {
      this._disable();
    } else {
      this._enable();
    }
  }

  /**
   * Coordinates enabling the ISDK across multiple managers and monitors together
   */
  private static _enable(): void {
    HeartbeatMonitor.Heartbeat.enable();
  }

  /**
   * Coordinates disabling the ISDK across multiple managers and monitors together
   */
  private static _disable(): void {
    HeartbeatMonitor.Heartbeat.disable();
  }
}
