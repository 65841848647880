// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Local labs decisions type.
 * @public
 */
export enum DecisionType {
  /** Allows local decisions to be merged with server decisions. */
  DYNAMIC = 'dynamic',
  /** Allows local decisions to take priority over server decisions. */
  STATIC = 'static'
}

/**
 * Response format of a labs decisions.
 * @public
 */
export interface ILabsDecisionData<T = unknown> {
  /** variant configuration */
  config?: T;
  /** Unique identified of decision. */
  flagId?: string;
  /** Variant title. */
  label?: string;
  /** Generated seed ID */
  seedId?: string;
  /** enabled state */
  on: boolean;
  /** Source of the decision (for tracking). */
  source?: string;
  /**
   * decision type - local decisions only
   *   - static: local decision only, ignore server decisions matching the same key
   *   - dynamic: server decision takes priority, local decision is used as fallback
   * @defaultValue 'DecisionType.STATIC'
   */
  type: `${DecisionType}`;
  /** Current treatment id - i.e. what variant of an experiment this decision belongs to. */
  variantId?: string;
}

/**
 * Configuration validator
 * @public
 */
export interface ILabsDecisionValidator<T> {
  validConfig: T;
  isValid: (value: unknown) => value is T;
}

// Need to allow function signature style for the following interface' overloading
/* eslint-disable @typescript-eslint/method-signature-style */

/**
 * Decision accessor
 * @public
 */
export interface ILabsDecision<T> {
  /** Determine if the decision is enabled. */
  isEnabled: () => boolean;
  /** Get the current decision configuration, without validator. */
  getConfig(): T | undefined;
  /** Get the current decision configuration, with validation. */
  getConfig(validator: ILabsDecisionValidator<T>): T;
}
