// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { Emitter } from './Emitter';
import { IVersionedEvent } from './IVersionedEvent';
import { nextSessionOrder } from './SessionOrder';
import { SessionUpdate } from './SessionUpdate';

/**
 * Structure that defines the public facing methods for emitting and listening to a given event
 * @public
 */
export class BaseEvent<TEventPayload = void> {
  /** A default fallback event type value, should be overwritten by the extended class */
  protected eventType: string = 'beam.events.base';
  /** A default session modifier value, should be overwritten by the extended class **/
  protected sessionUpdate: SessionUpdate = SessionUpdate.NOT_APPLICABLE;

  /** Builds the event, Events extending from this event are expected to add the eventType info within their extended build function */
  protected _build(payload: TEventPayload): IVersionedEvent<TEventPayload> {
    return {
      sessionUpdate: this.sessionUpdate,
      eventType: this.eventType,
      deviceOccurredAt: Date.now(),
      sessionOrder: nextSessionOrder(),
      payload: payload
    };
  }

  /**
   * method to listen to an event
   * @param callback - callback function to be called when the event is emitted
   */
  public addEventListener(callback: (event: IVersionedEvent<TEventPayload>) => void): void {
    Emitter.addEventTypeListener<TEventPayload>(this.eventType, callback);
  }

  /** method to emit an event */
  public emit(payload: TEventPayload, deviceOccuredAt?: number): void {
    // build this event
    const event = this._build(payload);
    event.deviceOccurredAt = deviceOccuredAt || event.deviceOccurredAt;
    this._emit(event);
  }

  /**
   * emit out the event to any bound listeners on the emitter
   * @param event - the event to be emitted
   */
  private _emit(event: IVersionedEvent<TEventPayload>): void {
    Emitter.emitEventAsync(event).catch(() => {});
  }
}
