// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { FormatCode, LanguageBasedFormatCode, LanguageCode } from '@wbd/localization-core';
import { deriveLanguageBasedFormatCode } from './deriveLanguageBasedFormatCode';

/**
 * Optional constructor options for `GlobalizationContext`
 * @public
 */
export interface IGlobalizationContextOptions {
  /**
   * When enabled uses the `HybridLocaleFormatter` to improve language consistency 
   * for a selected number of (unit / date) formatters that include words.
   * 
   * The globalization context sets the `languageBasedFormatCode` to the`languageCode`, 
   * if the primary language of the `formatCode` does not match the primary language of the `formatCode`.
   *  
   * @defaultValue false
   * 
   * @example
   * English language / US registered user
   * ```
   * GIVEN
   *  enableHybridLocaleFormatter: true OR false
   *  AND languageCode: en-US and formatCode en-US
   * RESULT
   *  regionBasedFormatCode: en-US and languageBasedFormatCode: en-US
   * OUTPUT
   *  Dec 22, 2029
   *  1/16/2024
   *  1 sec
   * ```

   * @example
   * Variant 1 - English language / BR registered user (hybrid locale formatter disabled)
   * ```
   * GIVEN
   *  enableHybridLocaleFormatter: false
   *  AND languageCode: en-US and formatCode pt-BR
   * RESULT
   *  regionBasedFormatCode: pt-BR and languageBasedFormatCode: pt-BR
   * OUTPUT
   *  22 de dez. de 2029
   *  16/01/2024
   *  1 seg
   * ```
   * 
   * @example
   * Variant 2 - English language / BR registered user (hybrid locale formatter enabled)
   * ```
   * GIVEN
   *  enableHybridLocaleFormatter: true
   *  AND languageCode: en-US and formatCode pt-BR
   * RESULT
   *  regionBasedFormatCode: pt-BR and languageBasedFormatCode: en-US
   * OUTPUT
   *  Dec 22, 2029  <-- word units: using en-US format
   *  16/01/2024    <-- no units: using pt-BR format
   *  1 sec         <-- word units: using en-US format
   * ```
   */
  enableHybridLocaleFormatter?: boolean;
}

/**
 * A context for localization, consisting of a language code, a region-based format code, and a language-based
 * format code.
 *
 * Determines the target UI language that resources should be translated into, the formatting rules for formatting
 * data into formats containing words (to ensure a single-language UI), and the formatting rules for formatting
 * data that needs to be formatted in region-specific ways (such as currency and purely numeric date formats).
 *
 * @public
 */
export class GlobalizationContext {
  private readonly _languageCode: LanguageCode;
  private readonly _regionBasedFormatCode: FormatCode;
  private readonly _languageBasedFormatCode: LanguageBasedFormatCode;

  /**
   * Constructs a GlobalizationContext from the given language code and format code.
   *
   * The language code and format code must comply with all relevant constraints
   * (see {@link https://wbdstreaming.atlassian.net/wiki/spaces/GCX/pages/52199934/Language+Codes+and+Format+Codes})
   *
   * The format code will be used for formatting all of the data into formats that do not
   * contain words. For example, purely numeric dates (e.g. "04/27/2021"), currencies (e.g. "$19.99"),
   * and numbers (e.g. "1,234.56").
   *
   * If the format code and the language code have different primary language subtags, we don't want
   * to format data into formats that contain words using the format code, as this will create a
   * mixed-language, or even mixed-script, UI. In this case, we will attempt to choose a format code
   * which has the same primary language subtag as the language code, but which is a `FormatCode`, and
   * thus can be used for formatting data. To accomplish this:
   *   1) If the language code is a FormatCode, we use the language code.
   *   2) If the language code is not a FormatCode, but is one for which we have reasonable CLDR formatting
   *      rules (such as es-419), and for which we have coded special support in `localization-formats`,
   *      we use the language code.
   *
   * If all of the above fail, we use the format code for formatting data into formats containing words.
   *
   * @param languageCode - the language code defining the target language for translation
   * @param formatCode - the format code defining the rules to use for formatting data
   */
  public constructor(
    languageCode: LanguageCode,
    formatCode: FormatCode,
    options?: IGlobalizationContextOptions
  );

  /**
   * Constructs a GlobalizationContext from the given language code, region-based format code, and
   * language-based format code.
   *
   * The language code and format code must comply with all relevant constraints
   * (see {@link https://wbdstreaming.atlassian.net/wiki/spaces/GCX/pages/52199934/Language+Codes+and+Format+Codes})
   *
   * @param languageCode - the language code defining the target language for translation
   * @param regionBasedFormatCode - the FormatCode used for formats that are specific to the region, like
   * currency and purely numeric date formats
   * @param languageBasedFormatCode - the FormatCode used for formats that are specific to the language,
   * like units and date formats with words
   */
  public constructor(
    languageCode: LanguageCode,
    regionBasedFormatCode: FormatCode,
    languageBasedFormatCode: LanguageBasedFormatCode,
    options?: IGlobalizationContextOptions
  );

  public constructor(
    languageCode: LanguageCode,
    formatCode: FormatCode,
    languageBasedFormatCodeOrOptions?: LanguageBasedFormatCode | IGlobalizationContextOptions,
    options?: IGlobalizationContextOptions
  ) {
    let languageBasedFormatCode = undefined;
    if (typeof languageBasedFormatCodeOrOptions !== 'string') {
      options = options ?? languageBasedFormatCodeOrOptions;
    } else {
      // auto enable hybrid locales when an explicit `languageBasedFormatCode` is passed
      languageBasedFormatCode = languageBasedFormatCodeOrOptions;
      options = {
        ...options,
        enableHybridLocaleFormatter: true
      };
    }

    this._languageCode = languageCode;
    this._regionBasedFormatCode = formatCode;
    languageBasedFormatCode =
      languageBasedFormatCode ?? deriveLanguageBasedFormatCode(languageCode, formatCode);
    this._languageBasedFormatCode = options?.enableHybridLocaleFormatter
      ? languageBasedFormatCode
      : formatCode;
  }

  /**
   * Returns the language code for this GlobalizationContext.
   */
  public get languageCode(): LanguageCode {
    return this._languageCode;
  }

  /**
   * Returns the format code for this GlobalizationContext. Used to format numbers.
   *
   * @deprecated use `regionBasedFormatCode` or `languageBasedFormatCode`
   */
  public get formatCode(): FormatCode {
    return this._regionBasedFormatCode;
  }
  /**
   * Returns the region-based format code for this GlobalizationContext. Used for formats without words, such as
   * numbers, purely numeric date formats, and currency values..
   */
  public get regionBasedFormatCode(): FormatCode {
    return this._regionBasedFormatCode;
  }

  /**
   * Returns the language-based format code for this GlobalizationContext. Used for formats which contain words, such as
   * units, dates which contain words, and relative times.
   *
   * Can be different from `regionBasedFormatCode` and `formatCode` when the option `enableHybridLocaleFormatter` is provided.
   */
  public get languageBasedFormatCode(): LanguageBasedFormatCode {
    return this._languageBasedFormatCode;
  }
}
