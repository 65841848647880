// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * The ISO 639-1 Alpha 2 language codes (see {@link https://wbdstreaming.atlassian.net/wiki/spaces/GCX/pages/52199934/Language+Codes+and+Format+Codes#ISO-639-1-Alpha-2-Language-Codes}).
 *
 * These codes constitute the majority of the "primary language sub-tag" components of the IETF BCP 47 language tags
 * (see {@link https://wbdstreaming.atlassian.net/wiki/spaces/GCX/pages/52199934/Language+Codes+and+Format+Codes#IETF-BCP-47-Language-Tags}).
 *
 * Official values were sourced from the Library of Congress:
 *    <br>Website: {@link https://id.loc.gov/vocabulary/iso639-1.html}
 *    <br>Data file URL: {@link https://id.loc.gov/vocabulary/iso639-1.json}
 *    <br>Sourced on: 2021-03-23
 *
 * This file was generated by `localization-enum-generator`. Please edit the template there to make any desired
 * changes. Changes made directly to this file will be overwritten without warning if this file is re-generated.
 *
 * @public
 */
export const enum IsoLanguageCode {
  /** Afar */
  AA = 'aa',

  /** Abkhazian */
  AB = 'ab',

  /** Avestan */
  AE = 'ae',

  /** Afrikaans */
  AF = 'af',

  /** Akan */
  AK = 'ak',

  /** Amharic */
  AM = 'am',

  /** Aragonese */
  AN = 'an',

  /** Arabic */
  AR = 'ar',

  /** Assamese */
  AS = 'as',

  /** Avaric */
  AV = 'av',

  /** Aymara */
  AY = 'ay',

  /** Azerbaijani */
  AZ = 'az',

  /** Bashkir */
  BA = 'ba',

  /** Belarusian */
  BE = 'be',

  /** Bulgarian */
  BG = 'bg',

  /** Bihari languages */
  BH = 'bh',

  /** Bislama */
  BI = 'bi',

  /** Bambara */
  BM = 'bm',

  /** Bengali */
  BN = 'bn',

  /** Tibetan */
  BO = 'bo',

  /** Breton */
  BR = 'br',

  /** Bosnian */
  BS = 'bs',

  /** Catalan |  Valencian */
  CA = 'ca',

  /** Chechen */
  CE = 'ce',

  /** Chamorro */
  CH = 'ch',

  /** Corsican */
  CO = 'co',

  /** Cree */
  CR = 'cr',

  /** Czech */
  CS = 'cs',

  /** Church Slavic |  Old Slavonic |  Church Slavonic |  Old Bulgarian |  Old Church Slavonic */
  CU = 'cu',

  /** Chuvash */
  CV = 'cv',

  /** Welsh */
  CY = 'cy',

  /** Danish */
  DA = 'da',

  /** German */
  DE = 'de',

  /** Divehi |  Dhivehi |  Maldivian */
  DV = 'dv',

  /** Dzongkha */
  DZ = 'dz',

  /** Ewe */
  EE = 'ee',

  /** Greek, Modern (1453-) */
  EL = 'el',

  /** English */
  EN = 'en',

  /** Esperanto */
  EO = 'eo',

  /** Spanish |  Castilian */
  ES = 'es',

  /** Estonian */
  ET = 'et',

  /** Basque */
  EU = 'eu',

  /** Persian */
  FA = 'fa',

  /** Fulah */
  FF = 'ff',

  /** Finnish */
  FI = 'fi',

  /** Fijian */
  FJ = 'fj',

  /** Faroese */
  FO = 'fo',

  /** French */
  FR = 'fr',

  /** Western Frisian */
  FY = 'fy',

  /** Irish */
  GA = 'ga',

  /** Gaelic |  Scottish Gaelic */
  GD = 'gd',

  /** Galician */
  GL = 'gl',

  /** Guarani */
  GN = 'gn',

  /** Gujarati */
  GU = 'gu',

  /** Manx */
  GV = 'gv',

  /** Hausa */
  HA = 'ha',

  /** Hebrew */
  HE = 'he',

  /** Hindi */
  HI = 'hi',

  /** Hiri Motu */
  HO = 'ho',

  /** Croatian */
  HR = 'hr',

  /** Haitian |  Haitian Creole */
  HT = 'ht',

  /** Hungarian */
  HU = 'hu',

  /** Armenian */
  HY = 'hy',

  /** Herero */
  HZ = 'hz',

  /** Interlingua (International Auxiliary Language Association) */
  IA = 'ia',

  /** Indonesian */
  ID = 'id',

  /** Interlingue |  Occidental */
  IE = 'ie',

  /** Igbo */
  IG = 'ig',

  /** Sichuan Yi |  Nuosu */
  II = 'ii',

  /** Inupiaq */
  IK = 'ik',

  /** Ido */
  IO = 'io',

  /** Icelandic */
  IS = 'is',

  /** Italian */
  IT = 'it',

  /** Inuktitut */
  IU = 'iu',

  /** Japanese */
  JA = 'ja',

  /** Javanese */
  JV = 'jv',

  /** Georgian */
  KA = 'ka',

  /** Kongo */
  KG = 'kg',

  /** Kikuyu |  Gikuyu */
  KI = 'ki',

  /** Kuanyama |  Kwanyama */
  KJ = 'kj',

  /** Kazakh */
  KK = 'kk',

  /** Kalaallisut |  Greenlandic */
  KL = 'kl',

  /** Central Khmer */
  KM = 'km',

  /** Kannada */
  KN = 'kn',

  /** Korean */
  KO = 'ko',

  /** Kanuri */
  KR = 'kr',

  /** Kashmiri */
  KS = 'ks',

  /** Kurdish */
  KU = 'ku',

  /** Komi */
  KV = 'kv',

  /** Cornish */
  KW = 'kw',

  /** Kirghiz |  Kyrgyz */
  KY = 'ky',

  /** Latin */
  LA = 'la',

  /** Luxembourgish |  Letzeburgesch */
  LB = 'lb',

  /** Ganda */
  LG = 'lg',

  /** Limburgan |  Limburger |  Limburgish */
  LI = 'li',

  /** Lingala */
  LN = 'ln',

  /** Lao */
  LO = 'lo',

  /** Lithuanian */
  LT = 'lt',

  /** Luba-Katanga */
  LU = 'lu',

  /** Latvian */
  LV = 'lv',

  /** Malagasy */
  MG = 'mg',

  /** Marshallese */
  MH = 'mh',

  /** Maori */
  MI = 'mi',

  /** Macedonian */
  MK = 'mk',

  /** Malayalam */
  ML = 'ml',

  /** Mongolian */
  MN = 'mn',

  /** Marathi */
  MR = 'mr',

  /** Malay */
  MS = 'ms',

  /** Maltese */
  MT = 'mt',

  /** Burmese */
  MY = 'my',

  /** Nauru */
  NA = 'na',

  /** Bokmål, Norwegian |  Norwegian Bokmål */
  NB = 'nb',

  /** Ndebele, North |  North Ndebele */
  ND = 'nd',

  /** Nepali */
  NE = 'ne',

  /** Ndonga */
  NG = 'ng',

  /** Dutch |  Flemish */
  NL = 'nl',

  /** Norwegian Nynorsk |  Nynorsk, Norwegian */
  NN = 'nn',

  /** Norwegian */
  NO = 'no',

  /** Ndebele, South |  South Ndebele */
  NR = 'nr',

  /** Navajo |  Navaho */
  NV = 'nv',

  /** Chichewa |  Chewa |  Nyanja */
  NY = 'ny',

  /** Occitan (post 1500) */
  OC = 'oc',

  /** Ojibwa */
  OJ = 'oj',

  /** Oromo */
  OM = 'om',

  /** Oriya */
  OR = 'or',

  /** Ossetian |  Ossetic */
  OS = 'os',

  /** Panjabi |  Punjabi */
  PA = 'pa',

  /** Pali */
  PI = 'pi',

  /** Polish */
  PL = 'pl',

  /** Pushto |  Pashto */
  PS = 'ps',

  /** Portuguese */
  PT = 'pt',

  /** Quechua */
  QU = 'qu',

  /** Romansh */
  RM = 'rm',

  /** Rundi */
  RN = 'rn',

  /** Romanian |  Moldavian |  Moldovan */
  RO = 'ro',

  /** Russian */
  RU = 'ru',

  /** Kinyarwanda */
  RW = 'rw',

  /** Sanskrit */
  SA = 'sa',

  /** Sardinian */
  SC = 'sc',

  /** Sindhi */
  SD = 'sd',

  /** Northern Sami */
  SE = 'se',

  /** Sango */
  SG = 'sg',

  /** Sinhala |  Sinhalese */
  SI = 'si',

  /** Slovak */
  SK = 'sk',

  /** Slovenian */
  SL = 'sl',

  /** Samoan */
  SM = 'sm',

  /** Shona */
  SN = 'sn',

  /** Somali */
  SO = 'so',

  /** Albanian */
  SQ = 'sq',

  /** Serbian */
  SR = 'sr',

  /** Swati */
  SS = 'ss',

  /** Sotho, Southern */
  ST = 'st',

  /** Sundanese */
  SU = 'su',

  /** Swedish */
  SV = 'sv',

  /** Swahili */
  SW = 'sw',

  /** Tamil */
  TA = 'ta',

  /** Telugu */
  TE = 'te',

  /** Tajik */
  TG = 'tg',

  /** Thai */
  TH = 'th',

  /** Tigrinya */
  TI = 'ti',

  /** Turkmen */
  TK = 'tk',

  /** Tagalog */
  TL = 'tl',

  /** Tswana */
  TN = 'tn',

  /** Tonga (Tonga Islands) */
  TO = 'to',

  /** Turkish */
  TR = 'tr',

  /** Tsonga */
  TS = 'ts',

  /** Tatar */
  TT = 'tt',

  /** Twi */
  TW = 'tw',

  /** Tahitian */
  TY = 'ty',

  /** Uighur |  Uyghur */
  UG = 'ug',

  /** Ukrainian */
  UK = 'uk',

  /** Urdu */
  UR = 'ur',

  /** Uzbek */
  UZ = 'uz',

  /** Venda */
  VE = 've',

  /** Vietnamese */
  VI = 'vi',

  /** Volapük */
  VO = 'vo',

  /** Walloon */
  WA = 'wa',

  /** Wolof */
  WO = 'wo',

  /** Xhosa */
  XH = 'xh',

  /** Yiddish */
  YI = 'yi',

  /** Yoruba */
  YO = 'yo',

  /** Zhuang |  Chuang */
  ZA = 'za',

  /** Chinese */
  ZH = 'zh',

  /** Zulu */
  ZU = 'zu',

  /**
   * The pseudo-localization "language" that indicates that the English text should be replaced with
   * alternative text that facilitates localization testing. See the `IsoRegionCode.LOC` and `IsoRegionCode.LONG`
   * values for the supported flavors of pseudo-localization.
   */
  PSEUDO = 'pseudo'
}

// prettier-ignore
/**
 * set of values from the IsoLanguageCode const enums
 * @public
 */
export const IsoLanguageCodeSet: ReadonlySet<IsoLanguageCode> = new Set([
'aa', 'ab', 'ae', 'af', 'ak', 'am', 'an', 'ar', 'as', 'av', 'ay', 'az', 'ba', 'be', 'bg', 'bh', 'bi', 'bm', 'bn', 'bo', 'br', 'bs', 'ca', 'ce', 'ch', 'co', 'cr', 'cs', 'cu', 'cv', 'cy', 'da', 'de', 'dv', 'dz', 'ee', 'el', 'en', 'eo', 'es', 'et', 'eu', 'fa', 'ff', 'fi', 'fj', 'fo', 'fr', 'fy', 'ga', 'gd', 'gl', 'gn', 'gu', 'gv', 'ha', 'he', 'hi', 'ho', 'hr', 'ht', 'hu', 'hy', 'hz', 'ia', 'id', 'ie', 'ig', 'ii', 'ik', 'io', 'is', 'it', 'iu', 'ja', 'jv', 'ka', 'kg', 'ki', 'kj', 'kk', 'kl', 'km', 'kn', 'ko', 'kr', 'ks', 'ku', 'kv', 'kw', 'ky', 'la', 'lb', 'lg', 'li', 'ln', 'lo', 'lt', 'lu', 'lv', 'mg', 'mh', 'mi', 'mk', 'ml', 'mn', 'mr', 'ms', 'mt', 'my', 'na', 'nb', 'nd', 'ne', 'ng', 'nl', 'nn', 'no', 'nr', 'nv', 'ny', 'oc', 'oj', 'om', 'or', 'os', 'pa', 'pi', 'pl', 'ps', 'pt', 'qu', 'rm', 'rn', 'ro', 'ru', 'rw', 'sa', 'sc', 'sd', 'se', 'sg', 'si', 'sk', 'sl', 'sm', 'sn', 'so', 'sq', 'sr', 'ss', 'st', 'su', 'sv', 'sw', 'ta', 'te', 'tg', 'th', 'ti', 'tk', 'tl', 'tn', 'to', 'tr', 'ts', 'tt', 'tw', 'ty', 'ug', 'uk', 'ur', 'uz', 've', 'vi', 'vo', 'wa', 'wo', 'xh', 'yi', 'yo', 'za', 'zh', 'zu', 'pseudo'
]) as Set<IsoLanguageCode>;
