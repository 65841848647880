// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { BoltHttpClient } from '@wbd/bolt-http';
import { RefreshSignal } from '@wbd/bolt-http';
import type { EventWithParams } from '@wbd/light-events';
import type { ILabsDecisionData, ILabsSDKEventParams, ILabsSDKOptions, LabsSDKEvents } from '../types';
import { labsRequest } from '../utils';

/**
 * Cache for fetching, storing, and handling labs service responses.
 * @public
 */
export class LabsResponseCache<T = Record<string, unknown>, K extends keyof T = keyof T> {
  private readonly _cache: Map<K, ILabsDecisionData<T[K]>> = new Map();
  private _boltHttp!: BoltHttpClient;
  private _projectId!: string;
  private _requestErrorEvent?: EventWithParams<[ILabsSDKEventParams<LabsSDKEvents>]>;
  private _requestOptions!: ILabsSDKOptions<T>['requestOptions'];

  /**
   * Fetch response from service build cache from response decisions to the cache.
   */
  private async _fetchCache(): Promise<void> {
    const { _boltHttp: boltHttp, _projectId: projectId, _requestOptions: requestOptions } = this;
    const response = await labsRequest<T>({ boltHttp, projectId, requestOptions }).catch((error) => {
      if (this._requestErrorEvent) {
        return this._requestErrorEvent.fire(error);
      } else {
        // retrow if the event handler hasn't been set up yet
        throw error;
      }
    });

    if (!response) return;

    this._cache.clear();
    (Object.keys(response) as []).forEach((key) => {
      this._cache.set(key, response[key]);
    });
  }

  /**
   * Handle BoltHttpClient refresh callback. Refreshes on UX and BOOTSTRAP signals.
   */
  private _handleRefreshSignals = async (refreshSignals: RefreshSignal[]): Promise<void> => {
    if (refreshSignals.includes(RefreshSignal.BOOTSTRAP) || refreshSignals.includes(RefreshSignal.UX)) {
      await this._fetchCache();
    }
  };

  /**
   * Get a labs decision from cached response by key.
   */
  public get(key: K): ILabsDecisionData<T[K]> | undefined {
    return this._cache.get(key);
  }

  /**
   * Stores SDK configuration, then fetches and the labs service response.
   */
  public async initialize({ boltHttp, projectId, requestOptions }: ILabsSDKOptions<T>): Promise<void> {
    this._boltHttp = boltHttp;
    this._projectId = projectId;
    this._requestOptions = requestOptions;

    // Await initial response and cache build.
    await this._fetchCache();
    this._boltHttp.refreshManager.onRefresh(this._handleRefreshSignals);
  }

  /**
   * Return a Set keys in the cache.
   */
  public keys(): Set<K> {
    return new Set(this._cache.keys());
  }

  /**
   * Set labs request event error handler.
   */
  public setRequestErrorEvent(event: EventWithParams<[ILabsSDKEventParams<LabsSDKEvents>]>): void {
    this._requestErrorEvent = event;
  }
}
