// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { CanonicalizedCldrLocale } from '../localization-enums';
import { LanguageCode } from '../nominals';
import { IGlobalizationPayload } from './IGlobalizationPayload';
import { HboCldrFormatCode, HboCldrLanguageCode } from './types';

/**
 * A constant for the default `LanguageCode`, US English. It is used throughout our codebase as a
 * special value, since it is the universal fallback `LanguageCode`.
 *
 * @public
 */
export const DEFAULT_LANGUAGE_CODE: HboCldrLanguageCode =
  CanonicalizedCldrLocale.EN_US as HboCldrLanguageCode;

/**
 * A constant for the default `FormatCode`, US English. It is used throughout our codebase as a
 * special value, since it is the universal fallback `FormatCode`.
 *
 * @public
 */
export const DEFAULT_FORMAT_CODE: HboCldrFormatCode = CanonicalizedCldrLocale.EN_US as HboCldrFormatCode;

/**
 * A constant for the default `IGlobalizationPayload`, US English/US English. It is used throughout our codebase
 * as a special value, since it is the universal fallback `IGlobalizationPayload`.
 *
 * @public
 */
export const DEFAULT_GLOBALIZATION_PAYLOAD: Readonly<IGlobalizationPayload> = {
  uiLanguage: DEFAULT_LANGUAGE_CODE,
  alternateUiLanguage: DEFAULT_LANGUAGE_CODE,
  format: DEFAULT_FORMAT_CODE
} as const;

/**
 * A constant for the special 'undetermined' language code. Used during language selection
 * to signal that we are unable to identify an appropriate language for some context (e.g.
 * to trigger additional fallback logic).  Cannot be used for formatting or messages.
 *
 * @public
 */
export const UND: LanguageCode = 'und' as LanguageCode;
