// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IDimensions } from '@wbd/player-sdk-core';
import type {
  IAudioCodecsOverride,
  IEngineNetworkRequestRetryConfig,
  IVideoCodecsOverride,
  IGluonConfig
} from '@wbd/player-sdk-gluon-engine';
import type { IScrubberOptions } from './IScrubberOptions';
import { ResolutionConstraint } from './ResolutionConstraint';
import { ProjectedHdcpLevel } from './IEngineCreatorConfig';

/**
 * Default value for *IEngineCreatorConfig* property *supportedAudioCodecs*
 * - Supported audio codecs for the player
 * @public
 */
export const defaultSupportedAudioCodecs: IAudioCodecsOverride = {
  aac: true,
  ac3: false,
  eac3: false,
  atmos: false
};

/**
 * Default value for *IEngineCreatorConfig* property *supportedVideoCodecs*
 * - Supported video codecs for the player
 * @public
 */
export const defaultSupportedVideoCodecs: IVideoCodecsOverride = {
  avc: true,
  dvh: false,
  hevcSdr: false,
  hevcHdr: false
};

/**
 * Default value for *IEngineCreatorConfig* property *maxResolution*
 * - Max resolution for the player
 * @public
 */
export const defaultMaxResolution: IDimensions = ResolutionConstraint.HD_1080P;

/**
 * Default value for *IEngineCreatorConfig* property *networkRequestRetry*
 * - Defines download retry timeouts for segments and manifest
 * @public
 */
export const defaultNetworkRequestRetry: IEngineNetworkRequestRetryConfig = {
  bufferRetryOptions: {
    maxRetryTimeMS: 180000,
    requestTimeoutMS: 12000,
    requestProgressTimeoutMS: 8000
  }
};

/**
 * Default values for the Player Scrubber.
 * - check IScrubberOptions interface for more details on each property
 * @public
 */
export const defaultScrubberOptions: Required<IScrubberOptions> = {
  disabled: false,
  fixItems: true,
  timeNotifyMS: 33,
  timeRepeatKeyMS: 600,
  timeToKeepNotifyingMS: 200,
  timeRepeatKeyStartLongPressMS: 700,
  items: [
    { afterSeconds: 0, value: 10 },
    { afterSeconds: 1, value: 15 },
    { afterSeconds: 2, value: 20 },
    { afterSeconds: 3, value: 30 },
    { afterSeconds: 4, value: 40 },
    { afterSeconds: 5, value: 60 },
    { afterSeconds: 7, value: 80 }
  ]
};

/**
 * Values for the Player Scrubber targeting high performance devices.
 * - check IScrubberOptions interface for more details on each property
 * @public
 */
export const highPerformanceScrubberOptions: Required<IScrubberOptions> = {
  ...defaultScrubberOptions,
  timeRepeatKeyMS: 250,
  timeToKeepNotifyingMS: 100,
  timeRepeatKeyStartLongPressMS: 500
};

/**
 * Default value for the video GC interval
 * @public
 */
export const defaultVideoGCInterval: number = 30_000;

/**
 * Default value for initialbandwidth.
 * value of 0.5 is to be conservative for now in favor of VST.
 * if we set it to 1, the gluon will resume from last measured bandwidth
 * @public
 */
export const defaultInitialBandwidthFactor: number = 0.5;

/**
 * Returns the projected HDCP level for the target platform
 * by default it is set to HDCP_NONE and then each platform
 * can override based on the platform capabilities for HDCP
 * @public
 */
export const defaultHdcpConfig: ProjectedHdcpLevel = ProjectedHdcpLevel.HDCP_NONE;

/**
 * Default value for Engine config property requireSingleKey
 * @public
 */
export const defaultRequireSingleKey: boolean = false;

/**
 * Default configuration for player engine
 ** 1080p max resolution,
 ** avc video codec
 ** aac audio codec
 ** no workarounds
 * @public
 */
export const defaultPlayerEngineConfig: IGluonConfig = {
  resolutionOverrides: {
    maxResolution: defaultMaxResolution
  },
  supportedVideoCodecs: defaultSupportedVideoCodecs,
  supportedAudioCodecs: defaultSupportedAudioCodecs,
  networkRequestRetry: defaultNetworkRequestRetry,
  initialBandwidthFactor: defaultInitialBandwidthFactor,
  hdcpConfig: defaultHdcpConfig,
  requireSingleKey: defaultRequireSingleKey
};
