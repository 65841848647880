// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { FormatCode } from '@wbd/localization-core';

/**
 * FormatCodes for the available format packs.
 *
 * @public
 */
export const SUPPORTED_FORMATS: readonly FormatCode[] = [
  'en-US' as FormatCode,
  'es-US' as FormatCode,
  'en-CA' as FormatCode,
  'fr-CA' as FormatCode,
  'en-AS' as FormatCode,
  'en-GU' as FormatCode,
  'en-MP' as FormatCode,
  'en-PR' as FormatCode,
  'es-PR' as FormatCode,
  'en-UM' as FormatCode,
  'en-VI' as FormatCode,
  'es-AR' as FormatCode,
  'es-BO' as FormatCode,
  'es-BR' as FormatCode,
  'pt-BR' as FormatCode,
  'es-CL' as FormatCode,
  'es-CO' as FormatCode,
  'es-CR' as FormatCode,
  'es-DO' as FormatCode,
  'es-EC' as FormatCode,
  'es-GT' as FormatCode,
  'es-HN' as FormatCode,
  'es-MX' as FormatCode,
  'es-NI' as FormatCode,
  'es-PA' as FormatCode,
  'es-PY' as FormatCode,
  'es-SV' as FormatCode,
  'es-UY' as FormatCode,
  'es-VE' as FormatCode,
  'en-AG' as FormatCode,
  'en-AI' as FormatCode,
  'nl-AW' as FormatCode,
  'en-BB' as FormatCode,
  'en-BS' as FormatCode,
  'en-BZ' as FormatCode,
  'es-BZ' as FormatCode,
  'nl-CW' as FormatCode,
  'en-DM' as FormatCode,
  'en-GD' as FormatCode,
  'en-GY' as FormatCode,
  'fr-HT' as FormatCode,
  'en-JM' as FormatCode,
  'en-KN' as FormatCode,
  'en-KY' as FormatCode,
  'en-LC' as FormatCode,
  'en-MS' as FormatCode,
  'es-PE' as FormatCode,
  'nl-SR' as FormatCode,
  'en-TC' as FormatCode,
  'en-TT' as FormatCode,
  'en-VC' as FormatCode,
  'en-VG' as FormatCode,
  'ca-AD' as FormatCode,
  'es-EA' as FormatCode,
  'es-IC' as FormatCode,
  'es-ES' as FormatCode,
  'sv-AX' as FormatCode,
  'fo-FO' as FormatCode,
  'da-GL' as FormatCode,
  'nb-NO' as FormatCode,
  'en-SE' as FormatCode,
  'sv-SE' as FormatCode,
  'nb-SJ' as FormatCode,
  'da-DK' as FormatCode,
  'en-DK' as FormatCode,
  'en-FI' as FormatCode,
  'fi-FI' as FormatCode,
  'sv-FI' as FormatCode,
  'fr-FR' as FormatCode,
  'fr-MC' as FormatCode,
  'pt-PT' as FormatCode,
  'sq-AL' as FormatCode,
  'hr-BA' as FormatCode,
  'sr-Latn-BA' as FormatCode,
  'bg-BG' as FormatCode,
  'hr-HR' as FormatCode,
  'sq-XK' as FormatCode,
  'sr-Latn-XK' as FormatCode,
  'pl-PL' as FormatCode,
  'mk-MK' as FormatCode,
  'sq-MK' as FormatCode,
  'ro-MD' as FormatCode,
  'ru-MD' as FormatCode,
  'sr-Latn-ME' as FormatCode,
  'ro-RO' as FormatCode,
  'sr-Latn-RS' as FormatCode,
  'sk-SK' as FormatCode,
  'en-SI' as FormatCode,
  'sl-SI' as FormatCode,
  'hu-HU' as FormatCode,
  'cs-CZ' as FormatCode,
  'et-EE' as FormatCode,
  'is-IS' as FormatCode,
  'lt-LT' as FormatCode,
  'lv-LV' as FormatCode,
  'en-NL' as FormatCode,
  'nl-NL' as FormatCode,
  'tr-TR' as FormatCode,
  'el-GR' as FormatCode,
  'de-AT' as FormatCode,
  'en-AT' as FormatCode,
  'de-BE' as FormatCode,
  'en-BE' as FormatCode,
  'fr-BE' as FormatCode,
  'nl-BE' as FormatCode,
  'el-CY' as FormatCode,
  'en-CY' as FormatCode,
  'tr-CY' as FormatCode,
  'de-DE' as FormatCode,
  'en-DE' as FormatCode,
  'en-IE' as FormatCode,
  'de-IT' as FormatCode,
  'it-IT' as FormatCode,
  'de-LU' as FormatCode,
  'fr-LU' as FormatCode,
  'pt-LU' as FormatCode,
  'en-MT' as FormatCode,
  'en-GB' as FormatCode,
  'fr-GP' as FormatCode,
  'fr-GF' as FormatCode,
  'fr-RE' as FormatCode,
  'fr-YT' as FormatCode,
  'fr-MQ' as FormatCode,
  'fr-MF' as FormatCode,
  'fr-PF' as FormatCode,
  'fr-NC' as FormatCode,
  'fr-BL' as FormatCode,
  'fr-PM' as FormatCode,
  'fr-WF' as FormatCode,
  'en-SG' as FormatCode,
  'ms-SG' as FormatCode,
  'zh-Hans-SG' as FormatCode,
  'en-MY' as FormatCode,
  'ms-MY' as FormatCode,
  'zh-Hant-TW' as FormatCode,
  'th-TH' as FormatCode,
  'id-ID' as FormatCode,
  'en-HK' as FormatCode,
  'zh-Hans-HK' as FormatCode,
  'zh-Hant-HK' as FormatCode,
  'en-PH' as FormatCode,
  'es-PH' as FormatCode,
  'fil-PH' as FormatCode,
  'en-AU' as FormatCode,
  'en-NZ' as FormatCode,
  'ru-RU' as FormatCode,
  'pseudo-LOC' as FormatCode,
  'pseudo-LONG' as FormatCode,
  'es-419' as FormatCode
] as const;
