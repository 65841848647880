// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * helper function render a canvas2d drawing as a webGL canvas (single texture)
 */
export function drawCanvasTexture(canvas: HTMLCanvasElement, gl: WebGLRenderingContext): void {
  const vertexShader = gl.createShader(gl.VERTEX_SHADER);
  const program = gl.createProgram();

  // Set up position and texture coordinate buffers
  const positionBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([-1, 1, 1, 1, -1, -1, 1, -1]), gl.STATIC_DRAW);

  const textureCoordBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([0, 0, 1, 0, 0, 1, 1, 1]), gl.STATIC_DRAW);

  // Compile shaders
  const vertexShaderSource = `
      attribute vec2 a_position;
      attribute vec2 a_textureCoord;
      varying vec2 v_textureCoord;
      void main() {
        gl_Position = vec4(a_position, 0, 1);
        v_textureCoord = a_textureCoord;
      }
    `;
  const fragmentShaderSource = `
      precision mediump float;
      uniform sampler2D u_texture;
      varying vec2 v_textureCoord;
      void main() {
        gl_FragColor = texture2D(u_texture, v_textureCoord);
      }
    `;

  if (vertexShader) {
    gl.shaderSource(vertexShader, vertexShaderSource);
    gl.compileShader(vertexShader);
  }
  const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
  if (fragmentShader) {
    gl.shaderSource(fragmentShader, fragmentShaderSource);
    gl.compileShader(fragmentShader);
  }

  // Create program and link shaders
  if (program && fragmentShader && vertexShader) {
    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);
    gl.useProgram(program);
  }

  // Set up texture
  const texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, canvas);

  // Set up attributes and uniforms
  if (program) {
    const positionLocation = gl.getAttribLocation(program, 'a_position');
    const textureCoordLocation = gl.getAttribLocation(program, 'a_textureCoord');
    const textureLocation = gl.getUniformLocation(program, 'u_texture');

    gl.enableVertexAttribArray(positionLocation);
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

    gl.enableVertexAttribArray(textureCoordLocation);
    gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);
    gl.vertexAttribPointer(textureCoordLocation, 2, gl.FLOAT, false, 0, 0);

    gl.uniform1i(textureLocation, 0);
  }

  // Draw the rectangle
  gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
}
