// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { TFontScript } from './TFont';

/**
 * Identify the meaningful region script, or Latn default
 * @public
 */
export function getFontScript(scripts: string[]): TFontScript {
  // Hans, Hans-SG...
  if (scripts.includes('Hans')) return 'Hans';
  // Hant, Hant-HK...
  const hant = scripts.find((script) => script.startsWith('Hant-'));
  if (hant) return hant as TFontScript;
  // Thai
  if (scripts.includes('Thai')) return 'Thai';
  // default
  return 'Latn';
}
