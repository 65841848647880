// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { Configuration } from '../../core';
import { IRequestMeasureV1Payload } from '../../generated';
import { NetworkMonitor } from './NetworkMonitor';

/**
 * The Request monitoring class that allows access to Measure apis
 * @public
 */
export class RequestMonitor {
  /** contains the measure apis */
  // eslint-disable-next-line @typescript-eslint/typedef
  public static Measure = new (class {
    /** creates measures from two marks */
    public createMeasure(measure: IRequestMeasureV1Payload): void {
      return RequestMonitor.measureInstance.createMeasure(measure);
    }
  })();

  /** instance of the request measure monitor that is defined on initialization */
  public static measureInstance: NetworkMonitor;

  /** initializes the request measure monitor and connects the public apis to the private apis */
  public static initialize(config: Configuration): void {
    this.measureInstance = new NetworkMonitor(config.telegraphUri, config.traceFlags, config.httpClient);
  }

  /** updates the configuration of the request measure monitor */
  public static update(config: Configuration): void {
    this.measureInstance.update(config.telegraphUri, config.traceFlags, config.httpClient);
  }
}
