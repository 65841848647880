// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { GlobalizationContext, Globalizer, type GlobalizeStatic } from '@wbd/localization';
import {
  DEFAULT_LANGUAGE_CODE,
  selectFormat,
  selectLanguage,
  type LanguageCode
} from '@wbd/localization-core';
import { type IBeamCTVGlobalizer } from './IBeamCTVGlobalizer';
import { SUPPORTED_FORMATS } from '@wbd/localization-formats';
import { getDisplayLanguageFromQueryParam } from './getDisplayLanguageFromQueryParam';

/**
 * Create a globalizer context for a specific language and format
 * @public
 */
export function createGlobalizerContext(
  clientEnabledLanguages: LanguageCode[],
  wantLanguageCodes: string[],
  wantFormatCode?: string,
  overrideLanguageCode?: string
): GlobalizationContext {
  // support language overrides
  overrideLanguageCode =
    overrideLanguageCode ??
    getDisplayLanguageFromQueryParam(clientEnabledLanguages, new URLSearchParams(location.search));
  wantLanguageCodes = overrideLanguageCode ? [overrideLanguageCode, ...wantLanguageCodes] : wantLanguageCodes;

  // select language translations and formatters from client available bundle
  const languageCode = selectLanguage(wantLanguageCodes, clientEnabledLanguages, DEFAULT_LANGUAGE_CODE);
  const formatCode = selectFormat(wantFormatCode, Array.from(SUPPORTED_FORMATS), languageCode);

  // create new globalization context
  return new GlobalizationContext(languageCode, formatCode);
}

/**
 * Initialize a new globalizer from the globalizer context
 * @public
 */
export async function loadGlobalizerAsync(
  context: GlobalizationContext,
  loadLanguagePack: (languageCode: string) => Promise<GlobalizeStatic>
): Promise<IBeamCTVGlobalizer> {
  const { loadTranslatorAsync, loadFormatterAsync } = await import(
    /* webpackChunkName: "resources-loader" */ './ResourceLoaders'
  );

  // lazy load the new locales and hydrate a new Globalizer instance
  return await Globalizer.loadGlobalizerAsync(
    context,
    (languageCode: LanguageCode) => loadTranslatorAsync(languageCode, loadLanguagePack),
    loadFormatterAsync
  );
}
