// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IInitialContext } from '../application';
import { TextToken } from '@wbd/beam-ctv-shared-ui';
import type { ParameterlessMessageKeys } from '@wbd/beam-ctv-localization';
import { SessionManager } from '@wbd/instrumentation-session-manager';
import { drawCanvasTexture } from './utils/helpers';
import type { ISonicError } from '@wbd/bolt-dataservice';
import { isHttpError } from '@wbd/bolt-http';
import Resources, { AppName } from '@wbd/fuse-ctv-resources-common';

interface ITextToken {
  fontSize: number;
  fontFace?: string;
}

interface ITextDrawing {
  token: ITextToken;
  textWrapWidth?: number;
  content: string;
  startX: number;
  startY: number;
}

function drawLines(
  text: string[],
  startX: number,
  startY: number,
  lineHeight: number,
  canvasContext: CanvasRenderingContext2D
): void {
  text.forEach((line, index) => {
    canvasContext.fillText(line, startX, startY + lineHeight * index);
  });
}

const fixedWidth: number = 1280;
const fixedHeight: number = 720;

function getErrorLines(error: Error | ISonicError | undefined): string[] {
  if (!error) {
    return [`No error details available`];
  }

  const errorLines: string[] = [];

  if (isHttpError(error)) {
    const { message, name, response } = error;
    errorLines.push(
      message,
      name,
      ...(response
        ? [
            response.config?.url,
            response.status.toString(),
            response.statusText,
            JSON.stringify(response.data)
          ]
        : [])
    );
  }

  if (error.stack) {
    errorLines.push(...error.stack.split('\n').filter((value: string) => Boolean(value.length)));
  } else {
    errorLines.push('No stack');
  }

  return errorLines;
}

function createCanvasElement(): HTMLCanvasElement {
  const canvas = document.createElement('canvas');
  canvas.width = fixedWidth;
  canvas.height = fixedHeight;
  canvas.setAttribute('style', `width: ${window.innerWidth}px; height: ${window.innerHeight}px`);
  return canvas;
}

// first we draw the error dialog
function drawTexture(canvas: HTMLCanvasElement, context: Partial<IInitialContext>): void {
  const ctx = canvas.getContext('2d');
  const { globalizer, platformAdapter } = context;

  const translateWithFallback = (key: ParameterlessMessageKeys, fallback: string): string =>
    globalizer ? globalizer.translator.translate(key) : fallback;

  if (ctx) {
    // we need to add a solid background for it to render on to prevent visual artifacting on various platforms
    ctx.fillStyle = '#000000';
    ctx.fillRect(0, 0, fixedWidth, fixedHeight);

    ctx.fillStyle = 'white';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';

    const appTitle = Resources.appName === AppName.HBOMAX ? 'HBO Max' : 'Max';
    const title = translateWithFallback('Application.Startup.Error.Title', `Can’t Start the ${appTitle} App`);
    drawWithToken(ctx, {
      token: TextToken.heading.lg,
      content: title,
      startX: canvas.width / 2,
      startY: 300
    });

    const body = translateWithFallback(
      'Application.Startup.Error.Fallback',
      `Sorry, we couldn’t start the ${appTitle} app. Please press OK to close the app and try again. (If this issue continues, you can try clearing any saved app data. Press Back to clear saved data.)`
    );
    const bodyBottomY = drawWithToken(ctx, {
      token: TextToken.body.md,
      content: body,
      startX: canvas.width / 2,
      textWrapWidth: 810,
      startY: 340
    });

    const contactInfo = translateWithFallback(
      'FullScreenError.ContactInfo',
      'If you contact us about this error, please include the following code:'
    );

    ctx.fillStyle = '#ffffffcc';

    drawWithToken(ctx, {
      token: TextToken.body.sm,
      content: contactInfo,
      startX: canvas.width / 2,
      startY: bodyBottomY + 18
    });

    drawWithToken(ctx, {
      token: TextToken.body.sm,
      content: SessionManager.sessionId,
      startX: canvas.width / 2,
      startY: bodyBottomY + 40
    });
  }

  if (
    (DEBUG || platformAdapter?.device.getURLParams().get('debug') || !context.isInstrumentationReady) &&
    ctx
  ) {
    ctx.font = '10px book,medium,sans-serif';
    ctx.textAlign = 'left';

    const lineHeight = 18;

    const lines = [String(context.startupError)];
    if (context.startupError?.reason) {
      lines.push(...getErrorLines(context.startupError?.reason));
    }
    lines.push(...getErrorLines(context.startupError));
    drawLines(lines, 100, canvas.height / 2 + 7 * lineHeight, lineHeight, ctx);
  }
}

function setupKeyHandlers(context: Partial<IInitialContext>): void {
  const { platformAdapter } = context;

  if (platformAdapter) {
    document.addEventListener('keydown', async (event) => {
      const keys = await platformAdapter.input.getKeyMapAsync();
      const code = event.keyCode || event.which;
      const key = keys[code];

      if (key === 'Enter') {
        if (platformAdapter.device.canAppBeClosed()) {
          platformAdapter.device.exit();
        } else {
          window.location.reload();
        }
      } else if (key === 'Back') {
        await platformAdapter.storage.clearAsync();
        platformAdapter.device.exit();
      }
    });

    platformAdapter.device.hideStaticSplash();
  }
}

function drawWithToken(
  context: CanvasRenderingContext2D,
  { token, content, startX, startY, textWrapWidth }: ITextDrawing
): number {
  const precision = 2 / 3;
  context.font = `${token.fontSize * precision}px ${token.fontFace ?? 'book'},sans-serif`;

  if (textWrapWidth) {
    const characterWidth = context.measureText(content).width / content.length;
    const fittingCharacterCount = Math.floor(textWrapWidth / precision / characterWidth);

    const words = content.split(' ');

    let lineCount = 0;
    const currentLine: string[] = [];

    words.forEach((word) => {
      if (currentLine.length * characterWidth < fittingCharacterCount) {
        currentLine.push(word);
      } else {
        context.fillText(currentLine.join(' '), startX, startY + token.fontSize * lineCount++);
        currentLine.splice(0, currentLine.length);
        currentLine.push(word);
      }
    });

    if (currentLine.length) {
      context.fillText(currentLine.join(' '), startX, startY + token.fontSize * lineCount++);
    }

    return lineCount * token.fontSize + startY;
  }

  context.fillText(content, startX, startY);
  return token.fontSize + startY;
}

/**
 * Function to present UI fallback error message in case application is unable to load
 * @param context - application boot context
 */
export function loadCanvasErrorFallback(context: Partial<IInitialContext>): void {
  const canvas = createCanvasElement();
  drawTexture(canvas, context);

  const canvas3d = createCanvasElement();
  const gl = canvas3d.getContext('webgl');

  if (gl) {
    drawCanvasTexture(canvas, gl);
    document.body.appendChild(canvas3d);
  } else {
    document.body.appendChild(canvas);
  }

  setupKeyHandlers(context);
}
