// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { AuthToken, IResponse } from '@wbd/bolt-http';

import type { RequestConfigOverride } from '../http-client';
import { HttpClient } from '../http-client';
import type { IArkoseHeaders } from './interfaces/IArkoseHeaders';
import type { ITokenAuthenticationData } from './interfaces/ITokenAuthenticationData';
import type { IUserCredentials } from './interfaces/IUserCredentials';

/**
 * @public
 * Endpoint called on createAccount
 */
export const REGISTER_AND_LOGIN_URL: string = '/users/registration/registerAndLogin';

/**
 * Registers and logs a user in.
 * @param username - The username.
 * @param password - The password.
 * @param arkoseHeaders - The arkose headers.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const registerAndLogin = async (
  username: string,
  password: string,
  arkoseHeaders?: IArkoseHeaders,
  config?: RequestConfigOverride
): Promise<AuthToken> => {
  const http = HttpClient.instance;
  const isArkoseRequest = !!arkoseHeaders;
  const response = await HttpClient.instance.post<IResponse<ITokenAuthenticationData>, IUserCredentials>(
    REGISTER_AND_LOGIN_URL,
    { username, password },
    {
      headers: {
        ...(isArkoseRequest && {
          'X-disco-arkose-token': arkoseHeaders.token,
          'X-disco-arkose-sitekey': arkoseHeaders.siteKey
        })
      },
      signal: config?.signal
    }
  );
  const resource = response.data?.data;
  const token = resource?.attributes?.token;
  await http.sessionConfig.authTokenProvider?.setTokenAsync(token);

  return token;
};
