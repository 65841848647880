// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  IPerformanceMarkV1Payload,
  PerformanceMeasureV1EndMarkName,
  PerformanceMeasureV1Name,
  PerformanceMeasureV1StartMarkName
} from '../../generated';
import { MarkManager } from './MarkManager';
import { MeasureManager } from './MeasureManager';

/**
 * The Performance class that allows access to Mark and Measure apis
 * @public
 */
export class PerformanceManager {
  /**
   *  The Mark class that allows for creating marks
   */
  // eslint-disable-next-line @typescript-eslint/typedef
  public static Mark = new (class {
    /** creates a mark */
    public createMark(payload: IPerformanceMarkV1Payload, deviceOccuredAt?: number): void {
      return PerformanceManager.markInstance.createMark(payload, deviceOccuredAt);
    }
  })();

  /**
   *  The Measure class that allows for measuring marks
   */
  // eslint-disable-next-line @typescript-eslint/typedef
  public static Measure = new (class {
    /** measures mark defined marks */
    public createMeasure(
      name: PerformanceMeasureV1Name,
      startMarkName: PerformanceMeasureV1StartMarkName,
      endMarkName?: PerformanceMeasureV1EndMarkName
    ): void {
      return PerformanceManager.measureInstance.createMeasure(name, startMarkName, endMarkName);
    }
  })();

  /** instance of the mark monitor that is defined on initialization */
  public static markInstance: MarkManager;
  /** instance of the measure monitor that is defined on initialization */
  public static measureInstance: MeasureManager;

  /** initializes the performance monitor and connects the public apis to the private apis */
  public static initialize(): void {
    this.markInstance = new MarkManager();
    this.measureInstance = new MeasureManager(this.markInstance);
  }
}
