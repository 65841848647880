// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { TFontCategory } from './TFont';

const DENSE_CODES: string[] = ['ja', 'ko', 'zh-Hans', 'zh-Hant'];
const TALL_CODES: string[] = [
  'af',
  'ar',
  'bn',
  'fa',
  'gu',
  'hi',
  'km',
  'kn',
  'ml',
  'mr',
  'my',
  'ne',
  'pa',
  'si',
  'ta',
  'te',
  'th',
  'ur',
  'vi'
];

/**
 * Find the text typography category for a given language
 * @public
 */
export function getLanguageCategory(languageCode: string): TFontCategory {
  if (DENSE_CODES.some((code) => languageCode.startsWith(`${code}-`))) return 'dense';
  if (TALL_CODES.some((code) => languageCode.startsWith(`${code}-`))) return 'tall';
  return 'english-like';
}
