// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { AppName, BackgroundVariant, type IFuseCTVResources } from '@wbd/fuse-ctv-resources-common';
import { basicBackground, defaultBackground, maxSplashVideo } from './packlets/assets';
import {
  iconAdd,
  iconAddSolid,
  iconBack,
  iconCheck,
  iconCheckSolid,
  iconChevronDown,
  iconChevronLeft,
  iconChevronRight,
  iconChevronUp,
  iconDelete,
  iconEdit,
  iconError,
  iconEyeHide,
  iconEyeShow,
  iconForward2,
  iconHome,
  iconInfo,
  iconKeyBackspace,
  iconKeyHide,
  iconKeyLanguage,
  iconKeyNext,
  iconKey,
  iconKeyShift,
  iconKeyShiftSolid,
  iconSpace,
  iconSpaceTriple,
  iconLanguage,
  iconLockClosed,
  iconMenu,
  iconMoreEpisodes,
  iconPause,
  iconPlay,
  iconPressAndHold,
  iconProfile,
  iconRemove,
  iconRestart,
  iconSearch,
  iconSeries,
  iconSettings,
  iconTrailer
} from './packlets/assets';

// Static imports of supported languages
// eslint-disable-next-line @rushstack/packlets/mechanics
import { SUPPORTED_LANGUAGES } from './codegen/localization/supportedLanguages';

import {
  BorderToken,
  ColorToken,
  ElevationTokens,
  GradientTokens,
  MotionToken,
  TextTokenEnglishLike,
  TextTokenTall,
  TextTokenDense
} from './packlets/generated';

/**
 * Resources for Max
 *
 * @public
 */
export const data: IFuseCTVResources = {
  appName: AppName.BEAM,
  splashVideo: {
    source: maxSplashVideo
  },
  backgrounds: {
    [BackgroundVariant.Basic]: {
      source: basicBackground,
      testId: 'BasicBackground',
      type: 'image'
    },
    [BackgroundVariant.Default]: {
      source: defaultBackground,
      testId: 'DefaultBackground',
      type: 'image'
    },
    [BackgroundVariant.Darker]: {
      color: ColorToken.background.base.scrim_02,
      testId: 'DarkerBackground',
      type: 'color'
    },
    [BackgroundVariant.Transparent]: {
      color: ColorToken.transparent,
      testId: 'TransparentBackground',
      type: 'color'
    }
  },
  design: {
    border: BorderToken,
    color: ColorToken,
    elevation: ElevationTokens,
    gradient: GradientTokens,
    motion: MotionToken,
    text: {
      'english-like': TextTokenEnglishLike,
      tall: TextTokenTall,
      dense: TextTokenDense
    }
  },
  icons: {
    add: iconAdd,
    addSolid: iconAddSolid,
    back: iconBack,
    check: iconCheck,
    checkSolid: iconCheckSolid,
    chevronDown: iconChevronDown,
    chevronLeft: iconChevronLeft,
    chevronRight: iconChevronRight,
    chevronUp: iconChevronUp,
    delete: iconDelete,
    edit: iconEdit,
    error: iconError,
    eyeHide: iconEyeHide,
    eyeShow: iconEyeShow,
    forward2: iconForward2,
    home: iconHome,
    info: iconInfo,
    key: iconKey,
    keyBackspace: iconKeyBackspace,
    keyHide: iconKeyHide,
    keyLanguage: iconKeyLanguage,
    keyNext: iconKeyNext,
    keyShift: iconKeyShift,
    keyShiftSolid: iconKeyShiftSolid,
    keySpace: iconSpace,
    keySpaceTriple: iconSpaceTriple,
    language: iconLanguage,
    lockClosed: iconLockClosed,
    menu: iconMenu,
    moreEpisodes: iconMoreEpisodes,
    pause: iconPause,
    play: iconPlay,
    pressHold: iconPressAndHold,
    profile: iconProfile,
    remove: iconRemove,
    restart: iconRestart,
    search: iconSearch,
    series: iconSeries,
    settings: iconSettings,
    trailer: iconTrailer
  },
  loadLanguagePack: async function (languageCode: string) {
    // Note: `await import`, unlike `import`, does not directly return the value defined in the Javascript module.
    // Instead, it returns an object with a `default` property whose value is the defined value.
    const languagePack = await import(
      /* webpackChunkName: "i18n/language-packs/[request]" */
      `./codegen/localization/language-packs/${languageCode}/compiled-messages.js`
    );
    return languagePack.default;
  },
  getClientEnabledLanguageCodes: function () {
    return [...SUPPORTED_LANGUAGES];
  }
};
