// Copyright text placeholder, Warner Bros. Discovery, Inc.

/* eslint-disable no-unused-vars */

import type { ILocalizationService } from '@wbd/beam-ctv-localization';
import type { IStorage } from '@wbd/beam-dom-extensions';
import { createV4Uuid } from '@wbd/beam-js-extensions';
import { NotificationEvent } from '@wbd/light-events';
import { StorageDeviceConstants } from '../storage';
import { DevicePerformance } from './DevicePerformance';
import { type INetworkInformation, NetworkType } from './NetworkInformation';
import { parseBrowserUserAgent } from './parseUserAgent';
import {
  DeeplinkContentType,
  DeviceType,
  type ICustomAnnouncer,
  type IDeeplinkParams,
  type IProfileInfo,
  type ISplashVideoProps,
  type TDeviceEventHandler,
  type IStreamMetadata
} from './types';

/**
 * Abstract device implementation
 * @public
 */
export class AbstractDevice {
  /**
   * Lightweight events command bus for Voice and other actions.
   */
  public static readonly deviceCommand: NotificationEvent<TDeviceEventHandler> =
    new NotificationEvent<TDeviceEventHandler>();

  // prohibit class initialization as we only want to use te static functions
  protected constructor() {}

  /**
   * Returns the platform Chipset
   *  @returns string
   */
  public static getDeviceChipsetAsync(): Promise<string> {
    return Promise.resolve('');
  }

  /**
   * Get the current model name (ex. UN65JS9500)
   * @returns string
   */
  public static getDeviceModelNameAsync(): Promise<string> {
    return Promise.resolve(parseBrowserUserAgent().deviceName || 'Unknown');
  }

  /**
   * Get the current model display name (ex. UN65JS9500)
   * @returns string
   */
  public static getDeviceModelDisplayNameAsync(): Promise<string> {
    return this.getDeviceModelNameAsync();
  }

  /**
   * Get the current model group (ex. 17_KANTM_UHD_BASIC)
   */
  public static getDeviceModelGroupAsync(): Promise<string> {
    return Promise.resolve('');
  }

  /**
   * Get the current performance (HIGH, AVERAGE, LOW)
   */
  public static getDevicePerformanceIndicatorAsync(): Promise<DevicePerformance> {
    return Promise.resolve(DevicePerformance.HIGH);
  }

  /**
   * Get the current device type classification (TV, STB, CONSOLE)
   */
  public static getDeviceTypeAsync(): Promise<DeviceType> {
    return Promise.resolve(DeviceType.TV);
  }

  /**
   * Device groups categorize a set of devices upon which feature flags can be targeted to
   * e.g on LG a group of all 2017 devices can return `lg2017` as the device group name
   */
  public static async getDeviceGroupAsync(): Promise<string | undefined> {
    return undefined;
  }

  /**
   * Get device OS name as string (ex. Tizen / webOS)
   */
  public static getDeviceOsAsync(): Promise<string> {
    return Promise.resolve('Unknown');
  }
  /**
   * Get device OS model year string
   * @returns string
   */
  public static getDeviceModelYearAsync(): Promise<string> {
    return Promise.resolve('');
  }

  /**
   * Get the current device OS version (ex. 4.5)
   */
  public static getDeviceOSVersionAsync(): Promise<string> {
    return Promise.resolve(parseBrowserUserAgent().deviceVersion || 'unknown');
  }

  /**
   * Get device brand name like the TV brand or MVPD name (ex. Samsung, Comcast)
   */
  public static getDeviceBrandAsync(): Promise<string> {
    return Promise.resolve('');
  }

  /**
   * Get device manufacturer (ex. Samsung, Huawei)
   */
  public static getDeviceManufacturerAsync(): Promise<string> {
    return Promise.resolve('LGTV');
  }

  /**
   * Get the device network information
   */
  public static getDeviceNetworkInformationAsync(): Promise<INetworkInformation> {
    return Promise.resolve({
      type: NetworkType.UNKNOWN
    });
  }

  /**
   * Check if the current device is connected to the internet
   */
  public static isDeviceConnectedAsync(): Promise<boolean> {
    if (typeof window.navigator?.onLine === 'boolean') {
      return Promise.resolve(navigator.onLine);
    } else {
      return Promise.resolve(true);
    }
  }

  /**
   * Get current device locale following ISO-639 and ISO-3166 (example en-US)
   */
  public static getDeviceLocaleAsync(): Promise<string> {
    let country = 'US';
    let language = 'en';

    if (!navigator || !navigator.language) {
      return Promise.resolve([language, country].join('-'));
    }

    const browserLocale = navigator.language.toLocaleLowerCase();

    if (browserLocale && browserLocale.split('-').length > 1) {
      const localeArray = browserLocale.split('-');
      language = localeArray[0];
      country = localeArray[1].toUpperCase();
    } else {
      language = browserLocale;
    }

    return Promise.resolve([language, country].join('-'));
  }

  /**
   * Returns the Store country code for In-App-Purchase as ISO-3166 (example US)
   */
  public static getStoreCountryCodeAsync(): Promise<string> {
    return Promise.resolve('');
  }

  /**
   * Get unique device identifier
   */
  public static getDeviceIdentifierAsync(storage: IStorage): Promise<string> {
    let uuid = storage.readSync(StorageDeviceConstants.UUID);
    if (!uuid) {
      uuid = createV4Uuid();
      storage.writeSync(StorageDeviceConstants.UUID, uuid);
    }
    return Promise.resolve(uuid);
  }

  /**
   * Get unique device identifier used for legacy auth migration
   */
  public static getDeviceIdentifierForAuthAsync(storage: IStorage): Promise<string> {
    return this.getDeviceIdentifierAsync(storage);
  }

  /**
   * Get legacy token stored in the device for auth migration
   */
  public static getLegacyTokenForRetainAuth(): Promise<string> {
    return Promise.resolve('');
  }

  /**
   * Get platform ad identifier
   */
  public static getDeviceAdIdentifierAsync(storage: IStorage): Promise<string> {
    let uuid = storage.readSync(StorageDeviceConstants.ADVERTISEMENT_UUID);
    if (!uuid || uuid === '[object Object]') {
      uuid = createV4Uuid();
      storage.writeSync(StorageDeviceConstants.ADVERTISEMENT_UUID, uuid);
    }
    return Promise.resolve(uuid);
  }

  /**
   * Returns if the device / OS signals that limited ad tracking should be used - defaults to false
   */
  public static getLimitAdTrackingAsync(): Promise<boolean> {
    return Promise.resolve(false);
  }

  /**
   * Returns device metadata needed to support the Ad Framework. This is a Base64 encoded object.
   */
  public static getAdAttributesAsync(): Promise<string | undefined> {
    return Promise.resolve(undefined);
  }

  /**
   * Returns a string for DSP identification of the app
   */
  public static getAppStoreIdAsync(): Promise<string | undefined> {
    return Promise.resolve(undefined);
  }

  /**
   * Sends a platform specific notification
   * @param message - optional message for platform
   */
  public static notify(message?: string): void {}

  /**
   * Returns if speech synthesis is enabled.
   */
  public static useSpeechSynthesis(): (() => boolean) | undefined {
    return undefined;
  }

  /**
   * Determine if platform guidelines recommend to show a confirmation dialog before closing the app
   */
  public static requiresCloseDialog(): boolean {
    return true;
  }

  /**
   * Exit the application
   */
  public static exit(): void {
    window.close();
  }

  /**
   * Can the app be closed from javascirpt or a platform level api
   * @returns boolean
   */
  public static canAppBeClosed(): boolean {
    return true;
  }

  /**
   * Returns URLSearchParams data structure containing
   * all params from window.location
   */
  public static getURLParams(): URLSearchParams {
    const urlParams = new URLSearchParams(sanitizeQueryString(window.location.search));
    const urlHash = window.location.hash;

    /**
     * Because of the way our router works (using hash #), in a hashy url
     * like "/#catalog/page/some-page-urn?language=es&locale=us"
     * when we get location.search, we'll get an empty string.
     *
     * So to be able to pick ALL search params, from hash or not, we have to
     * do a workaround.
     */
    const hashQSIndex = urlHash.indexOf('?');

    if (hashQSIndex !== -1) {
      const queryString = urlHash.substr(hashQSIndex);

      new URLSearchParams(sanitizeQueryString(queryString)).forEach((value, key) => {
        urlParams.set(key, value);
      });
    }

    return urlParams;

    function sanitizeQueryString(qs: string = ''): string {
      const endStringBackslashRegex = /\/$/;
      return qs.replace(endStringBackslashRegex, '');
    }
  }

  /**
   * Parse and returns the deeplink parameters sent by the platform
   */
  public static async getDeeplinkParamsAsync(
    urlParams: URLSearchParams = this.getURLParams()
  ): Promise<IDeeplinkParams | undefined> {
    const makePayload = (
      type: DeeplinkContentType,
      id: string | undefined | null
    ): IDeeplinkParams | undefined => {
      return id ? { id, type, trackingUrlParams: urlParams } : undefined;
    };

    if (urlParams.has('launchpoint')) {
      const videoDeeplink = makePayload(DeeplinkContentType.VIDEO, urlParams.get('assetId'));
      const showDeeplink = makePayload(DeeplinkContentType.SHOW, urlParams.get('entityId'));
      const searchDeeplink = makePayload(DeeplinkContentType.SEARCH, urlParams.get('query'));

      switch (urlParams.get('launchpoint')) {
        case 'playback':
          return videoDeeplink;
        case 'detail':
          return showDeeplink;
        case 'search':
          return searchDeeplink;
      }
    }

    return undefined;
  }

  /**
   *
   * Enables/disables device screen saver, to allow disabling screen saver during playback
   *
   * @param enable - state in which to set screen saver setting, true = ON and false = OFF
   */
  public static setScreenSaver(enable: boolean): void {}

  /**
   *
   * Registers a callback to be executed when the application is resumed with a deeplink attached
   *
   * @param onResume - callback to execute on deeplink resume
   */
  public static onDeeplinkResume(onResume: (params?: IDeeplinkParams) => void): void {}

  /**
   * Returns whether the device / OS has a native keyboard available
   */
  public static isNativeKeyboardAvailable(): boolean {
    return false;
  }

  /**
   * Returns whether typing with a plugged-in keyboard is disabled or not
   */
  public static isDisableTypingKeyboard(): boolean {
    return false;
  }

  /**
   * Adds a callback to be executed after the keyboard is shown
   * @param callback - the callback function when keyboard is shown
   */
  public static onAfterKeyboardShown(callback: () => void): void {}

  /**
   * Adds a callback to be executed after the keyboard is hidden
   * @param callback - the callback function when keyboard is hidden
   */
  public static onAfterKeyboardHidden(callback: () => void): void {}

  /**
   * Hides the static start splash screen
   * @param  props - ISplashVideoProps
   */
  public static hideStaticSplash(props: ISplashVideoProps = {}): void {
    AbstractDevice.dismissSplash({
      ...props,
      onReady: () => {
        document.getElementById('splash')?.classList.add('hide');
        document.getElementById('spinner')?.classList.add('hide');
      }
    });
  }

  /**
   * Removes splash screen and video when completed
   */
  public static dismissSplash(props: ISplashVideoProps & { onReady: VoidFunction }): void {
    const video = document.getElementsByTagName('video')[0];

    if (video && !props.skipVideo) {
      let retries = 0;
      // eslint-disable-next-line prefer-const
      let videoComplete!: () => void;
      try {
        const timeout = window.setTimeout(() => videoComplete(), 6000);
        const splashVideoPlayback = (): void => {
          const cleanupSplashVideo = (): void => {
            if (video) {
              /**
               * Resets the media element to its initial state before we remove it.
               * This also forces gc to do a cleanup and empty the SourceBuffer.
               *
               * It fixes the issue with full SourceBuffer that is happening
               * on Vodafone VSB device but could also happen on other devices due to
               * short time for gc to clear the buffer between splash video play
               * and main content playback when deeplinking directly to playback.
               */
              video.load();
              video.removeEventListener('ended', videoComplete!);
              video.onerror = null;
              video.parentElement?.removeChild(video);
            }
          };

          videoComplete = () => {
            window.clearTimeout(timeout);
            cleanupSplashVideo();
            props.onComplete?.(); //show app
          };

          if (video.getAttribute('data-loaded')) {
            props.onReady();
            video.addEventListener('ended', videoComplete);

            const playSplashVideo = async (): Promise<void> => {
              await video.play();
            };

            playSplashVideo().catch(() => {
              video.muted = true; // Retry muted to avoid Google Chrome autoplay policy

              playSplashVideo().catch((error) => {
                props.onError?.(error);
                videoComplete();
              });
            });
          } else {
            if (retries++ > 9) {
              props.onError?.('Reached maximum retries while trying to play splash video.');
              props.onReady();
              cleanupSplashVideo();
              props.onComplete?.(); //show app
              return;
            }
            setTimeout(splashVideoPlayback, 300);
          }
        };
        splashVideoPlayback();
      } catch (error: unknown) {
        props.onError?.(error);
        if (video) {
          video.removeEventListener('ended', videoComplete!);
          video.parentElement?.removeChild(video);
        }
        props.onReady();
        props.onComplete?.(); //show app
      }
    } else {
      if (video) {
        video.onerror = null;
        video.parentElement?.removeChild(video);
      }
      props.onReady();
      props.onComplete?.(); //show app
    }
  }

  /**
   * Hook for updating preview rails, universal guide etc
   */
  public static onUserActivity(translation: ILocalizationService, profile: IProfileInfo | undefined): void {}

  /**
   * Hook for sending certain playback events to the platform
   */
  public static onPlayerEvent(type: string, currentStream?: IStreamMetadata): void {}

  /**
   * Returns the closed captions state on a platform level if available
   */
  public static async getClosedCaptionsStateAsync(): Promise<boolean | undefined> {
    return undefined;
  }

  /**
   * Returns the subtitle state on a platform level if available
   */
  public static async getSubtitleStateAsync(): Promise<boolean | undefined> {
    return undefined;
  }

  /**
   * Get the audio description active state of the platform if available
   */
  public static async getAudioDescriptionStateAsync(): Promise<boolean | undefined> {
    return undefined;
  }

  /**
   * Set listener for device closed captions preference
   * @param listener - the function to handle closed captions preference change
   * @returns function that removes the closed caption listener
   */
  public static addDeviceClosedCaptionsListener(listener: (isCCEnabled: boolean) => void): VoidFunction {
    return () => {};
  }

  /**
   * sets a listener for toggling subtitles on/off in the device level (when available)
   * @param listener - the function to handle closed captions preference change
   * @returns function that removes the subtitles listener
   */
  public static addDeviceSubtitlesListener(listener: (isCCEnabled: boolean) => void): VoidFunction {
    return () => {};
  }

  /**
   * Informs whether device's ARIA support needs extra compatibility effort
   */
  public static useSafeAriaAnnounce(): boolean {
    return false;
  }

  /**
   * Use custom aria support functionality
   */
  public static useCustomAnnouncer(): ICustomAnnouncer | undefined {
    return undefined;
  }

  /**
   * Get application binary version from the url params.
   */
  public static async getBinaryVersionAsync(): Promise<string | undefined> {
    const urlParams = this.getURLParams();
    return urlParams.get('binaryVersion') || undefined;
  }

  /**
   * Detect change in app visibility to determine if app is in foreground or background
   *
   * @param setVisibility - callback to disptach AppLifecycle
   */
  public static onAppVisibilityChange(setVisibility: (visibility: boolean) => void): void {
    // @ts-ignore
    const isWebkit = typeof document.webkitHidden !== 'undefined';
    document.addEventListener(
      isWebkit ? 'webkitvisibilitychange' : 'visibilitychange',
      function () {
        // @ts-ignore
        if (document[isWebkit ? 'webkitHidden' : 'hidden']) {
          setVisibility(false);
        } else {
          setVisibility(true);
        }
      },
      true
    );
  }

  /**
   * Initialise Voice interface.
   */
  public static initializeVoice(isInPlayer: () => boolean): boolean {
    return false;
  }
}

/**
 * device implementation interface
 * @public
 */
export type IDevice = Omit<typeof AbstractDevice, 'prototype'>;
