// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { FormatCode } from '../../nominals';
import { isFormatCode } from '../type-checkers';

/**
 * Adds "FormatCode" to the type of the given code, without reducing the specificity of its type.
 *
 * @public
 */
export function asFormatCode<TCode extends string>(code: TCode): TCode & FormatCode {
  if (isFormatCode(code)) {
    return code;
  } else {
    throw new Error(`Code "${code}" is not a FormatCode`);
  }
}
