// Copyright text placeholder, Warner Bros. Discovery, Inc.

/* eslint-disable no-unused-vars */

import type {
  IPurchasePlan,
  PaymentProvider,
  ISubscriptionPayloadMVPD,
  IStoreReceipt,
  IUnlinkedReceipt,
  ICreateChangePlanRequestPayload,
  ICreateChangePlanRequestResult
} from './types';
import { SubscriptionFlow } from './types';
import type { IStorage } from '@wbd/beam-dom-extensions';

/**
 * Abstract commerce implementation
 * @public
 */
export class AbstractCommerce {
  /**
   * Let you know which kind of subscription flow to deal depending on the platform
   * @returns SubscriptionFlow
   */
  public static getSubscriptionFlow(): SubscriptionFlow {
    return SubscriptionFlow.DTC;
  }

  /**
   * This method will be used until Paywall and Subscription on device pages are provided by Sonic. If this method return false it means user can complete
   * registration on the MVPD provider page instead of our app(either web, apps or CTV)
   * @returns Boolean
   */
  public static canCompleteSubscriptionOnDevice(): boolean {
    return true;
  }

  /**
   * This method to be used to know which payment provider has to be use for the subscription-journey/start service
   * @public
   */
  public static getPaymentProvider(): PaymentProvider {
    throw new Error('getPaymentProvider not implemented');
  }

  /**
   * This method will retrieve the subscriptions plans from the store, this method should be implemented for IAP flows
   * @returns IPurchasePlan[]
   */
  public static getStoreSubscriptionPlansIAP(): Promise<IPurchasePlan[]> {
    return Promise.resolve([]);
  }

  /**
   * This method will start the payment flow in the IAP store and will return a success once the payment is done
   * @param purchasePlan - IPurchasePlan
   * @param customerId - string - The customer ID
   * @returns Promise<IStoreReceipt>
   */
  public static purchasePlanInStoreIAP(
    purchasePlan: IPurchasePlan,
    customerId: string
  ): Promise<IStoreReceipt> {
    throw new Error('purchasePlanInStoreIAP not implemented');
  }

  /**
   * MVPD method to redirect the user to their store, some use cases will allow a redirect url(deep link)
   * @param subscriptionPayload - ISubscriptionPayloadMVPD
   */
  public static subscribePlanWithMVPD(subscriptionPayload: ISubscriptionPayloadMVPD): void {
    throw new Error('subscribePlanWithMVPD not implemented');
  }

  /**
   * Returns an unlinked receipt
   * @param storage - IStorage
   * @returns IUnlinkedReceipt | undefined
   */
  public static getUnlinkedReceipt(storage: IStorage): IUnlinkedReceipt | undefined {
    return undefined;
  }

  /**
   * Removes any unlinked receipt
   * @param storage - IStorage
   */
  public static cleanUnlinkedReceipt(storage: IStorage): void {}

  /**
   * Stores an unlinked receipt
   * @param payload - IUnlinkedReceipt
   * @param storage - IStorage
   */
  public static storeUnlinkedReceipt(payload: IUnlinkedReceipt, storage: IStorage): void {}

  /**
   * Creates the request to execute a change plan for IAP platforms.
   * When the transition is allowed, it also returns the date when the change plan will be effective.
   * @param payload - ICreateChangePlanRequestPayload
   * @returns Promise<ICreateChangePlanRequest>
   */
  public static async createChangePlanRequest(
    payload: ICreateChangePlanRequestPayload
  ): Promise<ICreateChangePlanRequestResult> {
    throw new Error('createChangePlanRequest not implemented');
  }
}

/**
 * device implementation interface
 * @public
 */
export type ICommerce = Omit<typeof AbstractCommerce, 'prototype'>;
