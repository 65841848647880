// Copyright text placeholder, Warner Bros. Discovery, Inc.

/* eslint-disable @typescript-eslint/naming-convention */

import type { ILabsDecisionValidator } from '@wbd/labs-sdk';
import type { IPlayerResiliencyConfigCore } from '@wbd/player-sdk-core';
import type {
  ICDEVRoutesConfig,
  ICDevFeatureFlagConfig,
  IIPDComponentAllowlistConfig,
  IISDKConfig,
  IImmersiveHeroConfig,
  ILiveEventMetadataConfig,
  ILiveLinearMetadataConfig,
  IMenuAliasConfig,
  IPlayerDisableRetryButtonOnErrorViewConfig,
  IPrivacyAliasConfig,
  IRailNormaliserConfig,
  IRatingsRegionalVisibilityRulesConfig,
  ISportsSkinConfig,
  IUSIabApplicableStatesConfig,
  IWelcomeRoutesConfig,
  IWelcomeScreenConfig
} from './ICDevFeatureFlagConfig';

const isObject = (value: unknown): value is object => typeof value === 'object' && value !== null;

const isValidNumber = (value: unknown): value is number => typeof value === 'number' && !isNaN(value);

type INeverProperties<T> = {
  [K in keyof T]: T[K] extends never ? K : never;
}[keyof T];

type ICDevFeatureFlagValidators = Omit<
  {
    [key in keyof ICDevFeatureFlagConfig]: ILabsDecisionValidator<ICDevFeatureFlagConfig[key]>;
  },
  INeverProperties<ICDevFeatureFlagConfig>
>;

/**
 * Default feature flag value validators
 */
export const LABS_FLAGS_VALIDATORS: ICDevFeatureFlagValidators = {
  CDEV_Routes: {
    validConfig: {
      /**
       * Paywall sonic route
       */
      paywall: '/paywall/ctv',
      /**
       * Configuration for deep linking to the Privacy and Terms tab of the settings page.
       */
      settingsPrivacyAndTerms: '/settings/settings-privacy',
      /**
       * Subscription route from settings
       */
      settingsSubscription: '/ctv-settings'
    },
    isValid: (config: unknown): config is ICDEVRoutesConfig => {
      if (isObject(config)) {
        const configKeys = Object.keys(config);
        const configValues = Object.values(config);
        if (
          configKeys.includes('paywall') &&
          configKeys.includes('settingsPrivacyAndTerms') &&
          configKeys.includes('settingsSubscription') &&
          configValues.every((value) => typeof value === 'string')
        ) {
          return true;
        }
      }
      return false;
    }
  },

  config_isdk: {
    validConfig: {
      forwarders: []
    },
    isValid: (config: unknown): config is IISDKConfig => true
  },

  Immersive_Hero: {
    validConfig: {
      delay: 13000
    },
    isValid: (config: unknown): config is IImmersiveHeroConfig => {
      if (isObject(config)) {
        const { delay } = config as IImmersiveHeroConfig;
        if (isValidNumber(delay)) {
          return true;
        }
      }
      return false;
    }
  },

  Live_Event_Metadata: {
    validConfig: {
      liveEdgeOffsetSeconds: 10
    },
    isValid: (config: unknown): config is ILiveEventMetadataConfig => {
      if (isObject(config)) {
        const { liveEdgeOffsetSeconds } = config as ILiveEventMetadataConfig;
        if (isValidNumber(liveEdgeOffsetSeconds)) {
          return true;
        }
      }
      return false;
    }
  },

  Live_Linear_Metadata: {
    validConfig: {
      liveLinearPrefetchMinutes: 5
    },
    isValid: (config: unknown): config is ILiveLinearMetadataConfig => {
      if (isObject(config)) {
        const { liveLinearPrefetchMinutes } = config as ILiveLinearMetadataConfig;
        if (isValidNumber(liveLinearPrefetchMinutes)) {
          return true;
        }
      }
      return false;
    }
  },

  Menu_Alias: {
    validConfig: {
      alias: 'ctv-menu-bar'
    },
    isValid: (config: unknown): config is IMenuAliasConfig => {
      if (isObject(config)) {
        const { alias } = config as IMenuAliasConfig;
        if (typeof alias === 'string') {
          return true;
        }
      }
      return false;
    }
  },

  player_config_resiliency: {
    validConfig: {
      device_models_rule: {
        device_models: [],
        model_list_type: 'exclusive'
      },
      value: {
        drm_license: {},
        engine: {
          network: {}
        },
        pir: {
          default: {
            backoffTimeMs: 0,
            retry_count: 0
          },
          requestTimeoutMs: 20000
        },
        version: ''
      }
    },
    isValid: (config: unknown): config is IPlayerResiliencyConfigCore => {
      if (isObject(config)) {
        const { device_models_rule, value } = config as IPlayerResiliencyConfigCore;

        const isDeviceModelsRuleValid =
          isObject(device_models_rule) &&
          Array.isArray(device_models_rule.device_models) &&
          typeof device_models_rule.model_list_type === 'string';

        const isValueValid =
          isObject(value) &&
          isObject(value.drm_license) &&
          isObject(value.engine) &&
          isObject(value.engine.network) &&
          typeof value.version === 'string';

        let isPIRValid = false;
        if (isValueValid) {
          const { pir } = value;
          isPIRValid =
            isObject(pir) &&
            isObject(pir.default) &&
            isValidNumber(pir.default.backoffTimeMs) &&
            isValidNumber(pir.default.retry_count) &&
            isValidNumber(pir.requestTimeoutMs);
        }

        return isDeviceModelsRuleValid && isValueValid && isPIRValid;
      }
      return false;
    }
  },

  Privacy_Alias: {
    validConfig: {
      privacyTermsCollectionAlias: 'privacy-terms-ctv'
    },
    isValid: (config: unknown): config is IPrivacyAliasConfig => {
      if (isObject(config)) {
        const { privacyTermsCollectionAlias } = config as IPrivacyAliasConfig;
        if (typeof privacyTermsCollectionAlias === 'string') {
          return true;
        }
      }
      return false;
    }
  },

  'ratings-regional-visibility-rules': {
    validConfig: {
      visibilityDurationMs: 7000,
      visibilityTriggers: ['MAIN_CONTENT_PLAYBACK_START']
    },
    isValid: (config: unknown): config is IRatingsRegionalVisibilityRulesConfig => {
      if (isObject(config)) {
        const configKeys = Object.keys(config);
        const { visibilityDurationMs, visibilityTriggers } = config as IRatingsRegionalVisibilityRulesConfig;
        if (
          configKeys.includes('visibilityDurationMs') &&
          configKeys.includes('visibilityTriggers') &&
          isValidNumber(visibilityDurationMs) &&
          Array.isArray(visibilityTriggers) &&
          visibilityTriggers.length > 0 &&
          visibilityTriggers.every((value) => typeof value === 'string')
        ) {
          return true;
        }
      }
      return false;
    }
  },

  Rail_Normaliser_V2: {
    validConfig: {},
    isValid: (config: unknown): config is IRailNormaliserConfig => {
      if (isObject(config)) {
        const configKeys = Object.keys(config);
        const configValues = Object.values(config);
        if (
          configKeys.every((value) => typeof value === 'string') &&
          configValues.every((value) => typeof value === 'boolean')
        ) {
          return true;
        }
      }
      return false;
    }
  },

  IPD_Component_Allowlist: {
    validConfig: {
      allowlist: ['16-9', 'multilevel', 'tabbed-content', 'timeline-markers']
    },
    isValid: (config: unknown): config is IIPDComponentAllowlistConfig => {
      if (isObject(config)) {
        const { allowlist } = config as IIPDComponentAllowlistConfig;
        if (Array.isArray(allowlist) && allowlist.every((value) => typeof value === 'string')) {
          return true;
        }
      }
      return false;
    }
  },

  Sports_Skin_Config: {
    validConfig: {},
    isValid: (config: unknown): config is ISportsSkinConfig => {
      if (isObject(config)) {
        return true;
      }
      return false;
    }
  },

  US_Iab_Applicable_States: {
    validConfig: {
      applicableStates: ['CA', 'CO', 'CT', 'VA', 'UT', 'MT', 'TX', 'OR']
    },
    isValid: (config: unknown): config is IUSIabApplicableStatesConfig => {
      if (isObject(config)) {
        const { applicableStates } = config as IUSIabApplicableStatesConfig;
        if (Array.isArray(applicableStates) && applicableStates.every((value) => typeof value === 'string')) {
          return true;
        }
      }
      return false;
    }
  },

  Welcome_Screen: {
    validConfig: {
      firstPageBackground: '',
      secondPageBackground: '',
      logoUrl: ''
    },
    isValid: (config: unknown): config is IWelcomeScreenConfig => {
      if (isObject(config)) {
        const configKeys = Object.keys(config);
        const configValues = Object.values(config);
        if (
          configKeys.includes('firstPageBackground') &&
          configKeys.includes('secondPageBackground') &&
          configKeys.includes('logoUrl') &&
          configValues.every((value) => typeof value === 'string')
        ) {
          return true;
        }
      }
      return false;
    }
  },

  Welcome_Config_Routes: {
    validConfig: {
      welcomeBackRoute: '/welcome-back/tvn'
    },
    isValid: (config: unknown): config is IWelcomeRoutesConfig => {
      if (isObject(config)) {
        const { welcomeBackRoute } = config as IWelcomeRoutesConfig;
        if (typeof welcomeBackRoute === 'string') {
          return true;
        }
      }
      return false;
    }
  },

  player_disable_retry_button_on_error_view: {
    validConfig: {
      device_models_rule: {
        device_models: [],
        model_list_type: 'exclusive'
      },
      value: {
        stream_modes: []
      }
    },
    isValid: (config: unknown): config is IPlayerDisableRetryButtonOnErrorViewConfig => {
      if (isObject(config)) {
        const { device_models_rule, value } = config as IPlayerDisableRetryButtonOnErrorViewConfig;

        const isDeviceModelsRuleValid =
          isObject(device_models_rule) &&
          Array.isArray(device_models_rule.device_models) &&
          typeof device_models_rule.model_list_type === 'string';

        const isValueValid = isObject(value) && Array.isArray(value.stream_modes);

        return isDeviceModelsRuleValid && isValueValid;
      }
      return false;
    }
  }
};
