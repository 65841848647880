// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Set delayFactor 1000 for an exponential delay to occur on the order  of seconds
 * @param retryNumber - retry count number
 * @param error - unused; for existing API of retryDelay callback
 * @param delayFactor - delay factor in milliseconds
 * @returns
 */
export function exponentialDelay(
  retryNumber: number = 0,
  error?: unknown,
  delayFactor: number = 100
): number {
  const delay = Math.pow(2, retryNumber) * delayFactor;
  const randomSum = delay * 0.2 * Math.random(); // 0-20% of the delay
  return delay + randomSum;
}
