// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  FEATURE_FLAG_DEFAULTS,
  type FeatureFlag,
  type FeatureFlagConfiguration
} from './FeatureFlagConfiguration';
import { type IFeatureFlagState, getDefaultFlagState } from './FeatureFlagState';
import { type IFeatureFlagStorage, featureFlagStorage } from './FeatureFlagStorage';

import type { IPlatformAdapter } from '@wbd/beam-ctv-platform-adapters';
import type { Writable } from 'type-fest';

/**
 * Helper function to initialize feature flag state from url and storage environments
 * @param platformAdapter - device abstraction adapter
 * @returns
 */
export function featureFlagStateInitialization(platformAdapter: IPlatformAdapter): IFeatureFlagState {
  const storage: IFeatureFlagStorage = featureFlagStorage(platformAdapter);

  // 0. Get initial state configuration.
  let state: IFeatureFlagState = getDefaultFlagState();

  // 1. Override initial state with configuration from device storage, if applicable.
  state = readStateFromStorage(state, storage);

  // 2. Override feature-flag configuration with URL config, if applicable.
  state = setupFeatureFlagsFromUrl(state, platformAdapter);

  // 3. Remove unknown flags from state, if applicable.
  state = pruneUnknownFlags(state);

  // 4. Store synchronized state back to the device.
  storage.write(state);

  return state;
}

/**
 * Validate integrity of the feature flag state coming from storage
 * @param shapeReference - new state
 * @param shapeToCompare - state to compare with
 * @returns
 */
export function checkStateIntegrity(shapeReference: unknown, shapeToCompare: unknown): boolean {
  // `typeof []` and `typeof null` both return 'object' so we have to special case them

  if (Array.isArray(shapeReference) && Array.isArray(shapeToCompare)) {
    if (shapeReference.length !== shapeToCompare.length) {
      return false;
    }

    /**
     * This validation compares the elements inside the array:
     * - [1,2,3] x [3,4,5] = will be true because the index has the same type
     * - [1,2,3] x [3,"4", 5] = will be false because index 1 has different type in both
     *
     * I won't too deep and check the objects and arrays inside because this edge
     * case is really rare.
     */
    for (let index = 0; index < shapeReference.length; index++) {
      if (!checkStateIntegrity(shapeReference[index], shapeToCompare[index])) {
        return false;
      }
    }

    return true;
  } else if (Array.isArray(shapeReference) || Array.isArray(shapeToCompare)) {
    return false;
  }

  if (typeof shapeReference === 'object' && typeof shapeToCompare === 'object') {
    if (shapeReference === null && shapeToCompare === null) {
      return true;
    } else if (shapeReference === null || shapeToCompare === null) {
      return false;
    }

    /**
     * In case both are objects, I want to do a recursion which will run
     * all validations again. Edge case: nested objects
     */
    const referenceKeys = Object.keys(shapeReference);
    const keysToCompare = Object.keys(shapeToCompare);

    if (referenceKeys.length !== keysToCompare.length) {
      return false;
    }

    /**
     * We need to ensure that the key in the reference object
     * is present in the object compared.
     */
    for (const referenceKey of referenceKeys) {
      if (!(referenceKey in shapeToCompare)) {
        return false;
      }

      /**
       * We need to compare if the value for that specific key has
       * the same type in both objects
       */
      const referenceValue = (shapeReference as Record<string, unknown>)[referenceKey];
      const valueToCompare = (shapeToCompare as Record<string, unknown>)[referenceKey];

      if (!checkStateIntegrity(referenceValue, valueToCompare)) {
        return false;
      }
    }

    return true;
  }

  return typeof shapeReference === typeof shapeToCompare;
}

/**
 * Get latest state from storage and validate integrity
 * @param currentState - current state
 * @param featureFlagStorage - feature flag storage instance
 * @returns
 */
function readStateFromStorage(
  currentState: IFeatureFlagState,
  featureFlagStorage: IFeatureFlagStorage
): IFeatureFlagState {
  const savedState = featureFlagStorage.read();
  if (!savedState) {
    return currentState;
  }

  const isStoredStateValid = checkStateIntegrity(currentState, savedState);
  if (!isStoredStateValid) {
    featureFlagStorage.remove();
    return currentState;
  }

  currentState.features = {
    ...currentState.features,
    ...savedState.features
  };

  return currentState;
}

/**
 * Initialize feature-flags state from url query param
 * @param currentState - current state
 * @param platformAdapter - device abstraction adapter
 */
function setupFeatureFlagsFromUrl(
  currentState: IFeatureFlagState,
  platformAdapter: IPlatformAdapter
): IFeatureFlagState {
  /**
   * Ensure that we still can force a flag via query parameters:
   * http://localhost:8080/?flags=AvodManager,Mouse,SkipSchemaValidation
   */
  const flagsViaQuery = platformAdapter.device.getURLParams().get('flags');
  if (flagsViaQuery) {
    const flags = flagsViaQuery.split(',') as FeatureFlag[];

    for (const flag of flags) {
      const targetFlagConfiguration = currentState.features[flag];
      if (targetFlagConfiguration) {
        targetFlagConfiguration.isEnabled = true;
      }
    }
  }

  return currentState;
}

/**
 * Loop through current state's list of feature-flag and compare with our default configuration.
 * If any
 */
function pruneUnknownFlags(currentState: IFeatureFlagState): IFeatureFlagState {
  const validState = Object.assign({}, currentState);

  const featureFlagKeys = Object.keys(validState.features) as FeatureFlag[];

  featureFlagKeys.forEach((featureFlag: FeatureFlag) => {
    const hasMatchingFeatureFlag = FEATURE_FLAG_DEFAULTS[featureFlag];
    if (!hasMatchingFeatureFlag) {
      delete (validState.features as Writable<Partial<FeatureFlagConfiguration>>)[featureFlag];
    }
  });

  return validState;
}
