// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Feature flag keys
 */
export type FeatureFlag =
  | 'StartupMilestones'
  | 'Hospitality'
  | 'DisableMouse'
  | 'DisableDevtools'
  | 'PlayerDebug'
  | 'PlayerDebugLite'
  | 'DomCue'
  | 'LightningInspector'
  | 'OpenBrowse'
  | 'ClearStorage'
  | 'DisableInputThrottling'
  | 'ProfilePersistence'
  | 'ChangePlan';

/**
 * Feature flag values
 */
export interface IFeatureFlagValues {
  description: string;
  isEnabled: boolean;
}

/**
 * Feature flag typed list
 */
export type FeatureFlagConfiguration = {
  readonly [key in FeatureFlag]: IFeatureFlagValues;
};

/**
 * Default feature flag configuration
 */
export const FEATURE_FLAG_DEFAULTS: FeatureFlagConfiguration = {
  StartupMilestones: {
    description: 'Displays a debug panel with startup time information',
    isEnabled: false
  },
  Hospitality: {
    description: 'Enable hospitality in the application',
    isEnabled: false
  },
  DisableMouse: {
    description: 'Disables mouse in the application',
    isEnabled: false
  },
  DisableDevtools: {
    description: 'Disables performance monitor and flashing element removals when running in DEBUG',
    isEnabled: false
  },
  PlayerDebug: {
    description: 'Enable extra debugging UI in the player',
    isEnabled: false
  },
  PlayerDebugLite: {
    description: 'Lite version of the debugging UI in the player (enabled by default on INT & STG)',
    isEnabled: true
  },
  DomCue: {
    description: 'Use DOM renderer for subtitles in video page',
    isEnabled: false
  },
  LightningInspector: {
    description: 'Enables the Lightning.js DOM inspector',
    isEnabled: false
  },
  OpenBrowse: {
    description:
      'Enables unrestricted navigation through the app without requiring authentication credentials',
    isEnabled: false
  },
  ClearStorage: {
    description: 'Whether localStorage should be cleared upon application start',
    isEnabled: false
  },
  DisableInputThrottling: {
    description: 'Disables keyboard input throttling',
    isEnabled: false
  },
  ProfilePersistence: {
    description: 'Enables Profile persistence flow',
    isEnabled: false
  },
  ChangePlan: {
    description: 'Enables support the change plan flow for Tizen',
    isEnabled: false
  }
};
