// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { RequestConfigOverride } from '../../http-client';
import { HttpClient } from '../../http-client';
import type { IUserResponse } from './interfaces/IUserResponse';

/**
 * Gets info about the user connected to the supplied token
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getUserRequest = async (config?: RequestConfigOverride): Promise<IUserResponse> => {
  const response = await HttpClient.instance.get<IUserResponse>('/users/me', config);
  return response.data;
};
