// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IBootstrapConfigJson, IRequestHeaders } from '@wbd/bolt-http';

import { hasInvalidTokenError } from '../errors';
import type { RequestConfigOverride } from '../http-client';
import { HttpClient } from '../http-client';
import type { IBootstrapRequestPayload, IBootstrapResponseJson } from './IBootstrapRequest';

/**
 * @public
 * Bootstrap service url
 */
export const BOOTSTRAP_URL: string = `/session-context/headwaiter/v1/bootstrap`;

/**
 * Fetches the bootstrap client config
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const getBootstrap = async (
  config?: RequestConfigOverride,
  {
    multiverseTransportPayload,
    updateBootstrapConfig = true
  }: {
    multiverseTransportPayload?: string;
    updateBootstrapConfig?: boolean;
  } = {}
): Promise<IBootstrapResponseJson> => {
  const http = HttpClient.instance;

  // the x-wbd-ace header will be added using an interceptor configureAnonymousConsentInterceptors
  const headers: IRequestHeaders = {
    ...(multiverseTransportPayload && { 'x-wbd-transport': multiverseTransportPayload })
  };

  const payload: IBootstrapRequestPayload = {};
  try {
    const response = await http.post<IBootstrapConfigJson, IBootstrapRequestPayload>(BOOTSTRAP_URL, payload, {
      headers,
      bypassBootstrapRefreshLock: true,
      signal: config?.signal
    });

    if (updateBootstrapConfig) {
      // update bolt-http bootstrap config
      // after a successful bootstrap call
      http.bootstrapConfig?.update(response.data);
    }
    return response.data;
  } catch (error) {
    // expect clients to retry invalid token errors
    if (hasInvalidTokenError(error)) {
      throw error;
    }

    const restoredConfig = http.bootstrapConfig?.restore();

    if (restoredConfig) {
      return restoredConfig;
    }

    throw error;
  }
};
