// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { getConfig, type IBoltConfig } from '@wbd/bolt-dataservice';

export const getGeoblock = async (): Promise<boolean> => {
  let isRegionAllowed = false;
  const CMS_CONFIG_NAME: string = 'geoblock';
  try {
    const geoblockConfig: IBoltConfig = await getConfig(CMS_CONFIG_NAME);
    isRegionAllowed = !!geoblockConfig;
  } catch (error) {
    if (error.response.status === 404) isRegionAllowed = false;
  }
  return isRegionAllowed;
};
