// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { Platform } from '@wbd/bolt-http';
import {
  UiLaunchErrorClassification,
  UiLaunchErrorLaunchState,
  UiLaunchErrorScope,
  generateErr,
  iUiLaunchError,
  type IUiLaunchErrorErr
} from '../instrumentation';
import { bootstrapAppAsync, type IBootstrapAppOptions } from './bootstrapAppAsync';
import { loadCanvasErrorFallback } from './loadUIFallback';
import { resolvePlatformValues } from './resolvePlatformValues';

/**
 * Sync wrapper for bootstrap to capture uncaught promise exceptions
 * @param config - bootstrap configuration
 */
export function bootstrapApp(config: IBootstrapAppOptions): void {
  require('./main.css');

  bootstrapAppAsync(config)
    .catch(async (error) => {
      iUiLaunchError.capture({
        err: generateErr<IUiLaunchErrorErr>(error, {
          scope: UiLaunchErrorScope.USER_FACING,
          classification: UiLaunchErrorClassification.UNKNOWN
        }),
        launchState: UiLaunchErrorLaunchState.INITIALIZING
      });

      const { platform, commercePartnerId } = await resolvePlatformValues(config);

      // present user fallback message
      loadCanvasErrorFallback({ ...config, platform, commercePartnerId, startupError: error });
    })
    .catch((error) => {
      loadCanvasErrorFallback({
        ...config,
        platform: Platform.TVSET,
        commercePartnerId: undefined,
        startupError: error
      });
    });
}
