// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  FEATURE_FLAG_DEFAULTS,
  type FeatureFlag,
  type FeatureFlagConfiguration,
  type IFeatureFlagValues
} from './FeatureFlagConfiguration';
import { type IFeatureFlagStorage, featureFlagStorage } from './FeatureFlagStorage';

import type { IPlatformAdapter } from '@wbd/beam-ctv-platform-adapters';
import { featureFlagStateInitialization } from './FeatureFlagStateInitialization';

export interface IFeatureFlagService {
  getAllFlags(): FeatureFlag[];
  getEnabledFlags(): FeatureFlag[];
  isDebug(): boolean;
  isEnabled(featureFlag: FeatureFlag): boolean;
  getFlagDescription(featureFlag: FeatureFlag): string;
  setState(featureFlag: FeatureFlag, isEnabled: boolean): void;
  enable(featureFlag: FeatureFlag): void;
  disable(featureFlag: FeatureFlag): void;
}

export class FeatureFlagService implements IFeatureFlagService {
  /**
   * Internal configuration storing all feature flags & current enabled/disabled state.
   */
  private _featureFlags: FeatureFlagConfiguration = FEATURE_FLAG_DEFAULTS;

  /**
   * Instance of device level feature flag storage
   */
  private _featureFlagStorage: IFeatureFlagStorage;

  /**
   * Debug flag
   */
  private _isDebug: boolean = false;

  /**
   * Construct new instance of feature-flag manager
   * @param storage - abstracted device storage
   * @param features - feature-flags
   */
  protected constructor(storage: IFeatureFlagStorage, features?: FeatureFlagConfiguration) {
    this._featureFlagStorage = storage;

    // Set current feature-flag configuration to fetched configuration.
    if (features) this._featureFlags = features;
  }

  /**
   * Create the FeatureFlag manager.
   *
   * This gets environment and feature-flags from existing storage & URL configuration.
   */
  public static create({
    isEnabled,
    platformAdapter
  }: {
    isEnabled: boolean;
    platformAdapter: IPlatformAdapter;
  }): FeatureFlagService {
    const { features } = featureFlagStateInitialization(platformAdapter);
    const storage = featureFlagStorage(platformAdapter);
    if (isEnabled) {
      const service = new FeatureFlagService(storage, features);
      service._isDebug = true;

      return service;
    } else {
      return new FeatureFlagService(storage);
    }
  }

  /**
   * Get whether debug is enabled or not.
   * @returns
   */
  public isDebug(): boolean {
    return this._isDebug;
  }

  /**
   * Get all feature-flags for external usage.
   *
   * Note: Returns an immutable object to avoid malicious usage.
   */
  public getAllFlags(): FeatureFlag[] {
    const immutableFlagConfiguration = Object.assign({}, this._featureFlags);

    const mappedFlagConfiguration = Object.entries(immutableFlagConfiguration) as [
      FeatureFlag,
      IFeatureFlagValues
    ][];
    const allFeatureFlags = mappedFlagConfiguration.map(([flag]) => flag);

    return allFeatureFlags;
  }

  /**
   * Get all enabled feature-flags for external usage.
   *
   * Note: Returns an immutable object to avoid malicious usage.
   */
  public getEnabledFlags(): FeatureFlag[] {
    const immutableFlagConfiguration = Object.assign({}, this._featureFlags);

    const mappedFlagConfiguration = Object.entries(immutableFlagConfiguration) as [
      FeatureFlag,
      IFeatureFlagValues
    ][];
    const filteredConfiguration = mappedFlagConfiguration.filter(([, config]) => config.isEnabled);
    const enabledFlags = filteredConfiguration.map(([flag]) => flag);

    return enabledFlags;
  }

  /**
   * Check ifs the target feature-flag currently enabled.
   *
   * @param featureFlag - FeatureFlag being checked.
   * @returns boolean
   */
  public isEnabled(featureFlag: FeatureFlag): boolean {
    return this._featureFlags[featureFlag].isEnabled;
  }

  /**
   * Get a feature flag description
   *
   * @param featureFlag - FeatureFlag being checked.
   * @returns string
   */
  public getFlagDescription(featureFlag: FeatureFlag): string {
    return this._featureFlags[featureFlag].description;
  }

  /**
   * Set specific feature-flags enabled/disabled state.
   *
   * @param featureFlag - FeatureFlag which states is being updated.
   * @param isEnabled - boolean state of target flag.
   */
  public setState(featureFlag: FeatureFlag, isEnabled: boolean): void {
    this._updateFlagState(featureFlag, isEnabled);
  }

  /**
   * Set specific feature-flag to enabled.
   *
   * @param featureFlag - FeatureFlag which states is being enabled.
   */
  public enable(featureFlag: FeatureFlag): void {
    this._updateFlagState(featureFlag, true);
  }

  /**
   * Set specific feature-flag to disabled.
   *
   * @param featureFlag - FeatureFlag which states is being disabled.
   */
  public disable(featureFlag: FeatureFlag): void {
    this._updateFlagState(featureFlag, false);
  }

  /**
   * Internal feature-flag state-update.
   * This also writes current FeatureFlagService state to device storage.
   *
   * @param featureFlag - FeatureFlag which states is being updated.
   * @param isEnabled - boolean state of target flag.
   */
  private _updateFlagState(featureFlag: FeatureFlag, isEnabled: boolean): void {
    this._featureFlags[featureFlag].isEnabled = isEnabled;
    this._storeCurrentState();
  }

  /**
   * Store environment and feature-flag configuration to device storage.
   */
  private _storeCurrentState(): void {
    const currentState = {
      features: this._featureFlags
    };

    this._featureFlagStorage.write(currentState);
  }
}
