// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { LanguageCode } from '@wbd/localization-core';
import { Resources } from './Resources';

/**
 * Retrieves a list of supported language codes if resources have been loaded; otherwise returns an empty array.
 * @public
 */
export function getClientEnabledLanguageCodes(): LanguageCode[] {
  return Resources.getClientEnabledLanguageCodes !== undefined
    ? Resources.getClientEnabledLanguageCodes()
    : [];
}
