// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IFeatureFlagState } from './FeatureFlagState';
import type { IPlatformAdapter } from '@wbd/beam-ctv-platform-adapters';

/**
 * local storage key for holding feature flag information
 */
export const FEATURE_FLAG_STORAGE_KEY: 'feature-flag' = 'feature-flag';

export interface IFeatureFlagStorage {
  /**
   * Get parsed data from feature-flag storage.
   */
  read(): IFeatureFlagState | undefined;

  /**
   * Get the raw data from feature-flag storage.
   */
  readString(): string | undefined;

  /**
   * Write data to feature-flag storage.
   */
  write(debugStore: IFeatureFlagState): void;

  /**
   * Remove all data from feature-flag storage.
   */
  remove(): void;
}

export const featureFlagStorage = function (platformAdapter: IPlatformAdapter): IFeatureFlagStorage {
  return {
    read(): IFeatureFlagState | undefined {
      const stringToParse = this.readString();

      let parsedResult = undefined;

      try {
        if (stringToParse) {
          parsedResult = JSON.parse(stringToParse);
        }
      } catch (exception) {
        parsedResult = undefined;
      }

      return parsedResult;
    },

    readString(): string | undefined {
      return platformAdapter.storage.readSync(FEATURE_FLAG_STORAGE_KEY);
    },

    write(debugStore: IFeatureFlagState): void {
      const stringToStore = JSON.stringify(debugStore);
      platformAdapter.storage.writeSync(FEATURE_FLAG_STORAGE_KEY, stringToStore);
    },

    remove(): void {
      platformAdapter.storage.removeSync(FEATURE_FLAG_STORAGE_KEY);
    }
  };
};
