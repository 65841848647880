// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { Listener } from '@wbd/light-events';
import { EventWithParams } from '@wbd/light-events';
import { LabsResponseCache } from '../labs-response-cache';
import type {
  ILabsDecision,
  ILabsDecisionData,
  ILabsDecisionValidator,
  ILabsSDKEventParams,
  ILabsSDKOptions,
  TLabsResponse
} from '../types';
import { DecisionType, LabsSDKEvents } from '../types';
import { getDecision } from '../utils';

/**
 * Exposes the labs SDK public methods for handling / storing / getting local and service decisions.
 * @public
 * @param decisions - object of [response-keys]: decision state functions {@link ILabsResponse}.
 * @param options - labs sdk options {@link ILabsSDKOptions}.
 */
export class LabsSDK<T = Record<string, unknown>, K extends keyof T = keyof T> {
  private _cache: LabsResponseCache<T>;
  private _decisionKeys: Set<K>;
  private _events: { [key in LabsSDKEvents]: EventWithParams<[ILabsSDKEventParams<key>]> };
  private _localDecisions: Partial<TLabsResponse<T>>;
  private _projectId: string;

  /**
   * Internal constructor to create the labs SDK.
   * @param labsResponse - Object of labs decisions data returned from the labs service
   * @param labsSDKOptions - optional default request configuration {@link ILabsSDKOptions}
   */
  private constructor(cache: LabsResponseCache<T>, { localDecisions = {}, projectId }: ILabsSDKOptions<T>) {
    // Ensure that each local decision has type property set.
    (Object.keys(localDecisions) as K[]).forEach((key) => {
      const decision = localDecisions[key];
      if (decision) {
        decision.type = this._sanitizeLocalDecisionType(decision);
      }
    });

    // Build labs SDK events.
    this._events = {
      [LabsSDKEvents.DecisionAccessed]: new EventWithParams<
        [ILabsSDKEventParams<LabsSDKEvents.DecisionAccessed>]
      >(),
      [LabsSDKEvents.RequestError]: new EventWithParams<[ILabsSDKEventParams<LabsSDKEvents.RequestError>]>()
    };

    // Set the request error handler on cache.
    cache.setRequestErrorEvent(this._events[LabsSDKEvents.RequestError]);

    this._cache = cache;
    this._decisionKeys = this._getResponseKeys(localDecisions);
    this._localDecisions = localDecisions;
    this._projectId = projectId;
  }

  /**
   * Fires a requested LabsSDK event.
   * Avoids blocking synchronous methods from asynchronous EventWithParams.fire().
   * @param type - event type defined in LabsSDKEvents
   * @param params - event arguments defined in ILabsSDKEventParams
   */

  private _fireEvent<E extends LabsSDKEvents>(type: E, params: ILabsSDKEventParams<E>): void {
    this._events[type].fire(params).catch(() => {});
  }

  /**
   * Merges local decisions keys with cached server response keys.
   */
  private _getResponseKeys(localDecisions: Partial<TLabsResponse<T>>): Set<K> {
    return new Set<K>([...Object.keys(localDecisions), ...this._cache.keys()] as K[]);
  }

  /**
   * Ensure that each local decision has type property set.
   * @returns - sanitized local decision.
   */
  private _sanitizeLocalDecisionType(localDecision: ILabsDecisionData<unknown>): DecisionType {
    return localDecision.type === DecisionType.DYNAMIC ? DecisionType.DYNAMIC : DecisionType.STATIC;
  }

  /**
   * Create a new instance of labs SDK.
   * @param options - labs configuration.
   * @returns LabsSDK promise.
   */
  public static async create<T = Record<string, unknown>>(options: ILabsSDKOptions<T>): Promise<LabsSDK<T>> {
    // Initialize the cache with the provided options.
    const cache = new LabsResponseCache<T>();
    await cache.initialize(options);
    return new LabsSDK<T>(cache, options);
  }

  /**
   * Get a decisions state accessors (triggers LabsSDKEvents.DecisionAccessed event).
   * @param key - unique string identifier for the decision
   */
  public getLabsDecision<K extends keyof T & string>(key: K): ILabsDecision<T[K]> {
    const { on, config, flagId, source, variantId, seedId } = getDecision({
      labsDecision: this._cache.get(key),
      localDecision: this._localDecisions[key]
    });

    // Fire decision_accessed event for valid events.
    if (flagId && source && variantId) {
      this._fireEvent(LabsSDKEvents.DecisionAccessed, {
        projectId: this._projectId,
        payload: { flagId, source, variantId, seedId }
      });
    }

    function getConfig(validator?: ILabsDecisionValidator<T[K]>): T[K] {
      if (validator) {
        return config && validator.isValid(config) ? config : validator.validConfig;
      }
      return config as T[K];
    }

    // Return the decision state accessors.
    return {
      getConfig,
      isEnabled: () => on
    };
  }

  /**
   * Get all decision keys.
   * @returns - An array of feature keys.
   */
  public getLabsDecisionKeys(): Set<K> {
    return this._decisionKeys;
  }

  /**
   * Updates an existing local decision after SDK initialization.
   * If the decision already exists, it will be replaced, else the request will be ignored.
   * @param key - unique string identifier for the feature
   * @param decision - decision to replace cached decision {@link ILabsDecisionData}
   */
  public setLocalDecision<K extends keyof T>(key: K, localDecision: ILabsDecisionData<T[K]>): void {
    if (this._localDecisions[key] && localDecision) {
      localDecision.type = this._sanitizeLocalDecisionType(localDecision);
      this._localDecisions[key] = localDecision;
    }
  }

  /**
   * Subscribe to LabsSDK observability events (ex DecisionAccessed).
   * @param type - event type defined in LabsSDKEvents
   * @param listener - callback function of type Listener\<[ILabsSDKEventParams[param.type]]\>
   * @returns - unsubscribe function
   */
  public subscribe<E extends LabsSDKEvents>(
    type: E,
    listener: Listener<[ILabsSDKEventParams<E>]>
  ): () => void {
    const unsubscribe = this._events[type].addListener(listener);
    return unsubscribe;
  }
}
