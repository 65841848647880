// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { FEATURE_FLAG_DEFAULTS, type FeatureFlagConfiguration } from './FeatureFlagConfiguration';

/**
 * Feature flag manager state
 */
export interface IFeatureFlagState {
  features: FeatureFlagConfiguration;
}

/**
 * Default feature flag manager state
 */
export function getDefaultFlagState(): IFeatureFlagState {
  return {
    features: FEATURE_FLAG_DEFAULTS
  };
}
