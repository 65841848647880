// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IInterceptors, IStorage } from '@wbd/bolt-http';
import { BoltEnvironment } from '@wbd/bolt-http';
import { LOGIN_URL, REGISTER_AND_LOGIN_URL, LOGOUT_URL } from '../authentication';
import { BOOTSTRAP_URL } from '../bootstrap';
import { LEGACY_CONSENT_EXPERIENCE_URL } from '../legacy-consent-experience';
import { CONSENT_EXPERIENCE_URL } from './getConsentExperience';

const ACE_STORAGE_KEY: string = 'anonymousConsentElections';
const ACE_HEADER: string = 'x-wbd-ace';
const ALLOW_ACE_HEADER_SERVICES: string[] = [
  BOOTSTRAP_URL,
  LEGACY_CONSENT_EXPERIENCE_URL,
  CONSENT_EXPERIENCE_URL,
  LOGIN_URL,
  LOGOUT_URL,
  REGISTER_AND_LOGIN_URL
];

function getAceStorageKey(environment: BoltEnvironment): string {
  const prefix = environment !== BoltEnvironment.PRD ? `${environment}-` : '';
  return `${prefix}${ACE_STORAGE_KEY}`;
}

/**
 * @public
 * This method will configure the ACE headers using the response and request interceptors.
 *
 * @param interceptors - Axios interceptors
 * @param environment - Bolt data service env
 * @param storage - Platform storage
 */
export function configureAnonymousConsentInterceptors(
  interceptors: IInterceptors,
  environment: BoltEnvironment,
  storage?: IStorage
): void {
  interceptors.response.use((response) => {
    if (storage) {
      if (ACE_HEADER in response.headers) {
        const aceHeader: string = response.headers[ACE_HEADER] ?? '';
        storage.writeSync(getAceStorageKey(environment), aceHeader);
      }
    }
    return response;
  });

  interceptors.request.use((config) => {
    if (storage && ALLOW_ACE_HEADER_SERVICES.some((url) => config.url.includes(url))) {
      const aceHeader = storage.readSync(getAceStorageKey(environment));

      if (aceHeader) {
        return {
          ...config,
          headers: {
            ...config.headers,
            [ACE_HEADER]: aceHeader
          }
        };
      }
    }
    return config;
  });
}
