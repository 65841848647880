// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { loggerFactory } from '@wbd/beam-ctv-logger';
import { iPlatformRuntimeError, type IPlatformRuntimeErrorErr } from '../instrumentation';
import { EventWithParams, type Listener, type Unsubscribe } from '@wbd/light-events';
import type { IGlobalErrorContext } from '../application';

/**
 * Register global uncaught error
 */
export function bootstrapGlobalErrors(): IGlobalErrorContext {
  /**
   * Event subscribe
   */
  const globalErrorEvent: EventWithParams<[Error]> = new EventWithParams<[Error]>();

  /**
   * Simple counter to keep track of number of exceptions that occurred in a single session
   */
  let globalErrorEventCount: number = 0;

  const errorLogger = loggerFactory.createLogger('GlobalError');

  /**
   * Function to attach to window error events
   * @param event - original window error event
   */
  function onError(event: ErrorEvent | { reason: Error }): void {
    globalErrorEventCount++;
    let error;
    if (event instanceof ErrorEvent) {
      error = event.error;
    } else if (event.reason instanceof Error) {
      error = event.reason;
    }

    // Add playback information to the error message if an unhandled rejection
    const isPlayback = window.location.href.includes('/watch');
    const isPlaybackMessage = `isPlayback=${isPlayback}`;

    if (!error) {
      error = new Error();
    }
    error.message += error.message ? ' ' + isPlaybackMessage : isPlaybackMessage;

    globalErrorEvent.fire(error).catch((error: Error) => {
      // report on exceptions in attached event subscribers
      import('../instrumentation')
        .then(({ generateErr }) => {
          iPlatformRuntimeError.capture({
            err: generateErr<IPlatformRuntimeErrorErr>(error, {
              classification: 'global-error-subscriber-failure'
            })
          });
        })
        // silently fail if reporting itself fails
        .catch(() => {});
    });

    // log error to console
    errorLogger.error(error);
  }

  // capture any uncaught exceptions
  window.addEventListener('error', onError);

  // capture any uncaught promise / async exceptions
  window.addEventListener('unhandledrejection', onError);

  // return global error context
  return {
    subscribe: function (listener: Listener<[Error]>): Unsubscribe {
      return globalErrorEvent.addListener(listener);
    },
    totalCount: function (): number {
      return globalErrorEventCount;
    }
  };
}
