// Copyright text placeholder, Warner Bros. Discovery, Inc.

import axios, { AxiosError } from 'axios';
import type { IHttpError } from '../http-internal';

/**
 * @public
 *
 * Checks if an error is HttpTimeoutError
 *
 * @param error - error
 * @returns whether if the error is a IHttpError error or not
 */
export const isHttpTimeoutError = (error: unknown): error is IHttpError =>
  axios.isAxiosError(error) && error.code === AxiosError.ETIMEDOUT;
