// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { ILog, ILogAdapter, ILogger, LogOperation } from '../types';
import { LogLevel } from '../types';

/**
 * Logger Factory
 *
 * @public
 */
export interface ILoggerFactory {
  /**
   * To create a logger
   */
  createLogger: (namespace: string) => ILogger;
  /**
   * Attach an adapter
   *
   * @param adapter - Adapter
   */
  attachAdapter: (adapter: ILogAdapter) => void;
  /**
   * Establish what is the verbose of the log methods
   *
   * @param level - LogLevel
   */
  setLevel: (level: LogLevel) => void;
}

export function createLoggerFactory(): ILoggerFactory {
  const adapters: Set<ILogAdapter> = new Set();
  let minimumLogLevel: LogLevel = LogLevel.INFO;

  const createLogger = (namespace: string): ILogger => {
    const log = (level: LogLevel): ILog => {
      const entryLevels: {
        [log in LogLevel]: keyof ILogAdapter;
      } = {
        [LogLevel.DEBUG]: 'debug',
        [LogLevel.INFO]: 'info',
        [LogLevel.WARN]: 'warn',
        [LogLevel.ERROR]: 'error'
      };

      return (message: string, ...data: unknown[]) => {
        if (level < minimumLogLevel) {
          return;
        }

        adapters.forEach((adapter) => {
          const logOperation: LogOperation = adapter[entryLevels[level]] as LogOperation;
          if (namespace) {
            logOperation(`[${namespace}]`, message, ...data);
          } else {
            logOperation(message, ...data);
          }
        });
      };
    };

    return {
      debug: log(LogLevel.DEBUG) as ILog,
      info: log(LogLevel.INFO) as ILog,
      warn: log(LogLevel.WARN) as ILog,
      error: log(LogLevel.ERROR) as ILog
    };
  };

  const attachAdapter = (adapter: ILogAdapter): void => {
    adapters.add(adapter);
  };

  const setLevel = (level: LogLevel): void => {
    minimumLogLevel = level;
  };

  return {
    createLogger,
    attachAdapter,
    setLevel
  };
}
