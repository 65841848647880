// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { FormatCode, LanguageCode } from '../nominals';
import { asFormatCode, isFormatCode } from '../localization';

import { CanonicalizedCldrLocale } from '../localization-enums';

/**
 * Determines if an exact match for a desired format locale is present
 * on a list of available format locale.
 * @param wantFormatCode - wantFormatCode Desired format
 * @param haveFormatCodes - haveFormatCodes Available formats
 */
function hasFormat(wantFormatCode: string, haveFormatCodes: FormatCode[]): wantFormatCode is FormatCode {
  return isFormatCode(wantFormatCode) && haveFormatCodes.includes(wantFormatCode);
}

/**
 * Maps a desired format code onto a list of supported format codes,
 * with a fallback to the active language locale if no match is found
 *
 * @param wantFormatCode - Desired format
 * @param haveFormatCodes - Available formats
 * @param activeLanguage - the active language used by the client as default

 * @public
 */
export function selectFormat(
  wantFormatCode: string | undefined,
  haveFormatCodes: FormatCode[],
  activeLanguage: LanguageCode
): FormatCode {
  // a direct match of server requested format code and client available format code
  if (wantFormatCode && hasFormat(wantFormatCode, haveFormatCodes)) {
    return wantFormatCode;
  }

  // use the active language locale as fallback for format code
  if (hasFormat(activeLanguage, haveFormatCodes)) {
    return activeLanguage;
  }

  // use a last resort fallback to english US
  return asFormatCode(CanonicalizedCldrLocale.EN_US);
}
