// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { AuthToken, IResponse } from '@wbd/bolt-http';

import type { RequestConfigOverride } from '../http-client';
import { HttpClient } from '../http-client';
import type { ITokenAuthenticationData } from './interfaces/ITokenAuthenticationData';

/**
 * @public
 * Endpoint called on logout
 */
export const LOGOUT_URL: string = '/logout';

/**
 * Logs a user out.
 * @param config - The request config used to pass in properties like an abort signal.
 * @public
 */
export const logout = async (config?: RequestConfigOverride): Promise<AuthToken> => {
  const http = HttpClient.instance;
  const response = await http.post<IResponse<ITokenAuthenticationData>, undefined>(
    LOGOUT_URL,
    undefined,
    config
  );
  const resource = response.data?.data;
  const token = resource?.attributes?.token;

  await http.sessionConfig.authTokenProvider?.setTokenAsync(token);

  return token;
};
