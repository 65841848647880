// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type {
  ConsentExperienceAlias,
  LegacyConsentExperience,
  LegacyConsentExperienceAlias
} from '../data-models';
import { LegacyConsentExperienceDecorator } from '../data-models';
import { TransformerLight } from '../data-transformer';
import type { RequestConfigOverride } from '../http-client';
import { HttpClient } from '../http-client';
import type { ITypedJsonApiDocumentFromModel } from '../json-api';
import { DataTypes } from '../json-api';

/**
 * @public
 * Legal consent experience url
 * @deprecated
 * TODO GCX-49602
 */
export const LEGACY_CONSENT_EXPERIENCE_URL: string = '/legal/consentExperience';

/**
 * @public
 * @deprecated
 * TODO GCX-49602
 */
export type ILegacyConsentExperienceResponse = ITypedJsonApiDocumentFromModel<
  DataTypes.LegacyConsentExperience,
  LegacyConsentExperience
>;

/**
 * @public
 * @deprecated
 * TODO GCX-49602
 */
export const getLegacyConsentExperienceRequest = async (
  consentExperienceAlias: ConsentExperienceAlias | LegacyConsentExperienceAlias,
  decorators: LegacyConsentExperienceDecorator[] = [LegacyConsentExperienceDecorator.PLAIN_TEXT],
  config?: RequestConfigOverride
): Promise<ILegacyConsentExperienceResponse> => {
  const response = await HttpClient.instance.get<ILegacyConsentExperienceResponse>(
    `/legal/consentExperience`,
    {
      params: {
        alias: consentExperienceAlias,
        decorators: decorators.join(',')
      },
      signal: config?.signal
    }
  );

  return response.data;
};

interface ITypeMap extends Record<DataTypes, unknown> {
  [DataTypes.LegacyConsentExperience]: LegacyConsentExperience;
}

/**
 * @public
 * NCIS service to retrieve the consent experience data
 * @param consentExperienceAlias - Consent experience alias
 * @param decorators - Consent experience decorators to get optional properties eg. richtexthtml
 * @param config - The request config used to pass in properties like an abort signal.
 * @returns consent experience
 * @deprecated
 * TODO GCX-49602
 */
export const getLegacyConsentExperience = async (
  consentExperienceAlias: ConsentExperienceAlias | LegacyConsentExperienceAlias,
  decorators?: LegacyConsentExperienceDecorator[],
  config?: RequestConfigOverride
): Promise<LegacyConsentExperience> => {
  const consentExperience = await getLegacyConsentExperienceRequest(
    consentExperienceAlias,
    decorators,
    config
  );

  const store = new TransformerLight<DataTypes.LegacyConsentExperience, ITypeMap>(consentExperience);

  return store.find(DataTypes.LegacyConsentExperience, consentExperience.data.id)!;
};
