// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { _parseIetfBcp47LanguageTag } from '../code-parsers';
import { isIsoRegionCode } from '../../localization-enums';
import { FormatCode } from '../../nominals';
import { RegionCode } from '../RegionCode';

/**
 * Determines if the given string is a valid format code (`FormatCode`).
 * In order to be used as a `FormatCode`, the string must:
 *    1) Be a valid IETF BCP 47 language tag, and
 *    2) Have a region subtag that is an `IsoRegionCode` (not a `UnRegionCode`)/
 *
 * @param code - a string which may or may not be a valid `FormatCode`
 *
 * @public
 */
export function isFormatCode(code: string): code is FormatCode {
  try {
    const regionCode: RegionCode | undefined = _parseIetfBcp47LanguageTag(code).regionSubtag;
    return regionCode !== undefined && isIsoRegionCode(regionCode);
  } catch (e) {
    return false;
  }
}
