// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { type IStorage } from '@wbd/beam-dom-extensions';
import { Configuration, type IVersionedEventWithContext } from '../core';

/**
 * Event Queue Class
 * @public
 */
export class EventQueue {
  private static _persistentStorage: IStorage;
  private static _maxBatchSize: number = 10;
  private static _storage: IVersionedEventWithContext[] = [];
  private static _maxQueueSize: number = 2000;

  public static initialize(config: Configuration): void {
    this._maxBatchSize = config.maxEventBatchSize;
    if (config.eventStorage) {
      this._persistentStorage = config.eventStorage;
      this._storage = this._getIStorageEventQueue();
    }
  }

  /**
   * Enqueues a given event into the storage.
   * @param event - A single IVersionedEventWithContext.
   * @public
   */
  public static enqueueEvent(event: IVersionedEventWithContext): void {
    if (this._storage.length >= this._maxQueueSize) {
      this._popTopEvents();
    }

    this._storage.push(event);
    if (this._persistentStorage) {
      this._persistentStorage.writeSync('ISDKEventQueue', JSON.stringify(this._storage));
    }
  }

  /**
   * Places events back into the front of our array of QueueableEvents
   * @param events - An array of IVersionedEventWithContexts.
   * @public
   */
  public static insertToFront(events: IVersionedEventWithContext[]): void {
    this._storage.unshift(...events);
    if (this._persistentStorage) {
      this._persistentStorage.writeSync('ISDKEventQueue', JSON.stringify(this._storage));
    }
  }

  private static _popTopEvents(): void {
    let eventsOverMax = 0;
    if (this._storage.length >= this._maxQueueSize) {
      eventsOverMax = this._storage.length - this._maxQueueSize;
    }
    // pop all events over our max size + 1 (for the new event) off of our storage
    this._storage.splice(0, eventsOverMax + 1);
  }

  /**
   * Gets the number of events in the queue.
   * @returns A number corresponding to the size of the queue.
   * @public
   */
  public static size(): number {
    return this._storage.length;
  }

  /**
   * Resets the queue storage back to empty.
   * @public
   */
  public static clearQueue(): void {
    this._storage = [];
    if (this._persistentStorage) this._persistentStorage.removeSync('ISDKEventQueue');
  }

  /**
   * Gets the first _maxBatchSize number of events from the queue.
   * @returns An array of IVersionedEventWithContexts of size minor or equal to _maxBatchSize.
   * @public
   */
  public static getEventBatch(): IVersionedEventWithContext[] {
    const batch = this._storage.splice(0, this._maxBatchSize);
    if (this._persistentStorage) {
      this._persistentStorage.writeSync('ISDKEventQueue', JSON.stringify(this._storage));
    }
    return batch;
  }

  /**
   * Synchronously reads "ISDKEventQueue" key from IStorage.
   * @returns An array containing all the events stored in IStorage under that key, or empty array if no value was found.
   */
  private static _getIStorageEventQueue(): IVersionedEventWithContext[] {
    const iStorageEventQueue = this._persistentStorage.readSync('ISDKEventQueue');
    try {
      return iStorageEventQueue ? JSON.parse(iStorageEventQueue) : [];
    } catch (error) {
      // Error parsing iStorageEventQueue, returning empty array
      return [];
    }
  }
}
