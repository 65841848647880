// Copyright text placeholder, Warner Bros. Discovery, Inc.

import { createHEXDIGLC } from '@wbd/beam-js-extensions';
import { BoltHttpClient, IHttpError, IRequestConfig, IResponse, isHttpError } from '@wbd/bolt-http';
import { SessionManager } from '@wbd/instrumentation-session-manager';
import {
  RequestMeasureV1Method,
  ServiceHttpErrorV1Method,
  ServiceHttpErrorV1Reason,
  iRequestMeasureV1,
  iServiceHttpErrorV1,
  type IRequestMeasureV1Payload
} from '../../generated';
interface IResponseErrorCode {
  code: string;
  id: string;
}

interface IResponseErrors {
  errors: IResponseErrorCode[];
}

/**
 * The network monitor class used to monitor network requests
 * @public
 */
export class NetworkMonitor {
  private _onRequestInterceptorId: number = 0;
  private _onResponseInterceptorId: number = 0;
  private _httpClient: BoltHttpClient | undefined;
  private _STATUS_CODE_CANCELLED: number = 499;
  private _STATUS_CODE_CLIENT_CLOSED_CONNECTION: number = 460;
  private _STATUS_CODE_TIMEOUT: number = 408;
  private _STATUS_CODE_UNKNOWN_ERROR: number = 999;
  private _measureV1: typeof iRequestMeasureV1 = iRequestMeasureV1;
  private _errorV1: typeof iServiceHttpErrorV1 = iServiceHttpErrorV1;
  private _telegraphUri: string;
  private _traceFlags: string;

  public constructor(telegraphUri: string, traceFlags: string, httpClient?: BoltHttpClient) {
    this._telegraphUri = telegraphUri;
    this._traceFlags = traceFlags;
    if (httpClient) {
      this._initialize(httpClient);
    }
  }

  /**
   * send the request measure payload to request measure v1
   * @param payload - request measure payload
   */
  public createMeasure(payload: IRequestMeasureV1Payload): void {
    /**
     * Do not measure Telegraph requests, as this would cause loops of requests
     * reporting how long the last Telegraph report took to report
     */
    if (payload.request.url.includes(this._telegraphUri)) {
      return;
    }

    this._measureV1.emit(payload);
  }

  /**
   * updates the request monitor with a new http client if available
   * @param httpClient - bolt http client
   * @returns
   */
  public update(telegraphUri?: string, traceFlags?: string, httpClient?: BoltHttpClient): void {
    if (telegraphUri) {
      this._telegraphUri = telegraphUri;
    }

    if (traceFlags) {
      this._traceFlags = traceFlags;
    }

    if (!httpClient) {
      return;
    }

    if (this._httpClient) {
      const { request, response } = this._httpClient.interceptors;
      request.eject(this._onRequestInterceptorId);
      response.eject(this._onResponseInterceptorId);
    }

    this._initialize(httpClient);
  }

  /**
   * failure handler when a axios request fails
   * @param error - error object
   * @returns
   */
  private async _catchResponse(err: unknown): Promise<unknown> {
    const error = err as IHttpError;
    /**
     * Network error will return an error without an response,
     * 400-5xx include a response. We need to normalize the error
     * object here to either be the response or the original error.
     */
    const response = error.code && this._httpClient?.isHttpError(error) ? error : error.response;

    // Do not measure or create a service error on cancelled requests as they are not an error scenario we need to track.
    if (!this._isCancelledRequest(response as IResponse & IHttpError)) {
      const measurement = this._generatePayload(response as IResponse & IHttpError);
      //if statusCode is undefined then indicates failure on client-side
      const clientReason = this._getClientReason(response as IHttpError & IResponse);
      this.createMeasure(measurement);
      this._createServiceHttpError(measurement, clientReason);
    }
    return Promise.reject(error);
  }

  private _createServiceHttpError(
    measurement: IRequestMeasureV1Payload,
    failureReason?: ServiceHttpErrorV1Reason
  ): void {
    const { request, response } = measurement;
    const { method, retryCount, traceId, url } = request;
    const { statusCode, errorCodes } = response;

    this._errorV1.capture({
      err: {},
      request: {
        method: method as unknown as ServiceHttpErrorV1Method,
        retryCount,
        traceId,
        url
      },
      response: {
        errorCodes,
        statusCode: failureReason !== undefined ? undefined : statusCode
      },
      clientFailure: {
        reason: failureReason
      }
    });
  }

  /**
   * generates a payload from the response object
   * @param response - axios response object
   * @returns request measure payload
   */
  private _generatePayload(response: IResponse): IRequestMeasureV1Payload {
    const { config } = response;
    // @ts-ignore - axios retry added by axios-retry module
    const retryCount = config['axios-retry']?.retryCount ?? 0;
    // @ts-ignore - request id added to the config
    const requestId = config.requestId;
    // @ts-ignore - request start time added to the config
    const requestStartTime = config.requestStartTime;
    // @ts-ignore - request page added to the config
    const requestPage = config.requestPage;

    const deviceReceivedAt = Date.now();
    const method = config.method.toUpperCase() as RequestMeasureV1Method;
    const errorCodes = this._getErrorCode(response);

    return {
      duration: deviceReceivedAt - requestStartTime,
      page: requestPage,
      request: {
        retryCount,
        deviceSentAt: requestStartTime,
        method,
        traceId: requestId,
        url: config.url
      },
      response: {
        ...(errorCodes && errorCodes.length ? { errorCodes } : {}),
        deviceReceivedAt,
        statusCode: this._getStatus(response)
      }
    };
  }

  /**
   * attempts to pull the error code from the response object
   * @param response - axios response object
   * @returns error code
   */
  private _getErrorCode(errorOrResponse: IResponse | IHttpError): IResponseErrorCode[] | undefined {
    const error = errorOrResponse as IHttpError;

    if (!isHttpError(error)) {
      return;
    }

    let errorCodes: IResponseErrorCode[] = [];

    // assumes axios is setting an error code
    if (error.code) {
      errorCodes = [
        {
          id: error.code,
          code: error.code
        }
      ];
    }

    if ('response' in error && error.response!.data) {
      const data = error.response!.data as IResponseErrors;
      errorCodes = data!.errors ?? errorCodes;
      errorCodes = errorCodes.map(({ id, code }) => ({ id, code }));
    }

    return errorCodes;
  }

  /**
   * pulls the page from the response object
   * @returns page object
   */
  private _getPage(): IRequestMeasureV1Payload['page'] {
    const formattedHash = location.hash?.replace('#', '');
    const pathname = location.pathname;

    return {
      uri: formattedHash || pathname
    };
  }

  /**
   * generates a trace parent identifier for the request
   * @returns string traceparent identifier
   */
  private _generateTraceParent(): string {
    const traceId = createHEXDIGLC(32);
    const spanId = createHEXDIGLC(16);
    return `00-${traceId}-${spanId}-${this._traceFlags}`;
  }

  /*
   * generates a tracestate wth session information
   * @returns string trace state
   */
  private _generateTraceState(): string {
    return `wbd=session:${SessionManager.sessionId}`;
  }

  /**
   * pulls the status code from the response object, or if cancelled returns 499
   * @param response - axios response object
   * @returns number
   */
  private _getStatus(errorOrResponse: IResponse | IHttpError): number {
    const { config } = errorOrResponse;

    if (config && config.signal?.aborted) {
      return this._STATUS_CODE_CANCELLED;
    }

    if (!isHttpError(errorOrResponse)) {
      return errorOrResponse.status;
    }

    const { response, code } = errorOrResponse;

    /**
     * covers the following cases:
     * ERR_BAD_RESPONSE, ERR_BAD_REQUEST, ERR_NOT_SUPPORT, ERR_INVALID_URL
     */
    if (response && response.status) {
      return response.status;
    }

    switch (code) {
      case 'ERR_CANCELED':
        return this._STATUS_CODE_CANCELLED;
      case 'ERR_NETWORK':
        return this._STATUS_CODE_CLIENT_CLOSED_CONNECTION;
      case 'ETIMEDOUT':
        return this._STATUS_CODE_TIMEOUT;
      default:
        // we've exhausted all options, return 999 as we don't know what it could be
        return this._STATUS_CODE_UNKNOWN_ERROR;
    }
  }

  private _getClientReason(error: IHttpError & IResponse): ServiceHttpErrorV1Reason | undefined {
    // safeguard against clientReason defaulting to OTHER
    const { config } = error;

    if (config && config.signal?.aborted) {
      return undefined;
    }

    if (!isHttpError(error)) {
      return undefined;
    }

    const { response } = error;

    if (response && response.status) {
      return undefined;
    }

    const { code } = error;
    switch (code) {
      case 'ETIMEDOUT':
        return ServiceHttpErrorV1Reason.CLIENT_TIMEOUT;
      case 'ERR_NETWORK':
        return ServiceHttpErrorV1Reason.CONNECTION_LOST;
      case 'ERR_CANCELED':
        return ServiceHttpErrorV1Reason.CANCELLED;

      /*
      The following test cases have been commented out and will be revisited at a later time. 
      */
      // case 'CONNECTION_TIMEOUT':
      //   return ServiceHttpErrorV1Reason.CONNECTION_TIMEOUT;
      // case 'INSUFFICIENT_RESOURCES':
      //   return ServiceHttpErrorV1Reason.INSUFFICIENT_RESOURCES;
      // case 'SECURITY_FAILURE':
      //   return ServiceHttpErrorV1Reason.SECURITY_FAILURE;
      // case 'DESERIALIZATION_FAILURE':
      //   return ServiceHttpErrorV1Reason.DESERIALIZATION_FAILURE;
      default:
        return ServiceHttpErrorV1Reason.OTHER;
    }
  }
  /**
   * initializes the request monitor by attaching the interceptors to the http client and caching
   * the interceptors so they can be removed later.
   * @param httpClient - bolt http client
   */
  private _initialize(httpClient: BoltHttpClient): void {
    this._httpClient = httpClient;

    const { request, response } = this._httpClient.interceptors;
    this._onRequestInterceptorId = request.use(this._onRequest.bind(this));
    this._onResponseInterceptorId = response.use(this._onResponse.bind(this), this._catchResponse.bind(this));
  }

  private _isCancelledRequest(response: IResponse): boolean {
    return this._getStatus(response) === this._STATUS_CODE_CANCELLED;
  }

  /**
   * interceptor before requests are made
   * @param config - axios request config
   * @returns promised axios request config
   */
  private async _onRequest(config: IRequestConfig): Promise<IRequestConfig> {
    const requestId = this._generateTraceParent();

    /**
     * Generating a trace id directly on the IRequestConfig until the service supports
     * trace id headers.
     */
    // @ts-ignore - request id added to the config// @ts-ignore next-line
    config.requestId = requestId;

    /**
     * Store the current page on the request object, so that we know what page
     * the request was made from
     */
    // @ts-ignore - request page added to the config
    config.requestPage = this._getPage();

    /**
     * Attach the requestStartTime to the request object, this avoid having to
     * store a dictionary of request ids to start times
     */
    // @ts-ignore - request start added to the config
    config.requestStartTime = Date.now();

    /**
     * Attach the traceparent and tracestate headers to the request header
     */
    config.headers = {
      ...config.headers,
      traceparent: requestId,
      tracestate: this._generateTraceState()
    };

    return config;
  }

  /**
   * interceptor after response are made
   * @param response - axios response
   * @returns promised axios response
   */
  private async _onResponse(response: IResponse): Promise<IResponse> {
    const measurement = this._generatePayload(response);
    this.createMeasure(measurement);
    return response;
  }
}
