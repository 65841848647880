// Copyright text placeholder, Warner Bros. Discovery, Inc.

import canonicalize from 'canonicalize';

/**
 * Implement the FNV-1a hashing algorithm for 32 bit hashes.
 *
 * See https://en.wikipedia.org/wiki/Fowler%E2%80%93Noll%E2%80%93Vo_hash_function
 */
function fnv1a32(inputString: string): number {
  // Magic numbers derived from Wikipedia page linked above.
  const FNV_PRIME = 16_777_619;
  const FNV_OFFSET = 2_166_136_261;

  let hash = FNV_OFFSET;

  for (let index = 0; index < inputString.length; index++) {
    const utf16Char = inputString.charCodeAt(index);
    // eslint-disable-next-line no-bitwise
    const lsb = utf16Char & 0x00ff;
    const msb = utf16Char << 8;

    // Apply hashing function in byte sized chunks

    hash ^= msb; // XOR byte
    // eslint-disable-next-line no-bitwise
    hash = (hash * FNV_PRIME) & 0xffff_ffff; // Trim to 32 bit value

    hash ^= lsb; // XOR byte
    // eslint-disable-next-line no-bitwise
    hash = (hash * FNV_PRIME) & 0xffff_ffff; // Trim to 32 bit value
  }

  return hash;
}
/**
 * Create a unique value that represents the contents of a data structure.
 * Used to compare two data structures without needing the complete structure.
 *
 * NOTE: For fingerprintData to succeed, the data structure being processed *must*
 * be serializable.
 *
 * @param serializableData - the data structure to take a fingerprint from
 * @returns a string that can may be compared with other fingerprints
 *
 * @public
 */
export function fingerprintData(serializableData: unknown): string {
  const canonicalized = canonicalize(serializableData) ?? '';

  /* NOTE:
   * The current implementation uses FNV-1a with a hash size of 32 bits.
   *
   * An ideal 32 bit hash will have a 1% probability of collision after only 9,300
   * distinct inputs.  FNV-1a is not an ideal hash, but it is pretty good.  Given that
   * our anticipated usage patterns, it would be highly unusual to compute over 9000
   * distinct fingerprints in the lifetime of any particular client application time
   * in memory.
   *
   * If you do need greater 32 bits of hash, consider extending this function to accept
   * an optional hashing function as an argument.
   *
   * Any well behaved hashing function should do the job, as could a CRC.  This
   * algorithm was chosen because it is well behaved, well known, and easy to implement
   * without taking on another external dependency.
   */
  return fnv1a32(canonicalized).toString(16);
}
