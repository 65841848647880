// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * An asynchronous timer.
 *
 * A decorated Promise that includes an additional `cancel` method that may be
 * called to clear the underlying timeout.
 *
 * Resolves to void when either the time elapses or the timer is cancelled.
 * @public
 */
export type AsyncTimer = Promise<void> & { cancel: () => void };

/**
 * Returns a promise that resolves when the duration noted has passed.
 *
 * The promise is decorated with a `cancel` method that may be used to clear the
 * underlying Timeout.
 *
 * @param ms - the duration of the timer in milliseconds
 * @returns A specialized Promise that will resolve when the time has elapsed
 *
 * @public
 */
export function timer(ms: number): AsyncTimer {
  let timeoutId: ReturnType<typeof setTimeout>;
  let resolver: () => void;

  const timerPromise: Promise<void> = new Promise<void>((resolve) => {
    resolver = resolve;
    timeoutId = setTimeout(() => {
      resolve();
    }, ms);
  });

  (timerPromise as unknown as AsyncTimer).cancel = () => {
    if (timeoutId && typeof timeoutId === 'number') {
      clearTimeout(timeoutId);
    }
    resolver();
  };

  return timerPromise as AsyncTimer;
}
