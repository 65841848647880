// Copyright text placeholder, Warner Bros. Discovery, Inc.

import {
  type IPlatformAdapter,
  SubscriptionFlow,
  PaymentProvider,
  type ICommerce
} from '@wbd/beam-ctv-platform-adapters';

export const COMMERCE_PLATFORM_URL_PARAM: string = 'commercePlatform';
export const FAKE_PLAYBACK_ENGINE_URL_PARAM: string = 'fakePlaybackEngine';

function overrideCommerceAdapter(platform: string, defaultCommerceAdapter: ICommerce): void {
  switch (platform) {
    case 'cox': {
      defaultCommerceAdapter.getSubscriptionFlow = () => SubscriptionFlow.MVPD;
      defaultCommerceAdapter.subscribePlanWithMVPD = () => {};
      break;
    }
    case 'comcast': {
      defaultCommerceAdapter.getSubscriptionFlow = () => SubscriptionFlow.MVPD;
      defaultCommerceAdapter.subscribePlanWithMVPD = () => {};
      break;
    }
    case 'samsung': {
      defaultCommerceAdapter.getSubscriptionFlow = () => SubscriptionFlow.IAP;
      defaultCommerceAdapter.getPaymentProvider = () => PaymentProvider.Samsung;
      break;
    }
  }
}

/**
 * function for creating a customized platform adapter instance depending on some environment overrides
 * @param defaultAdapter - default platform adapter
 * @returns
 */
export function createDesktopAdapter(defaultAdapter: IPlatformAdapter): IPlatformAdapter {
  const urlParameters = defaultAdapter.device.getURLParams();
  // enable mouse support
  defaultAdapter.input.setIsPointerEnabled(true);

  // check commerce flow override
  const commercePlatform = urlParameters.get(COMMERCE_PLATFORM_URL_PARAM);
  if (commercePlatform) {
    overrideCommerceAdapter(commercePlatform, defaultAdapter.commerce);
  }

  // check if fake engine is enabled
  const isFakeEngineEnabled = Boolean(urlParameters.get(FAKE_PLAYBACK_ENGINE_URL_PARAM));
  if (isFakeEngineEnabled) {
    // Fire & forget to unblock app bootstrap
    import('./packlets/domain-watch/integration-video-adapter')
      .then(({ overrideVideoAdapter }) => {
        overrideVideoAdapter(defaultAdapter.video);
      })
      .catch((error) => {
        window.console.log(`Could not override video adapter in createDesktopAdapter.`, error);
      });
  }

  //TODO https://wbdstreaming.atlassian.net/browse/GCX-48176 move this to abstractAdapttor
  // do aditional checks for webGl support
  const defaultStageOptions = defaultAdapter.stage.getStageOptionsAsync();

  const force720p = urlParameters.get('force720p');

  defaultAdapter.stage.getStageOptionsAsync = async () => {
    const settings = await defaultStageOptions;

    if (force720p) {
      settings.h = 720;
      settings.w = 1280;
      settings.precision = 2 / 3;
      const canvasStyle = document.createElement('style');
      canvasStyle.innerText = `
      body > canvas {
        width: 100%;
        height: 100%;
      }`;
      document.body.appendChild(canvasStyle);
    }

    return { canvas2d: !testWebGL(), ...settings };
  };

  const deviceIdOverride = urlParameters.get('id');

  if (deviceIdOverride) {
    defaultAdapter.device.getDeviceIdentifierAsync = async () => {
      return deviceIdOverride;
    };
  }

  return defaultAdapter;
}

// advanced lightning specific webGL validation
//TODO https://wbdstreaming.atlassian.net/browse/GCX-48176 refactor
function testWebGL(): boolean {
  const opts: { [key: string]: boolean } = {
    alpha: true,
    antialias: false,
    premultipliedAlpha: true,
    stencil: true,
    preserveDrawingBuffer: false
  };
  try {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl', opts) || canvas.getContext('experimental-webgl', opts);
    return !!gl;
  } catch (error) {
    return false;
  }
}
