// Copyright text placeholder, Warner Bros. Discovery, Inc.

import merge from 'ts-deepmerge';
import { IGluonConfig } from '../types/IGluonConfig';
import type { IGluonPlaybackEngineLabsConfig } from './IGluonPlaybackEngineLabsConfig';
import { defaultGluonPlaybackEngineLabsConfig } from './IGluonPlaybackEngineLabsConfig';

/**
 * A function that extracts GLUON engine configs and converts into object that respects the defined schema
 * @beta
 * @param input - FeatureFlags response JSON object, set as unknown type for maximum compatibility
 * @returns Object with collections of parsed PSDK Configuration
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseGluonPlaybackEngineLabsConfig(input: any): IGluonPlaybackEngineLabsConfig {
  return input?.playback_engine_gluon?.config ?? defaultGluonPlaybackEngineLabsConfig;
}

/**
 * A function that merges GLUON config with GLUON Labs config
 * @beta
 * @param gluonConfig - GLUON config
 * @param labsConfig - GLUON Labs config
 * @returns merged GLUON config
 */
export function mergeGluonPlaybackEngineLabsConfig(
  gluonConfig: IGluonConfig,
  labsConfig?: IGluonPlaybackEngineLabsConfig
): IGluonConfig {
  if (labsConfig) {
    return merge({}, gluonConfig, labsConfig);
  }
  return gluonConfig;
}
