// Copyright text placeholder, Warner Bros. Discovery, Inc.

/**
 * Enum definition for device type
 * @public
 */
export enum DeviceType {
  /**
   * Set-top box device
   */
  STB = 'stb',
  /**
   * Smart TV device
   */
  TV = 'tv',

  /**
   * Game console devices (xbox / playstation)
   */
  CONSOLE = 'console'
}

/**
 * Enum definition for deeplink content type
 * @public
 */
export enum DeeplinkContentType {
  /**
   * displays content page
   */
  SHOW = 'show',
  /**
   * redirects to content playback
   */
  VIDEO = 'video',
  /**
   * redirects to search page
   */
  SEARCH = 'search',
  /**
   * redirects to a route
   */
  ROUTE = 'route',
  /**
   * redirects to a route
   */
  VOICE = 'voice'
}

/**
 * Interface definition for Deeplink Parameters
 * @public
 */
export interface IDeeplinkParams {
  /**
   * deeplink content type
   */
  type: DeeplinkContentType;
  /**
   * content identifier
   */
  id: string;
  /**
   * Any tracking parameters passed to deeplinks in the form of `?param1=value&param2=value`
   */
  trackingUrlParams?: URLSearchParams;
}

/**
 * Interface definition for custom announcer
 * @public
 */
export interface ICustomAnnouncer {
  /**
   * indicates if custom tts is enabled
   */
  ttsEnabled?: boolean;
  /**
   * Play functionality for aria custom announcer
   */
  play: (text: string) => void;
  /**
   * Cancel functionality for aria custom announcer
   */
  cancel: () => void;
  /**
   * Init logic for the custom announcer
   */
  initialize?: (ttsService?: unknown) => void;
}

/**
 * Interface definition for active Profile info
 * @public
 */
export interface IProfileInfo {
  /**
   * Profile name
   */
  name: string | undefined;
  /**
   * Profile avatar image URL
   */
  imageUrl: string | undefined;
}

/**
 * Interface definition for Splash Video
 * @public
 */
export interface ISplashVideoProps {
  /**
   * A callback function to be invoked when there is an error.
   */
  onError?: (error: unknown) => void;
  /**
   * A callback function to be invoked once the splash screen hiding is complete.
   */
  onComplete?: VoidFunction;
  /**
   * A boolean indicating whether the intro video should be shown.
   */
  skipVideo?: boolean;
}

/**
 * valid pages
 * @public
 */
export type TargetPage = 'home' | 'settings' | 'search';

/**
 * @public
 * type for device commands / voice actions
 */
export type TDeviceCommand =
  | { name: 'playFrom'; positionSeconds: number }
  | { name: 'skip'; offsetSeconds: number }
  | { name: 'navigateTo'; state: TargetPage }
  | { name: 'search'; searchString: string }
  | { name: 'subtitles'; state: boolean };

/**
 * @public
 * event handler type
 */
export type TDeviceEventHandler = (command: TDeviceCommand) => void;

/**
 * @public
 * the metadata of the current stream.
 */
export interface IStreamMetadata {
  /**
   * The unique id for the current content being streamed.
   */
  contentId?: string;
  /**
   * The current playback progress in seconds.
   */
  progress?: number;
  /**
   *  The total duration of the stream in seconds.
   */
  duration?: number;
}
