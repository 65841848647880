// Copyright text placeholder, Warner Bros. Discovery, Inc.

import type { IInitialContext } from '../application';

interface ILoadUIResult {
  start: (context: IInitialContext) => Promise<void>;
  startWithError: (context: Partial<IInitialContext>) => Promise<void>;
}

/**
 * Load the Lightning UI layer of the application
 */
export async function loadUI(): Promise<ILoadUIResult> {
  return import(/* webpackChunkName: "start" */ '../application');
}
